import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/service/auth.service';
import { ServiceService } from 'src/app/service/service.service';
@Component({
  selector: 'app-database-maerchant-info',
  templateUrl: './database-maerchant-info.component.html',
  styleUrls: ['./database-maerchant-info.component.css'],
})
export class DatabaseMaerchantInfoComponent implements OnInit {
  url: any;
  id: any;
  date: any;
  data: any = {};
  panelOpenState = false;
  password: any;
  response: any;
  role: any;
  generalMaterialPhotoNumber: number = 0;
  customizeMaterialPhotoNumber: number = 0;
  competitorPhoto: number = 0;
  migratePhoto: number = 0;
  progress = 0;
  errMessage = '';
  isLoading = false;
  isDone = false;
  @ViewChild('btnclose') btnclose: any;
  constructor(
    private route: ActivatedRoute,
    private service: ServiceService,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      if (this.id) {
        this.getDatadetail();
      }
    });

    this.role = this.authService.getRole();
  }
  async getDatadetail() {
    const data: any = await this.service.GetTokenPathParams(
      '/backoffice/database/' + this.id
    );
    this.data = data;
    console.log('data', this.data);
    for (let i = 0; i < this.data.images.length; i++) {
      if (this.data.images[i]._id === 'generalMaterialPhoto') {
        this.generalMaterialPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'customizeMaterialPhoto') {
        this.customizeMaterialPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'competitorPhoto') {
        this.competitorPhoto = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'imageMigrate') {
        this.migratePhoto = this.data.images[i].images.length;
      }
    }
  }
  Editmerchant() {
    this.router.navigate([
      `/database-marchant-edit?id=${this.id}&date=${this.date}`,
    ]);
  }
  checkIfImagesExist(key: string): boolean {
    const imageItem = this.data?.images?.find((item: any) => item._id === key);
    return imageItem && imageItem.images.length > 0;
  }
  async confirmDelete() {
    const body = {
      id: [this.id],
      password: this.password,
    };
    const data: any = await this.service.DeleteTokenPathBody(
      '/backoffice/merchant/delete-many',
      body
    );

    if (data?.msg === 'Success') {
      this.btnclose.nativeElement.click();

      this.password = '';

      alert('Delete merchant success!');
      this.router.navigate(['/database']);
    }
    if (data.error?.message === 'Password is invalid.') {
      this.response = data.error.message;
    }
  }

  async createParam() {
    const paramSummary = {
      country: this.data.formId._id,
      locationName: this.data.locationName,
      category: this.data.category,
      district: this.data.district,
      province: this.data.province,
      zipcode: this.data.zipcode,
      merchantName: this.data.merchantName,
    };
    const params = new URLSearchParams(paramSummary);

    return params;
  }

  async exportMerchant() {
    this.progress = 0;
    this.errMessage = '';
    this.isLoading = true;
    this.isDone = false;
    const params = await this.createParam();

    this.service
      .downloadFile(
        '/backoffice/database/v2/export?' +
          `${params.toString()}` +
          '&isPhoto=' +
          'true'
      )
      .subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.handleDownloadProgress(event);
          } else if (event.type === HttpEventType.Response) {
            this.handleHttpResponse(event);
          }
        },
        error: (err: any) => {
          console.log(err);
          this.errMessage = 'Something went wrong, please try again later!';
          this.isDone = true;
          this.isLoading = false;
        },
        complete: () => {
          this.isDone = true;
          this.errMessage = '';
          this.isLoading = false;
        },
      });
  }

  get style() {
    return 'width:' + this.progress + '%';
  }

  private handleDownloadProgress(event: any): void {
    this.progress = Math.round((100 * event.loaded) / event.loaded);
    console.log(this.progress);
  }

  private handleHttpResponse(event: any): void {
    const file: Blob = event.body as Blob;
    this.downloadFileFunc(file);
  }

  private downloadFileFunc(file: Blob): void {
    const downloadLink = this.renderer.createElement('a');
    this.renderer.setProperty(
      downloadLink,
      'href',
      window.URL.createObjectURL(file)
    );
    this.renderer.setProperty(
      downloadLink,
      'download',
      `POSM_Report_${this.data.merchantName}`
    ); // Set your desired filename here
    downloadLink.click();
  }
}

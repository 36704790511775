import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/service/language.service';
import { ServiceService } from 'src/app/service/service.service';
@Component({
  selector: 'app-activity-record',
  templateUrl: './activity-record.component.html',
  styleUrls: ['./activity-record.component.css'],
})
export class ActivityRecordComponent implements OnInit, OnDestroy {
  data: any = {};
  servicename: any = '';
  adminName: any = '';
  dateFrom: any = '';
  dateEnd: any = '';
  search: any = '';
  sortOrderBy: any = 'desc';
  detail: any;
  totalCount: any;
  activity: any;
  admins: any;
  p: any = 1;
  row: number = 10;
  lang: any;
  subscirption?: Subscription;

  constructor(
    private service: ServiceService,
    private translate: TranslateService,
    private langChange: LanguageService
  ) {}

  ngOnInit(): void {
    this.getActivitydetail();
    this.getActivity();
    this.lang = this.translate.currentLang;
    this.subscirption = this.langChange
      .languageChanged$()
      .subscribe((lang) => (this.lang = lang));
  }
  async getActivity() {
    this.activity = await this.service.GetTokenPathParams(
      '/backoffice/activity/filter'
    );
    this.admins = await this.service.GetTokenPathParams(
      '/backoffice/activity/filter/admin'
    );
  }
  async getActivitydetail() {
    this.p = 1;
    this.data = await this.service.GetTokenPathParams(
      '/backoffice/activity?servicename=' +
        this.servicename +
        '&adminName=' +
        this.adminName +
        '&dateFrom=' +
        this.dateFrom +
        '&dateEnd=' +
        this.dateEnd +
        '&sortBy=datetime&sortOrderBy=' +
        this.sortOrderBy +
        '&skip=' +
        this.p +
        '&limit=' +
        this.row
    );
    this.totalCount = this.data.totalCount;
  }
  async getdetail(id: any) {
    this.detail = await this.service.GetTokenPathParams(
      '/backoffice/activity/' + id
    );
    console.log(this.detail);

    this.detail.message = JSON.parse(this.detail.message);
  }

  async pageChanged(event: any) {
    this.p = event;
    this.data = await this.service.GetTokenPathParams(
      '/backoffice/activity?servicename=' +
        this.servicename +
        '&adminName=' +
        this.adminName +
        '&dateFrom=' +
        this.dateFrom +
        '&dateEnd=' +
        this.dateEnd +
        '&sortBy=datetime&sortOrderBy=' +
        this.sortOrderBy +
        '&skip=' +
        event +
        '&limit=' +
        this.row
    );
  }

  async sortOrder() {
    (await this.sortOrderBy) === 'asc'
      ? (this.sortOrderBy = 'desc')
      : (this.sortOrderBy = 'asc');

    await this.getActivitydetail();
  }

  async searchData(text: string) {
    console.log(text);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.data = filterValue.trim().toLowerCase();
  }

  async clearDate() {
    this.dateFrom = '';
    this.dateEnd = '';
    await this.getActivitydetail();
  }
  async clearFilter() {
    this.servicename = '';
    this.adminName = '';
    this.dateFrom = '';
    this.dateEnd = '';
    this.sortOrderBy = 'desc';
    await this.getActivitydetail();
  }

  ngOnDestroy(): void {
    this.subscirption?.unsubscribe();
  }
}

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/admin-management">
              {{ "Admin management" | translate }}
            </a>
          </li>
          <li class="me-2 ms-2">></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Invite Admin" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="text-title">
        {{ "Invite Admin" | translate }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="background p-4">
        <form [formGroup]="data">
          <div class="row">
            <div
              class="col-md-12 d-flex justify-content-between align-items-center"
            >
              <div class="text-title">
                {{ "Admin detail" | translate }}
              </div>
              <mat-select
                class="form-control w-50"
                formControlName="formId"
                required
                placeholder="{{ 'Select Country' | translate }}"
              >
                <mat-option *ngFor="let i of country" value="{{ i._id }}">
                  {{ i.country }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span> {{ "Admin name" | translate }}* </span>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Admin name' | translate }}"
                formControlName="name"
                required
              />
            </div>
            <div class="col-md-6">
              <span> {{ "Role" | translate }}* </span>
              <mat-select
                class="form-control"
                formControlName="roles"
                required
                placeholder="{{ 'Select Role' | translate }}"
              >
                <mat-option value="DEPLOYMENT">DEPLOYMENT</mat-option>
                <mat-option value="EXTERNAL">EXTERNAL</mat-option>
                <mat-option value="ADMIN">ADMIN</mat-option>
                <mat-option value="SUPERADMIN">SUPERADMIN</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <span> {{ "Email" | translate }}* </span>
              <input
                type="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                class="form-control"
                placeholder="{{ 'Email' | translate }}"
                formControlName="email"
                required
              />
            </div>
            <div class="col-md-6">
              <span> {{ "Phone number" | translate }}* </span>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Phone number' | translate }}"
                formControlName="phoneNumber"
                required
              />
              <!-- <div
                *ngIf="
                  data.get('phoneNumber').errors &&
                  data.get('phoneNumber').touched
                "
                class="text-danger fs-6"
              >
                {{
                  "  Phone number must be at least 10 characters." | translate
                }}
              </div> -->
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-danger">
      <p *ngIf="errMessage">{{ errMessage | translate }}</p>
    </div>
    <div class="col-md-12">
      <button
        class="btnsave"
        id="btnsuccess"
        data-bs-toggle="modal"
        data-bs-target="#Inviteadmin"
        style="display: none"
      >
        {{ "Invite Admin" | translate }}
      </button>
      <button class="btnsave w-25" (click)="Confirm()" *ngIf="!isLoading">
        {{ "Invite Admin" | translate }}
      </button>
      <button class="btnsave w-25" [disabled]="true" *ngIf="isLoading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
  </div>
</div>

<!-- Modal Remove-->
<div
  class="modal fade"
  id="Inviteadmin"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              check_circle
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Invitation has been sent" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 text-center">
            <span style="font-size: 14px">
              {{
                "Invitation link has been sending to their inbox" | translate
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <button
              class="btnsave w-100"
              data-bs-dismiss="modal"
              (click)="back()"
            >
              {{ "Confirm" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { EventEmitter, Injectable } from '@angular/core';
import { MatPhoto } from '../models/interface-photo';

@Injectable({
  providedIn: 'root',
})
export class DragndropserviceService {
  edcNormalPhotoUpdated = new EventEmitter<File[]>();
  contactPhotoPhotoUpdated = new EventEmitter<File[]>();
  materialPhotoPhotoUpdated = new EventEmitter<File[]>();
  customizedMaterialPhotoPhotoUpdated = new EventEmitter<File[]>();
  CompetitionPhotoUpdated = new EventEmitter<File[]>();

  welcomPhotoPhotoUpdated = new EventEmitter<File[]>();
  ocSignPhotoPhotoUpdated = new EventEmitter<File[]>();
  cashierSignPhotoPhotoUpdated = new EventEmitter<File[]>();
  signPadPhotoPhotoUpdated = new EventEmitter<File[]>();
  billFolderPhotoPhotoUpdated = new EventEmitter<File[]>();
  reservedSignPhotoPhotoUpdated = new EventEmitter<File[]>();
  conciergePhotoPhotoUpdated = new EventEmitter<File[]>();
  cWelcomPhotoPhotoUpdated = new EventEmitter<File[]>();
  ccSignPhotoPhotoUpdated = new EventEmitter<File[]>();
  penStandPhotoPhotoUpdated = new EventEmitter<File[]>();
  cdStickerPhotoPhotoUpdated = new EventEmitter<File[]>();

  constructor() {}
  matQuantityInit = new EventEmitter<any>();
}

<!-- render issue photo -->
<div
  class="row g-2"
  *ngIf="
    (data._id === 'edcNormalPhoto' || data._id === 'contactPhoto') && !isMobile;
    else others
  "
>
  <ng-container *ngIf="data.images.length !== 3">
    <div
      class="col-md-4"
      *ngFor="let j of data.images | chunk : 0 : 2; let index = index"
    >
      <img
        (click)="openModal(index, index)"
        src="{{ j.image }}"
        class="box"
        alt="{{ j.filename }}"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="data.images.length === 3">
    <div
      class="col-md-4"
      *ngFor="let j of data.images | chunk : 0 : 3; let index = index"
    >
      <img
        (click)="openModal(index, index)"
        src="{{ j.image }}"
        class="box"
        alt="{{ j.filename }}"
      />
    </div>
  </ng-container>

  <div
    class="col-md-4"
    *ngIf="data.images.length > 3"
    (click)="openModal(2, 2)"
  >
    <div class="box-more">
      <h1>+{{ data.images.length - 2 }}</h1>
    </div>
  </div>
</div>
<!-- render others -->
<ng-template #others>
  <div class="row" *ngIf="!isMobile">
    <div
      class="col-md-3 gap-2"
      *ngFor="let j of data.images; let index = index"
    >
      <img
        (click)="openModal(index, index)"
        src="{{ j.image }}"
        class="box my-3"
        alt="{{ j.filename }}"
      />
    </div>
  </div>
</ng-template>
<!-- render mobile -->
<div class="row g-2" *ngIf="isMobile">
  <div class="col-4 col-lg-1" *ngFor="let j of data.images; let index = index">
    <img
      (click)="openModal(index, index)"
      src="{{ j.image }}"
      class="box"
      alt="{{ j.filename }}"
    />
  </div>
</div>

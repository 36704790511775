import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  retry,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProfile } from '../shares/models/interface-auth';
import { Roles } from '../shares/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = environment.url;
  token = '';
  accessToken = '';
  refreshToken = '';
  role = '';
  profile?: IProfile;
  private userRolesSubject = new BehaviorSubject<string[]>([]);
  userRoles$ = this.userRolesSubject.asObservable();
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private http: HttpClient, private router: Router) {
    const token: any = localStorage.getItem('role');

    if (this.isJsonObject(token)) {
      const data = JSON.parse(token);

      const role = data?.roles;

      if (role && this.checkRole(role)) {
        this.userRolesSubject.next(role);
      } else {
        localStorage.clear();
      }
    } else {
      localStorage.clear();
    }
  }

  isJsonObject(json: any) {
    try {
      JSON.parse(json);
    } catch (e) {
      return false;
    }
    return true;
  }

  login(body: any): Observable<any> {
    return this.http.post<any>(`${this.url}/auth/signin`, body).pipe(
      map((result) => {
        localStorage.setItem('role', JSON.stringify(result));
        return result;
      }),
      tap(() => {
        const token: any = localStorage.getItem('role');
        if (this.isJsonObject(token)) {
          const data = JSON.parse(token);

          const role = data?.roles;

          if (role && this.checkRole(role)) {
            this.userRolesSubject.next(role);
          } else {
            localStorage.clear();
          }
        } else {
          localStorage.clear();
        }
      }),
      catchError(this.handleError)
    );
  }

  public getAccessToken() {
    try {
      const data: any = localStorage.getItem('role');
      const roles = JSON.parse(data);

      this.accessToken = roles?.accessToken;

      if (this.accessToken) {
        return this.accessToken;
      } else {
        return null;
      }
    } catch (error) {
      return null; // Set role to a default value or handle the error as needed
    }
  }

  public getRefreshToken() {
    try {
      const data: any = localStorage.getItem('role');
      const roles = JSON.parse(data);
      this.refreshToken = roles.refreshToken;
      return this.refreshToken;
    } catch (error) {
      return null; // Set role to a default value or handle the error as needed
    }
  }

  public getRole() {
    try {
      const data: any = localStorage.getItem('role');

      const roles = JSON.parse(data);

      this.role = roles?.roles[0];
      if (this.role) {
        return this.role;
      } else {
        return null;
      }
    } catch (error) {
      return null; // Set role to a default value or handle the error as needed
    }
  }
  public getToken() {
    try {
      const data: any = localStorage.getItem('role');
      return data;
    } catch (error) {
      return null; // Set role to a default value or handle the error as needed
    }
  }

  public getProfile(): IProfile | null {
    try {
      const data: any = localStorage.getItem('proFile');
      const profile = JSON.parse(data);
      this.profile = profile;
      if (this.profile) {
        return this.profile;
      }
      return null;
    } catch (error) {
      return null; // Set role to a default value or handle the error as needed
    }
  }

  public isAuthenticated(): Promise<boolean> {
    const role = this.getRole();
    const accessToken = this.getAccessToken();

    return new Promise((resolve, reject) => {
      if (role && accessToken) {
        return resolve(true);
      } else {
        return resolve(false);
      }
    });
  }
  // Function to notify subscribers when the refresh token is updated
  public notifyRefreshTokenUpdated(): void {
    this.refreshTokenSubject.next(null);
  }

  // Getter for the observable to subscribe to refresh token updates
  public get refreshTokenObservable(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  //checkRole
  checkRole(role: any) {
    if (!Array.isArray(role)) {
      return false;
    } else {
      const strRole = role?.join();

      return (
        strRole === Roles.ADMIN ||
        strRole === Roles.DEPLOYMENT ||
        strRole === Roles.EXTERNAL ||
        strRole === Roles.SUPERADMIN
      );
    }
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('offer.service::error ', error.error);
    }
    // this.log.error('error...');
    let errorMessage = '';
    if (Array.isArray(error.error.message)) {
      errorMessage = error.error.message.toString();
    } else {
      errorMessage = error.error.message;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => new Error(`${errorMessage}`));
  }
}

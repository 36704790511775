import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  catchError,
  throwError,
  map,
  EMPTY,
  of,
  tap,
  switchMap,
  from,
} from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ServiceService } from './service.service';
import { StorageService } from './storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private token: any = '';

  private refreshingToken = false; // Flag to track if token refresh is in progress
  getDistrictUrl =
    'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json';
  GetprovinceUrl =
    'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json';

  refreshUrl = '/auth/refresh';

  responseCache = new Map();

  urlToCache = [
    this.getDistrictUrl,
    this.GetprovinceUrl,
    // '/backoffice/form/filter',
  ];

  constructor(
    private authService: AuthService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private service: ServiceService,
    private localStorageService: StorageService
  ) {}

  openSnackBar() {
    this._snackBar.open(
      'Your session has been expired, please login again!',
      'X',
      {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000,
      }
    );
  }
  openSnackBarDenied() {
    this._snackBar.open(
      'Your session has been denied, please login again!',
      'X',
      {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 2000,
      }
    );
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.token = this.authService.getAccessToken();

    const cache = this.responseCache.get(request.urlWithParams);

    if (cache) {
      return of(cache);
    }

    if (this.token) {
      if (
        request.url !== this.getDistrictUrl &&
        request.url !== this.GetprovinceUrl
      ) {
        if (!request.url.includes(this.refreshUrl)) {
          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + this.token },
          });
        }
      }
    } else {
      request = request.clone({
        setHeaders: { Authorization: 'Basic' },
      });
    }

    if (!this.canCache(request)) {
      return next
        .handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) =>
            this.errorAuthHandle(error, request, next)
          )
        );
    } else {
      return next.handle(request).pipe(
        tap((res) => {
          this.responseCache.set(request.urlWithParams, res);
        }),

        catchError((error: HttpErrorResponse) =>
          this.errorAuthHandle(error, request, next)
        )
      );
    }
  }

  canCache(request: HttpRequest<unknown>) {
    return this.urlToCache.some((keyword) =>
      request.urlWithParams.includes(keyword)
    );
  }
  errorAuthHandle(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ) {
    if (error.status === 401 && error.error.message === 'Unauthorized') {
      return this.refreshTokenMethod(request, next);
    } else if (
      error.status === 403 &&
      error.error.message === 'Access Denied'
    ) {
      this.openSnackBarDenied();
      localStorage.clear();
      window.location.replace('/login');

      return EMPTY;
    } else {
      return throwError((): any => error);
    }
  }
  private refreshTokenMethod(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true; // Set flag to indicate token refresh is in progress

      return this.service.RefreshToken().pipe(
        switchMap((res: any) => {
          const store = this.localStorageService.getStorage();
          const role = store.find((item) => item.key === 'role');
          const value = role?.value;

          value.accessToken = res.accessToken;
          value.refreshToken = res.refreshToken;
          window.localStorage.setItem('role', JSON.stringify(value));

          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + res.accessToken },
          });

          return next.handle(request).pipe(
            tap(() => {
              // Reset the flag once the request is successfully handled
              this.refreshingToken = false;
              // Update the refresh token and notify subscribers
              this.authService.notifyRefreshTokenUpdated();
            })
          );
        }),
        catchError((error) => {
          // Reset the flag if there's an error during token refresh
          this.refreshingToken = false;

          if (error.status === 401 && error.error.message === 'Unauthorized') {
            this.openSnackBar();
            localStorage.clear();
            window.location.replace('/login');

            return EMPTY;
          } else {
            return throwError(() => error);
          }
        })
      );
    } else {
      // If a refresh token request is already in progress, wait for it to complete
      return this.authService.refreshTokenObservable.pipe(
        switchMap(() => {
          return next.handle(request);
        })
      );
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-database-deployment',
  templateUrl: './database-deployment.component.html',
  styleUrls: ['./database-deployment.component.css'],
})
export class DatabaseDeploymentComponent implements OnInit {
  lang: any;
  subscription?: Subscription;
  country: any;
  formManagement: any;
  dataFilters: any;
  p: number = 1;
  row: number = 10;
  sortOrderBy: any = 'desc';
  isLoading = false;
  dataList: any = [];
  totalCount: any;
  materialChoices: string[] = [];
  endScroll: string = '';
  formId = '';
  constructor(
    private service: ServiceService,
    private transalate: TranslateService,
    private langChange: LanguageService,
    private router: Router,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  toggleLoading = () => (this.isLoading = !this.isLoading);

  onScroll = () => {
    if (this.dataList.length <= this.totalCount) {
      this.p++;
      this.getAllmerchant(true, false);
    }
  };

  selectData: any = {
    locationName: '',
    category: '',
    cardAcceptance: '',
    acquirerBank: '',
    contractlessStatus: '',
    merchantName: '',
    generalMaterial: '',
    customizeMaterial: '',
  };

  ngOnInit(): void {
    this.lang = this.transalate.currentLang;
    this.subscription = this.langChange
      .languageChanged$()
      .subscribe((lang) => (this.lang = lang));
    const profile = this.authService.getProfile();
    this.formId = profile?.formId._id;
    this.getFilter();
    this.getAllmerchant(false, true);
  }

  async getFilter() {
    let data: any = await this.service.GetTokenPathParams(
      '/merchant/filter/' + this.formId
    );
    this.dataFilters = data;
    console.log(this.dataFilters);
  }

  async createParam() {
    const paramSummary = {
      country: this.formId,
      locationName: this.selectData.locationName,
      category: this.selectData.category,
      cardAcceptance: this.selectData.cardAcceptance,
      acquirerBank: this.selectData.acquirerBank || '',
      contractlessStatus: this.selectData.contractlessStatus,
      merchantName: this.selectData.merchantName,
      generalMaterial: this.selectData.generalMaterial,
      customizeMaterial: this.selectData.customizeMaterial,
      sortBy: 'createdAt',
      sortOrderBy: this.sortOrderBy,
    };

    localStorage.setItem('data', JSON.stringify(paramSummary));

    const params = new URLSearchParams(paramSummary);

    return params;
  }

  async initialFilter() {
    const data: any = localStorage.getItem('data');
    const filterData = JSON.parse(data);

    if (filterData) {
      Object.keys(filterData).forEach((key) => {
        if (this.selectData.hasOwnProperty(key)) {
          this.selectData[key] = filterData[key];
        }
      });
      this.sortOrderBy = filterData.sortOrderBy;
    }
  }

  async getAllmerchant(isPagination?: any, isInitial?: any) {
    this.toggleLoading();
    if (!isPagination) {
      this.p = 1;
    }

    if (isInitial) {
      await this.initialFilter();
    }

    const params = await this.createParam();

    let data: any = await this.service.GetTokenPathParams(
      '/merchant/map/filter?skip=' +
        this.p +
        '&limit=' +
        this.row +
        `&${params.toString()}`
    );
    this.totalCount = data.totalCount;

    if (isPagination) {
      if (this.dataList.length > 0) {
        this.dataList = [...this.dataList, ...data.totalData];
        this.toggleLoading();
      } else {
        this.dataList = data.totalData;
        this.toggleLoading();
      }
    } else {
      this.dataList = data.totalData;
      this.toggleLoading();
    }
    console.log(data);

    if (this.dataList.length >= this.totalCount && this.totalCount !== 0) {
      this.endScroll = 'No more data';
    }
  }

  async sortOrder() {
    (await this.sortOrderBy) === 'asc'
      ? (this.sortOrderBy = 'desc')
      : (this.sortOrderBy = 'asc');

    await this.getAllmerchant();
  }

  Clearall() {
    this.endScroll = '';
    for (let i of Object.keys(this.selectData)) {
      this.selectData[i] = '';
    }
    this.sortOrderBy = 'desc';
    this.getAllmerchant(false);
  }
  onSearch() {
    this.endScroll = '';
    this.getAllmerchant(false);
  }

  clearOnSearch() {
    this.endScroll = '';
    this.selectData.merchantName = '';
    this.getAllmerchant(false);
  }

  detail(id: any) {
    this.router.navigate(['deployment/database-marchant-info', id]);
  }
  openSnackBar() {
    this._snackBar.open('Coming soon..', 'X', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }
}

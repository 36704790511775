<div class="container-fluid" *ngIf="data">
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <div class="text-title">
        {{ "Form-management" | translate }}
      </div>
      <select
        class="form-select w-50"
        (ngModelChange)="getFormId($event)"
        [(ngModel)]="countryId"
      >
        <option *ngFor="let i of totalCountry" [value]="i._id">
          {{ i.country }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "Merchant Detail" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span>
        {{ data[0]?.fieldName | translate }}
      </span>
      <div class="box-items">
        <span>
          {{ "Always Required" | translate }}
        </span>
      </div>
    </div>
  </div>
  <div
    class="box-modal"
    data-bs-toggle="modal"
    data-bs-target="#staticCategory"
  >
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span>
        {{ data[1]?.fieldName | translate }}
      </span>
      <div class="box-items">
        <span>
          {{ "Always Required" | translate }}
        </span>
        <span class="material-symbols-outlined ms-4"> arrow_forward_ios </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "Location" | translate }}
      </div>
    </div>
  </div>

  <div
    class="box-modal"
    data-bs-toggle="modal"
    data-bs-target="#staticLocation"
  >
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span>
        {{ data[2]?.fieldName | translate }}
      </span>
      <div class="box-items">
        <span>
          {{ "Always Required" | translate }}
        </span>
        <span class="material-symbols-outlined ms-4"> arrow_forward_ios </span>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span>
        {{ "Address" | translate }}
      </span>
      <div class="box-items">
        <span>
          {{ "Always Required" | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[3]?.isShow === true ? '' : 'notShow'">
        {{ data[3]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[3]?.isShow"
          [ngModel]="data[3]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 3)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[3]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(3)"
          >
            <ng-container *ngIf="data[3]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[3]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span
        [ngClass]="
          data[4]?.isShow === true && data[5]?.isShow === true ? '' : 'notShow'
        "
      >
        {{ "Google Map Link" | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [checked]="data[4]?.isRequired && data[5]?.isRequired"
          (change)="toggleIsRequiredMap($event)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="
              data[4]?.isShow === true && data[5]?.isShow === true
                ? ''
                : 'notShow'
            "
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(4, 5)"
          >
            <ng-container *ngIf="data[4]?.isShow && data[5]?.isShow"
              >visibility</ng-container
            >
            <ng-container *ngIf="!data[4]?.isShow && !data[5]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "Contact" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[6]?.isShow === true ? '' : 'notShow'">
        {{ data[6]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[6]?.isShow"
          [ngModel]="data[6]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 6)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[6]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(6)"
          >
            <ng-container *ngIf="data[6]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[6]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[7]?.isShow === true ? '' : 'notShow'">
        {{ data[7]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[7]?.isShow"
          [ngModel]="data[7]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 7)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[7]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(7)"
          >
            <ng-container *ngIf="data[7]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[7]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[8]?.isShow === true ? '' : 'notShow'">
        {{ data[8]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[8]?.isShow"
          [ngModel]="data[8]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 8)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[8]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(8)"
          >
            <ng-container *ngIf="data[8]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[8]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[9]?.isShow === true ? '' : 'notShow'">
        {{ data[9]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[9]?.isShow"
          [ngModel]="data[9]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 9)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[9]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(9)"
          >
            <ng-container *ngIf="data[9]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[9]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "Type of Payment" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[10]?.isShow === true ? '' : 'notShow'">
        {{ data[10]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[10]?.isShow"
          [ngModel]="data[10]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 10)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[10]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(10)"
          >
            <ng-container *ngIf="data[10]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[10]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
        <span
          data-bs-toggle="modal"
          data-bs-target="#staticPayment"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[11]?.isShow === true ? '' : 'notShow'">
        {{ data[11]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[11]?.isShow"
          [ngModel]="data[11]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 11)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[11]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(11)"
          >
            <ng-container *ngIf="data[11]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[11]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[12]?.isShow === true ? '' : 'notShow'">
        {{ data[12]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[12]?.isShow"
          [ngModel]="data[12]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 12)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[12]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(12)"
          >
            <ng-container *ngIf="data[12]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[12]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[13]?.isShow === true ? '' : 'notShow'">
        {{ data[13]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[13]?.isShow"
          [ngModel]="data[13]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 13)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[13]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(13)"
          >
            <ng-container *ngIf="data[13]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[13]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>

        <span
          data-bs-toggle="modal"
          data-bs-target="#staticQR"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "EDC Terminal" | translate }}
      </div>
    </div>
  </div>

  <!-- <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[14]?.isShow === true ? '' : 'notShow'">
        {{ data[14]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[14]?.isShow"
          [checked]="data[14]?.isRequired"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[14]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(14)"
          >
            <ng-container *ngIf="data[14]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[14]?.isShow">visibility_off</ng-container>
          </span>
        </div>

        <span
          data-bs-toggle="modal"
          data-bs-target="#staticEDC"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[15]?.isShow === true ? '' : 'notShow'">
        {{ data[15]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[15]?.isShow"
          [checked]="data[15]?.isRequired"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[15]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(15)"
          >
            <ng-container *ngIf="data[15]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[15]?.isShow">visibility_off</ng-container>
          </span>
        </div>
      </div>
    </div>
  </div> -->
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span
        [ngClass]="
          data[14]?.isShow === true && data[15]?.isShow === true
            ? ''
            : 'notShow'
        "
      >
        {{ "EDC Bank and Terminal ID" | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[14]?.isShow && !data[15]?.isShow"
          [ngModel]="data[14]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 14)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="
              data[14]?.isShow === true && data[15]?.isShow === true
                ? ''
                : 'notShow'
            "
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(14, 15)"
          >
            <ng-container *ngIf="data[14]?.isShow && data[15]?.isShow"
              >visibility</ng-container
            >
            <ng-container *ngIf="!data[14]?.isShow && !data[15]?.isShow"
              >visibility_off</ng-container
            >
          </span>
          <span
            data-bs-toggle="modal"
            data-bs-target="#staticEDC"
            class="material-symbols-outlined ms-4 curser"
          >
            arrow_forward_ios
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "EDC Status" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[16]?.isShow === true ? '' : 'notShow'">
        {{ "Normal EDC Status" | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[16]?.isShow"
          [ngModel]="data[16]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 16)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[16]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(16)"
          >
            <ng-container *ngIf="data[16]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[16]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[17]?.isShow === true ? '' : 'notShow'">
        {{ data[17]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[17]?.isShow"
          [ngModel]="data[17]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 17)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[17]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(17)"
          >
            <ng-container *ngIf="data[17]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[17]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[18]?.isShow === true ? '' : 'notShow'">
        {{ data[18]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[18]?.isShow"
          [ngModel]="data[18]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 18)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[18]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(18)"
          >
            <ng-container *ngIf="data[18]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[18]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[19]?.isShow === true ? '' : 'notShow'">
        {{ data[19]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[19]?.isShow"
          [ngModel]="data[19]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 19)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[19]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(19)"
          >
            <ng-container *ngIf="data[19]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[19]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "VISA Material" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[20]?.isShow === true ? '' : 'notShow'">
        {{ data[20]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[20]?.isShow"
          [ngModel]="data[20]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 20)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[20]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(20)"
          >
            <ng-container *ngIf="data[20]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[20]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>

        <span
          data-bs-toggle="modal"
          data-bs-target="#staticMaterial"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>
  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[21]?.isShow === true ? '' : 'notShow'">
        {{ data[21]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[21]?.isShow"
          [ngModel]="data[21]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 21)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[21]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(21)"
          >
            <ng-container *ngIf="data[21]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[21]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
        <span
          data-bs-toggle="modal"
          data-bs-target="#staticCustomize"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="title">
        {{ "Competitor Info" | translate }}
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[22]?.isShow === true ? '' : 'notShow'">
        {{ data[22]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[22]?.isShow"
          [ngModel]="data[22]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 22)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[22]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(22)"
          >
            <ng-container *ngIf="data[22]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[22]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>

        <span
          data-bs-toggle="modal"
          data-bs-target="#staticCompetitor"
          class="material-symbols-outlined ms-4 curser"
        >
          arrow_forward_ios
        </span>
      </div>
    </div>
  </div>

  <div class="box">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span [ngClass]="data[23]?.isShow === true ? '' : 'notShow'">
        {{ data[23]?.fieldName | translate }}
      </span>

      <div class="d-flex">
        <input
          type="checkbox"
          [disabled]="!data[23]?.isShow"
          [ngModel]="data[23]?.isRequired"
          (ngModelChange)="toggleIsRequired($event, 23)"
        />
        <div class="box-items">
          <span
            class="ms-4"
            [ngClass]="data[23]?.isShow === true ? '' : 'notShow'"
          >
            {{ "Require" | translate }}
          </span>
          <span
            class="material-symbols-outlined ms-4"
            (click)="toggleIsShow(23)"
          >
            <ng-container *ngIf="data[23]?.isShow">visibility</ng-container>
            <ng-container *ngIf="!data[23]?.isShow"
              >visibility_off</ng-container
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-4 mb-5">
      <button
        class="btnsave"
        data-bs-toggle="modal"
        data-bs-target="#Savechange"
      >
        {{ "Save Change" | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Modal Category-->
<div
  class="modal fade"
  id="staticCategory"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "Category" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[1]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(1, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceCategory"
              (keyup.enter)="
                addchoices(1, choice.choiceCategory, false, 'choiceCategory')
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(1, choice.choiceCategory, false, 'choiceCategory')
              "
            >
              add_circle
            </span>
          </div>

          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Location-->
<div
  class="modal fade"
  id="staticLocation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-xl modal-fullscreen-xl-down modal-dialog-centered"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "Location" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Street</th>
              <th>Floor</th>
              <th>District</th>
              <th>Province</th>
              <th>Zipcode</th>
              <th class="lat-long">Latitude</th>
              <th class="lat-long">Longitude</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let i of data[2]?.choices; let index = index">
              <tr>
                <td>{{ i?.name }}</td>
                <td>{{ i?.street }}</td>
                <td>{{ i?.floor }}</td>
                <td>{{ i?.district }}</td>
                <td>{{ i?.province }}</td>
                <td>{{ i?.zipcode }}</td>
                <td class="lat-long">{{ i?.latitude }}</td>
                <td class="lat-long">{{ i?.longitude }}</td>
                <td>
                  <button
                    type="button"
                    class="btn-close ms-3"
                    (click)="deleteChoices(2, index)"
                  ></button>
                </td>
              </tr>
            </ng-container>
            <tr>
              <td>
                <input
                  type="text"
                  class="choice"
                  [(ngModel)]="choiceLocation.name"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="choice"
                  [(ngModel)]="choiceLocation.street"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="choice lat-long"
                  [(ngModel)]="choiceLocation.floor"
                />
              </td>
              <td>
                <form [formGroup]="Formdata" *ngIf="country === 'Thailand'">
                  <input
                    type="text"
                    formControlName="district"
                    class="choice"
                    [matAutocomplete]="autoDistrict"
                    matInput
                    (ngModelChange)="addDistrictProvince($event, 'district')"
                  />
                  <mat-autocomplete #autoDistrict="matAutocomplete">
                    <mat-option
                      *ngFor="let option of filteredOptionDistrict | async"
                      [value]="option"
                    >
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </form>
                <input
                  type="text"
                  class="choice"
                  [(ngModel)]="choiceLocation.district"
                  *ngIf="country !== 'Thailand'"
                />
              </td>
              <td>
                <form [formGroup]="Formdata" *ngIf="country === 'Thailand'">
                  <input
                    type="text"
                    formControlName="province"
                    class="choice"
                    [matAutocomplete]="auto"
                    matInput
                    (ngModelChange)="addDistrictProvince($event, 'province')"
                  />
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let option of filteredOptionProvince | async"
                      [value]="option"
                    >
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </form>
                <input
                  type="text"
                  class="choice"
                  [(ngModel)]="choiceLocation.province"
                  *ngIf="country !== 'Thailand'"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="choice"
                  [(ngModel)]="choiceLocation.zipcode"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="choice lat-long"
                  [(ngModel)]="choiceLocation.latitude"
                />
              </td>
              <td>
                <input
                  type="text"
                  class="choice lat-long"
                  [(ngModel)]="choiceLocation.longitude"
                  (keyup.enter)="addchoices(2, choiceLocation, true)"
                />
              </td>
              <td class="text-end">
                <span
                  class="material-symbols-outlined curser"
                  (click)="addchoices(2, choiceLocation, true)"
                >
                  add_circle
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Type of Payment-->
<div
  class="modal fade"
  id="staticPayment"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "Card Acceptance" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[10]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(10, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceAcceptance"
              (keyup.enter)="
                addchoices(
                  10,
                  choice.choiceAcceptance,
                  false,
                  'choiceAcceptance'
                )
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(
                  10,
                  choice.choiceAcceptance,
                  false,
                  'choiceAcceptance'
                )
              "
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="staticQR"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "QR Pay" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[13]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(13, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choicePay"
              (keyup.enter)="
                addchoices(13, choice.choicePay, false, 'choicePay')
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="addchoices(13, choice.choicePay, false, 'choicePay')"
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="staticEwallet"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "E-wallet" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[14]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(14, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceWallet"
              (keyup.enter)="
                addchoices(14, choice.choiceWallet, false, 'choiceWallet')
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(14, choice.choiceWallet, false, 'choiceWallet')
              "
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal EDC-->
<div
  class="modal fade"
  id="staticEDC"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "EDC Bank" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[14]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(14, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceBank"
              (keyup.enter)="
                addchoices(14, choice.choiceBank, false, 'choiceBank')
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="addchoices(14, choice.choiceBank, false, 'choiceBank')"
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Material-->
<div
  class="modal fade"
  id="staticMaterial"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "General Material" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[20]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(20, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceGeneralMat"
              (keyup.enter)="
                addchoices(
                  20,
                  choice.choiceGeneralMat,
                  false,
                  'choiceGeneralMat'
                )
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(
                  20,
                  choice.choiceGeneralMat,
                  false,
                  'choiceGeneralMat'
                )
              "
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Customize-->
<div
  class="modal fade"
  id="staticCustomize"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "Customize Material" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[21]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(21, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceCustomizelMat"
              (keyup.enter)="
                addchoices(
                  21,
                  choice.choiceCustomizelMat,
                  false,
                  'choiceCustomizelMat'
                )
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(
                  21,
                  choice.choiceCustomizelMat,
                  false,
                  'choiceCustomizelMat'
                )
              "
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Competitor-->
<div
  class="modal fade"
  id="staticCompetitor"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">
          {{ "Competitor Bank" | translate }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngFor="let i of data[22]?.choices; let index = index">
          <div class="col-md-12 mt-2 d-flex justify-content-between">
            {{ i }}
            <button
              type="button"
              class="btn-close ms-3"
              (click)="deleteChoices(22, index)"
            ></button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 d-flex">
            <input
              type="text"
              class="choice"
              placeholder="{{ 'New choice here' | translate }}"
              [(ngModel)]="choice.choiceCompetitor"
              (keyup.enter)="
                addchoices(
                  22,
                  choice.choiceCompetitor,
                  false,
                  'choiceCompetitor'
                )
              "
            />
            <span
              class="material-symbols-outlined curser"
              (click)="
                addchoices(
                  22,
                  choice.choiceCompetitor,
                  false,
                  'choiceCompetitor'
                )
              "
            >
              add_circle
            </span>
          </div>
          <div class="col-md-12">
            <span style="font-size: 10px; color: #868686">
              {{ "Press enter to create new choice" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 mb-2">
            <button class="btnsave w-100" data-bs-dismiss="modal">
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Savechange-->
<div
  class="modal fade"
  id="Savechange"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              error
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Are you sure to change form setting" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 text-center">
            <span style="font-size: 14px">
              {{
                "Please confirm admin password to save the change" | translate
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <input
              type="password"
              class="form-control"
              placeholder="{{ 'Confirm Admin Password' | translate }}"
              [(ngModel)]="password"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 text-danger text-center">
            {{ message }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 d-flex justify-content-evenly">
            <button
              class="btnsave"
              (click)="onSubmit()"
              data-bs-toggle="modal"
              data-bs-target="#Formchanged"
            >
              {{ "Confirm" | translate }}
            </button>
            <button class="btncancel" id="btnclose" data-bs-dismiss="modal">
              {{ "Cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Change Success -->
<div
  class="modal fade"
  id="Formchanged"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <!-- Use ng-template with *ngIf to conditionally render the content -->
        <div
          class="d-flex justify-content-center align-items-center w-100 h-100"
          *ngIf="isLoading"
        >
          <!-- <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> -->
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-template [ngIf]="message !== null && !isLoading">
          <div class="row">
            <div class="col-md-12 text-center">
              <span
                class="material-symbols-outlined"
                style="font-size: 100px; color: #388087"
              >
                {{ message == "Success" ? "check_circle" : "error" }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-2 text-center">
              <span style="font-size: 14px" *ngIf="message == 'Success'">
                {{ "Form setting has been saved" | translate }}
              </span>
              <span style="font-size: 14px" *ngIf="message !== 'Success'">
                {{ message }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-5">
              <button
                class="btnsave w-100"
                data-bs-toggle="modal"
                data-bs-target="#Savechange"
                *ngIf="message !== 'Success'"
              >
                {{ "Back" | translate }}
              </button>
              <button
                class="btnsave w-100"
                data-bs-dismiss="modal"
                *ngIf="message == 'Success'"
                (click)="refreshCurrentRoute()"
              >
                {{ "Done" | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/database">
              {{ "Database" | translate }}
            </a>
          </li>
          <li class="me-2 ms-2">></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Export" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="text-title d-flex align-items-center">
        {{ "Export" | translate }}
      </div>
      <button class="btncancel">
        {{ "Clear filter" | translate }}
      </button>
    </div>
  </div>
  <div class="background p-3">
    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Date range" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Category" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Card acceptance" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <select name="" id="" class="form-select mt-2">
          <option value="">1</option>
          <option value="">1</option>
          <option value="">1</option>
        </select>
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "QR Payment" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "E-Wallet" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Contactless acceptance" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mt-3">
        <div>
          <input class="me-3" type="checkbox" name="" id="" />
          <span>
            {{ "Minimum spending (THB)" | translate }}
          </span>
        </div>
        <mat-slider min="0" max="1000">
          <input matSliderStartThumb />
          <input matSliderEndThumb />
        </mat-slider>
      </div>
      <div class="col-md-6 mt-3">
        <div>
          <input class="me-3" type="checkbox" name="" id="" />
          <span>
            {{ "Surcharge (THB)" | translate }}
          </span>
        </div>
        <mat-slider min="1" max="5" step="0.5" value="1.5">
          <input matSliderStartThumb #itemHeight />
          <input matSliderEndThumb />
        </mat-slider>

        <label> {{ itemHeight.value }} </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "EDC Bank" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "EDC Status" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "EDC Place" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <select name="" id="" class="form-select mt-2">
          <option value="">1</option>
          <option value="">1</option>
          <option value="">1</option>
        </select>
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Staff Performance" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-5">
        <div class="text-title">
          <h1>
            {{ "Location" | translate }}
          </h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Location" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Street" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "District" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <select name="" id="" class="form-select mt-2">
          <option value="">1</option>
          <option value="">1</option>
          <option value="">1</option>
        </select>
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Province" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Zipcode" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-5">
        <div class="text-title">
          <h1>
            {{ "POSM Deployment" | translate }}
          </h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Material" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
      <div class="col-md-4 mt-3">
        <div class="select-item">
          <span>{{ "Competitors Info" | translate }}</span>
          <span>
            <u>{{ "Clear" | translate }}</u>
          </span>
        </div>
        <input type="date" name="" id="" class="form-select mt-2" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-0 d-flex align-items-center">
      <input class="me-2" type="checkbox" />
      <span>
        {{ "Export data with Photo" | translate }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mb-5">
      <button class="btnsave d-flex align-items-center">
        <span class="material-symbols-outlined me-2"> download </span>
        {{ "Export" | translate }}
      </button>
    </div>
  </div>
</div>

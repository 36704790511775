<div class="container">
  <div class="row">
    <div class="col-md-12 text-center">
      <span
        class="material-symbols-outlined"
        style="font-size: 100px; color: #388087"
      >
        error
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <span> {{ "Are you sure to cancel add data" | translate }}? </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-5 d-flex">
      <button class="btnsave w-100" [mat-dialog-close]="true">
        {{ "Done" | translate }}
      </button>
      <button class="btncancel w-100 ms-1" [mat-dialog-close]="false">
        {{ "No" | translate }}
      </button>
    </div>
  </div>
</div>

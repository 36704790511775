<div class="col-md-12 mt-3">
  <span *ngIf="data.isRequired && !isEdit" class="small-title">
    {{ data.fieldName | translate }}*
  </span>
  <span *ngIf="!data.isRequired || isEdit" class="small-title">
    {{ data.fieldName | translate }}
  </span>
  <div class="mt-3">
    <input
      class="form-select d-flex align-items-center w-100 mb-2 small-title"
      placeholder="{{ placeholder | translate }}"
      #cardInput
      #autocompleteTriggerCard="matAutocompleteTrigger"
      [formControl]="cardAcceptanceCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="itemList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      [required]="isValid"
    />

    <mat-chip-list #itemList aria-label="itemList">
      <mat-chip
        *ngFor="let card of cardList"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(card)"
      >
        {{ card }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
      autoActiveFirstOption
    >
      <mat-option
        *ngFor="let card of filteredCardAcceptance | async"
        [value]="card"
      >
        <mat-checkbox
          color="primary"
          style="padding: 0 12px"
          [checked]="cardList.indexOf(card) >= 0"
        ></mat-checkbox>
        {{ card }}
      </mat-option>
    </mat-autocomplete>
    <button
      mat-icon-button
      matSuffix
      style="width: 34px; height: 34px; display: none"
      (click)="openAuto(autocompleteTriggerCard)"
    >
      <mat-icon style="width: 34px">expand_more</mat-icon>
    </button>
  </div>
</div>

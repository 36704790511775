import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { COMMA, ENTER, G } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup } from '@angular/forms';
import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { ServiceService } from 'src/app/service/service.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/service/language.service';
import { Observable, Subscription, filter, map, startWith } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { Roles } from 'src/app/shares/roles.enum';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css'],
  animations: [
    trigger('onOff', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
        animate(500),
      ]),
    ]),
  ],
})
export class DatabaseComponent implements OnInit, OnDestroy {
  Formdata!: FormGroup;
  filteredOptionProvince?: Observable<string[]>;
  filteredOptionDistrict?: Observable<string[]>;
  checklist: any;
  checkedList: any;
  p: number = 1;
  row: number = 10;
  totalCount: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  lang: any;
  subscription?: Subscription;
  deleteList: string[] = [];
  opened = false;
  country: any;
  dataFilters: any;
  password: any;
  response: any;
  competitorList: any;
  getAllMer: boolean = false;
  progress = 0;
  errMessage = '';
  isExportLoading = false;
  isLoading = false;
  isDone = false;
  user: any;
  @ViewChild('btnclose') btnclose: any;
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private service: ServiceService,
    private transalate: TranslateService,
    private langChange: LanguageService,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private _snackBar: MatSnackBar
  ) {}
  formManagement: any;
  sortOrderBy: any = 'desc';
  showmore: any = false;
  data: any = {
    min: '',
    response: [],
  };
  privince: any = [];
  countryId: any;
  totalCountry: any;
  selectData: any = {
    country: '',
    locationName: '',
    province: '',
    category: '',
    dateFrom: '',
    dateEnd: '',
    cardAcceptance: '',
    contractlessStatus: '',
    otherPayment: '',
    street: '',
    district: '',
    zipcode: '',
    min_spen: false,
    minSpendFrom: '',
    minSpendTo: '',
    surcharge_min: false,
    surchargeFrom: '',
    surchargeTo: '',
    edcBank: '',
    edcStatus: '',
    edcPlace: '',
    staffPer: '',
    competitior: '',
    merchantName: '',
    generalMaterial: '',
    customizeMaterial: '',
  };
  role: any;
  ngOnInit(): void {
    this.lang = this.transalate.currentLang;
    this.subscription = this.langChange
      .languageChanged$()
      .subscribe((lang) => (this.lang = lang));

    this.role = this.authService.getRole();

    this.Formdata = this.fb.group({
      location: '',
      category_id: '',
      phone: '',
      location_on_street: '',
      district: '',
      province: '',
      cardAcceptance: '',
    });
    if (this.role === 'SUPERADMIN' || this.role === 'ADMIN') {
      this.getCountrys();
    } else {
      this.user = this.authService.getProfile();
      this.countryId = this.user.formId._id;
      this.getFilter(this.countryId);
      this.getAllmerchant(false, true);
    }
    this.Getprovince();
    this.Getdistrict();

    this.filteredOptionProvince = this.Formdata.get(
      'province'
    )?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterProvince(value || ''))
    );
    this.filteredOptionDistrict = this.Formdata.get(
      'district'
    )?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDistrict(value || ''))
    );
  }
  private _filterProvince(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.privince?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filterDistrict(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.district?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  async getFormId(event: any) {
    this.isLoading = true;
    const formId = event;
    this.countryId = formId;
    const getCountry = await this.totalCountry.filter(
      (item: any) => item._id === event
    );
    this.country = getCountry[0].country;

    await this.getFilter(formId);
    await this.Clearall();
    this.isLoading = false;
  }
  reorderCountries() {
    this.totalCountry?.sort((a: any, b: any) =>
      a.country === 'Thailand' ? -1 : b.country === 'Thailand' ? 1 : 0
    );
  }

  async getCountrys() {
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/filter'
    );
    this.totalCountry = data?.totalData;
    this.reorderCountries();

    const dataLocal: any = localStorage.getItem('data');
    const parseData = JSON.parse(dataLocal);

    if (parseData?.length > 0) {
      this.countryId = parseData[3].country;
      this.country = parseData[4];
    } else {
      for (let item of this.totalCountry) {
        if (item.country === 'Thailand') {
          this.countryId = item._id;
          this.country = item.country;
        }
      }
    }

    this.getFilter(this.countryId);
    this.getAllmerchant(false, true);
  }

  updateMinSpen(event: any) {
    this.selectData.minSpendTo = JSON.stringify(event.value);
  }
  updateSurcharge(event: any) {
    this.selectData.surchargeTo = JSON.stringify(event.value);
  }
  toggleMinSpen(event: any) {
    if (!event) {
      this.selectData.minSpendFrom = '';
      this.selectData.minSpendTo = '';
    }
  }
  toggleSurcharge(event: any) {
    if (!event) {
      this.selectData.surchargeFrom = '';
      this.selectData.surchargeTo = '';
    }
  }

  async createParam() {
    const paramSummary = {
      country: this.countryId,
      locationName: this.selectData.locationName,
      dateFrom: this.selectData.dateFrom,
      dateEnd: this.selectData.dateEnd,
      category: this.selectData.category,
      cardAcceptance: this.selectData.cardAcceptance,
      otherPayment: this.selectData.otherPayment || '',
      contractlessStatus: this.selectData.contractlessStatus,
      minSpendFrom: this.selectData.minSpendFrom,
      minSpendTo: this.selectData.minSpendTo,
      surchargeFrom: this.selectData.surchargeFrom,
      surchargeTo: this.selectData.surchargeTo,
      edcBank: this.selectData.edcBank,
      edcStatus: this.selectData.edcStatus,
      edcPlace: this.selectData.edcPlace,
      staffPer: this.selectData.staffPer,
      street: this.selectData.street,
      district: this.Formdata.value.district || '',
      province: this.Formdata.value.province || '',
      zipcode: this.selectData.zipcode,
      competitior: this.selectData.competitior,
      sortBy: 'date',
      sortOrderBy: this.sortOrderBy,
      merchantName: this.selectData.merchantName,
      generalMaterial: this.selectData.generalMaterial,
      customizeMaterial: this.selectData.customizeMaterial,
    };

    const togglePayment = {
      min_spen: this.selectData.min_spen,
      surcharge_min: this.selectData.surcharge_min,
    };

    const dataToStorage = [
      this.p,
      this.row,
      this.showmore,
      paramSummary,
      this.country,
      togglePayment,
    ];

    localStorage.setItem('data', JSON.stringify(dataToStorage));

    const params = new URLSearchParams(paramSummary);

    return params;
  }
  async initialFilter() {
    const data: any = localStorage.getItem('data');
    const parseData = JSON.parse(data);

    if (parseData) {
      this.p = parseData[0];
      this.row = parseData[1];
      this.showmore = parseData[2];
      const filterData = parseData[3];
      Object.keys(filterData).forEach((key) => {
        if (this.selectData.hasOwnProperty(key)) {
          this.selectData[key] = filterData[key];
        }
      });
      const toggleData = parseData[5];
      Object.keys(toggleData).forEach((key) => {
        if (this.selectData.hasOwnProperty(key)) {
          this.selectData[key] = toggleData[key];
        }
      });
      this.Formdata.get('province')?.setValue(filterData.province);
      this.Formdata.get('district')?.setValue(filterData.district);
    }
  }

  async getAllmerchant(isPagination?: any, isInitial?: any) {
    if (!isPagination) {
      this.p = 1;
    }
    if (isInitial) {
      await this.initialFilter();
    }

    this.deleteList = [];
    this.getAllMer = false;
    const params = await this.createParam();

    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/database?skip=' +
        this.p +
        '&limit=' +
        this.row +
        `&${params.toString()}`
    );

    this.data = data;
    this.totalCount = this.data.totalCount;
    console.log(data);
  }

  async exportAllmerchant() {
    this.progress = 0;
    this.errMessage = '';
    this.isExportLoading = true;
    this.isDone = false;
    const data: any = localStorage.getItem('data');
    const parseData = await JSON.parse(data);
    const filterData = parseData[3];

    if (Boolean(filterData.dateFrom) && Boolean(filterData.dateEnd)) {
      const dateStart: any = new Date(filterData.dateFrom);
      const dateEnd: any = new Date(filterData.dateEnd);
      const dayOfMilsec = 86400000;
      const dateRage = (dateEnd - dateStart) / dayOfMilsec;
      const selectDateRange =
        (this.selectData.dateEnd - this.selectData.dateFrom) / dayOfMilsec;

      if (
        dateRage >= 0 &&
        dateRage < 7 &&
        selectDateRange >= 0 &&
        selectDateRange < 7
      ) {
        const params = await this.createParam();
        this.service
          .downloadFile(
            '/backoffice/database/v2/export?skip=' +
              this.p +
              '&limit=' +
              this.row +
              `&${params.toString()}` +
              '&isPhoto=' +
              false
          )
          .subscribe({
            next: (event: any) => {
              console.log(event);

              if (event.type === HttpEventType.DownloadProgress) {
                this.handleDownloadProgress(event);
              } else if (event.type === HttpEventType.Response) {
                this.handleHttpResponse(event);
              }
            },
            error: (err: any) => {
              console.log(err);
              this.errMessage = 'Something went wrong, please try again later!';
              this.isDone = true;
              this.isExportLoading = false;
            },
            complete: () => {
              this.isDone = true;
              this.errMessage = '';
              this.isExportLoading = false;
            },
          });
      } else {
        this.isDone = true;
        this.isExportLoading = false;
        this.errMessage = 'Exports are limited to 7-day date ranges.';
      }
    } else {
      this.isDone = true;
      this.isExportLoading = false;
      this.errMessage =
        'Please select a date range for your data export (maximum 7 days).';
    }
  }

  async getFilter(formId: any) {
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/database/filter/' + formId
    );
    this.dataFilters = data;
    console.log(this.dataFilters);
  }
  async sortOrder() {
    (await this.sortOrderBy) === 'asc'
      ? (this.sortOrderBy = 'desc')
      : (this.sortOrderBy = 'asc');

    await this.getAllmerchant();
  }

  Clear(name: any) {
    if (name === 'dateFrom') {
      this.selectData.dateEnd = '';
      this.selectData[name] = '';
      this.getAllmerchant();
    } else if (name === 'province' || name === 'district') {
      if (name === 'province') {
        this.Formdata.get('province')?.setValue('');
      } else {
        this.Formdata.get('district')?.setValue('');
      }
      this.getAllmerchant();
    } else {
      this.selectData[name] = '';
      this.getAllmerchant();
    }
  }

  clearOnSearch() {
    this.selectData.merchantName = '';
    this.getAllmerchant();
  }

  async Clearall() {
    for (let i of Object.keys(this.selectData)) {
      this.selectData[i] = '';
    }
    this.Formdata.get('province')?.setValue('');
    this.Formdata.get('district')?.setValue('');
    this.sortOrderBy = 'desc';
    await this.getAllmerchant();
  }

  show() {
    this.showmore = !this.showmore;
    const data: any = localStorage.getItem('data');
    const parseData = JSON.parse(data);
    parseData[2] = this.showmore;

    localStorage.setItem('data', JSON.stringify(parseData));
  }
  addpage() {
    this.router.navigate(['add-merchant']);
  }
  viewmap() {
    this.router.navigate(['view-on-map']);
  }
  exportpage() {
    this.router.navigate(['database-exports']);
  }
  Detail(id: any) {
    this.router.navigate(['database-marchant-info', id]);
  }
  Getalllists(event: any) {
    if (event.checked) {
      for (const item of this.data.totalData) {
        item.isSelected = true;
      }
    } else if (!event.checked) {
      for (const item of this.data.totalData) {
        item.isSelected = false;
      }
    }

    this.Deletelist();
    this.getAllMer = this.deleteList.length === this.data.totalData.length;
  }
  Deletelist() {
    const deleteListTemp = [];
    for (let i = 0; i < this.data.totalData.length; i++) {
      const selectedDeleteMer = this.data.totalData[i].isSelected;
      if (selectedDeleteMer) {
        deleteListTemp.push(this.data.totalData[i]._id);
      }
    }
    this.deleteList = deleteListTemp;
  }
  isUserSelected(event: any, index: number) {
    if (event) {
      this.deleteList.push(this.data.totalData[index]._id);
    } else if (!event) {
      const deleteListTemp = [...this.deleteList];
      const id = this.data.totalData[index]._id;
      this.deleteList = deleteListTemp.filter((item) => item !== id);
    }
    this.getAllMer = this.deleteList.length === this.data.totalData.length;
  }

  async confirmDelete() {
    const body = {
      id: [...this.deleteList],
      password: this.password,
    };
    const data: any = await this.service.DeleteTokenPathBody(
      '/backoffice/merchant/delete-many',
      body
    );

    if (data?.msg === 'Success') {
      await this.getAllmerchant();
      this.password = '';

      this.btnclose.nativeElement.click();
      alert('Delete merchant success!');
    }
    if (data.error?.message === 'Password is invalid.') {
      this.response = data.error.message;
    }
  }

  async pageChanged(event: any) {
    this.p = event;
    await this.getAllmerchant(true);
  }

  async Getprovince() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.privince = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }
  district: any;
  async Getdistrict() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.district = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }

  get style() {
    return 'width:' + this.progress + '%';
  }

  private handleDownloadProgress(event: any): void {
    this.progress = Math.round((100 * event.loaded) / event.loaded);
  }

  private handleHttpResponse(event: any): void {
    const file: Blob = event.body as Blob;
    this.downloadFileFunc(file);
  }

  private downloadFileFunc(file: Blob): void {
    const downloadLink = this.renderer.createElement('a');
    this.renderer.setProperty(
      downloadLink,
      'href',
      window.URL.createObjectURL(file)
    );
    this.renderer.setProperty(downloadLink, 'download', 'POSM_Report_Database'); // Set your desired filename here
    downloadLink.click();
  }

  openSnackBar() {
    this._snackBar.open('Coming soon..', 'X', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

<div class="container-fluid">
  <div class="row align-items-center justify-content-between">
    <div class="col-md-6">
      <div class="text-title">
        {{ "Dashboard" | translate }}
      </div>
    </div>
    <div class="col-md-6">
      <select
        class="form-select"
        (change)="getDashboardbyId()"
        [(ngModel)]="country"
      >
        <option *ngFor="let i of countryList" [value]="i._id">
          {{ i.country }}
        </option>
      </select>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-md-auto mt-3">
      <button
        class="btncancel"
        data-bs-toggle="modal"
        data-bs-target="#ExportLoading"
        (click)="downloadFile()"
      >
        <span class="material-symbols-outlined me-3"> download </span>
        {{ "Export" | translate }}
      </button>
    </div>
    <div class="col-md-auto mt-3">
      <button class="btncancel" (click)="openSnackBar()">
        <span class="material-symbols-outlined me-3"> map </span>
        {{ "View on Map" | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <ng-container *ngFor="let i of data['dataMerchant']">
              <div class="row">
                <div class="col-md-12 mt-0 title">
                  {{ "Merchant in Database" | translate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-5">
                  {{ "New today" | translate }}
                </div>
                <div class="col-md-12 mt-3 text-title">
                  {{ i?.totalDay | number }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-lg-4 col-xxl-4 mt-4">
                  {{ "This week" | translate }}
                  <div class="text-title mt-3">
                    {{ i?.totalWeek | number }}
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xxl-4 mt-4">
                  {{ "This month" | translate }}
                  <div class="text-title mt-3">
                    {{ i?.totalMonth | number }}
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xxl-4 mt-4">
                  {{ "This year" | translate }}
                  <div class="text-title mt-3">
                    {{ i?.totalYear | number }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-5">
                  {{ "Total merchant" | translate }}
                  <div class="text-title mt-3">
                    {{ i?.totalAll | number }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-5">
                  <a routerLink="/database" class="more-link">
                    {{ "View database" | translate }}
                    <span class="material-symbols-outlined">
                      navigate_next
                    </span>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="row">
              <div class="col-md-12 mt-0 title">
                {{ "Location" | translate }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="chartLocations?.series.length != 0">
                  <apx-chart
                    [series]="chartLocations?.series"
                    [chart]="chartLocations?.chart"
                    [xaxis]="chartLocations?.xaxis"
                    [labels]="chartLocations?.labels"
                  ></apx-chart>
                </div>
                <div class="text-center" *ngIf="chartLocations?.series == 0">
                  <h1>No Data</h1>
                </div>
              </div>
              <div class="col-md-12">
                <table class="w-100">
                  <tbody>
                    <ng-container
                      *ngFor="let i of data.dataGraph?.totalLoc | chunk : 0 : 5"
                    >
                      <tr>
                        <td class="text-center">
                          <div class="marking"></div>
                        </td>
                        <td>{{ i?.name }}</td>
                        <td>{{ i?.percent | number : "1.0-2" }}%</td>
                        <td>{{ i?.count }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
                <p
                  class="d-flex align-items-center more-link mb-0 mt-3"
                  *ngIf="
                    chartLocations?.series !== 0 &&
                    data.dataGraph?.totalLoc?.length > 5
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropLocation"
                >
                  {{ "More Location" | translate }}
                  <span class="material-symbols-outlined"> chevron_right </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="card">
            <div class="row">
              <div class="col-md-12 mt-0 title">
                {{ "EDC Bank" | translate }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="chartAcq?.series[0].data.length != 0">
                  <apx-chart
                    [series]="chartAcq?.series"
                    [chart]="chartAcq?.chart"
                    [xaxis]="chartAcq?.xaxis"
                    [dataLabels]="chartAcq?.dataLabels"
                    [plotOptions]="chartAcq?.plotOptions"
                    [colors]="chartAcq?.colors"
                  ></apx-chart>
                </div>
                <div
                  class="text-center"
                  *ngIf="chartAcq?.series[0].data.length == 0"
                >
                  <h1>No Data</h1>
                </div>
              </div>
              <div class="col-md-12">
                <table class="w-100">
                  <tbody>
                    <ng-container
                      *ngFor="let i of data.dataGraph?.totalAcq | chunk : 0 : 5"
                    >
                      <tr>
                        <td class="text-center">
                          <div class="marking"></div>
                        </td>
                        <td>{{ i?.name }}</td>
                        <td>{{ i?.percent | number : "1.0-2" }}%</td>
                        <td>{{ i?.count }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
                <p
                  class="d-flex align-items-center more-link mb-0 mt-3"
                  *ngIf="
                    chartAcq?.series[0].data.length !== 0 &&
                    data.dataGraph?.totalAcq?.length > 5
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdropBank"
                >
                  {{ "More Bank" | translate }}
                  <span class="material-symbols-outlined"> chevron_right </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="col-md-12">
        <div class="card">
          <div class="row">
            <div class="col-md-12 mt-0 title">
              {{ "Category" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="chartCategory?.series.length != 0">
                <apx-chart
                  [series]="chartCategory?.series"
                  [chart]="chartCategory?.chart"
                  [xaxis]="chartCategory?.xaxis"
                  [labels]="chartCategory?.labels"
                ></apx-chart>
              </div>
              <div
                class="text-center"
                *ngIf="chartCategory?.series.length == 0"
              >
                <h1>No Data</h1>
              </div>
            </div>
            <div class="col-md-12">
              <table class="w-100">
                <tbody>
                  <ng-container
                    *ngFor="let i of data.dataGraph?.totalCate | chunk : 0 : 5"
                  >
                    <tr>
                      <td class="text-center">
                        <div class="marking"></div>
                      </td>
                      <td>{{ i?.name }}</td>
                      <td>{{ i?.percent | number : "1.0-2" }}%</td>
                      <td>{{ i?.count }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p
                class="d-flex align-items-center more-link mb-0 mt-3"
                *ngIf="
                  chartCategory?.series.length !== 0 &&
                  data.dataGraph?.totalCate.length > 5
                "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropCategory"
              >
                {{ "More Category" | translate }}
                <span class="material-symbols-outlined"> chevron_right </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="row">
            <div class="col-md-12 mt-0 title">
              {{ "Card Acceptance" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="chartAcceptance?.series[0].data.length != 0">
                <apx-chart
                  [series]="chartAcceptance?.series"
                  [chart]="chartAcceptance?.chart"
                  [xaxis]="chartAcceptance?.xaxis"
                  [dataLabels]="chartAcceptance?.dataLabels"
                  [plotOptions]="chartAcceptance?.plotOptions"
                  [colors]="chartAcceptance?.colors"
                ></apx-chart>
              </div>
              <div
                class="text-center"
                *ngIf="chartAcceptance?.series[0].data.length == 0"
              >
                <h1>No Data</h1>
              </div>
            </div>
            <div class="col-md-12">
              <table class="w-100">
                <tbody>
                  <ng-container
                    *ngFor="let i of data.dataGraph?.totalCard | chunk : 0 : 5"
                  >
                    <tr>
                      <td class="text-center">
                        <div class="marking"></div>
                      </td>
                      <td>{{ i?.name }}</td>
                      <td>{{ i?.percent | number : "1.0-2" }}%</td>
                      <td>{{ i?.count }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p
                class="d-flex align-items-center more-link mb-0 mt-3"
                *ngIf="
                  chartAcceptance?.series[0].data.length !== 0 &&
                  data.dataGraph?.totalCard.length > 5
                "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropCard"
              >
                {{ "More Card" | translate }}
                <span class="material-symbols-outlined"> chevron_right </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="row">
            <div class="col-md-12 mt-0 title">
              {{ "Competitor info" | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div *ngIf="chartMaterialDeploy?.series[0].data.length != 0">
                <apx-chart
                  [series]="chartMaterialDeploy?.series"
                  [chart]="chartMaterialDeploy?.chart"
                  [xaxis]="chartMaterialDeploy?.xaxis"
                  [dataLabels]="chartMaterialDeploy?.dataLabels"
                  [plotOptions]="chartMaterialDeploy?.plotOptions"
                  [colors]="chartMaterialDeploy?.colors"
                ></apx-chart>
              </div>
              <div
                class="text-center"
                *ngIf="chartMaterialDeploy?.series[0].data.length == 0"
              >
                <h1>No Data</h1>
              </div>
            </div>
            <div class="col-md-12">
              <table class="w-100">
                <tbody>
                  <ng-container
                    *ngFor="let i of data.dataGraph?.totalComp | chunk : 0 : 5"
                  >
                    <tr>
                      <td class="text-center">
                        <div class="marking"></div>
                      </td>
                      <td>{{ i?.name }}</td>
                      <td>{{ i?.percent | number : "1.0-2" }}%</td>
                      <td>{{ i?.count }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p
                class="d-flex align-items-center more-link mb-0 mt-3"
                *ngIf="
                  chartMaterialDeploy?.series[0].data.length !== 0 &&
                  data.dataGraph?.totalComp.length > 5
                "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropCompetitor"
              >
                {{ "More Competitor" | translate }}
                <span class="material-symbols-outlined"> chevron_right </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<!-- Modal Location -->
<div
  class="modal fade"
  id="staticBackdropLocation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "Location" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="mt-2 col-md-12">
        <table class="w-100">
          <tbody>
            <ng-container
              *ngFor="
                let i of data.dataGraph?.totalLoc
                  | chunk : 5 : data.dataGraph?.totalLoc?.length
              "
            >
              <tr>
                <td class="text-center">
                  <div class="marking"></div>
                </td>
                <td>{{ i?.name }}</td>
                <td>{{ i?.percent | number : "1.0-2" }}%</td>
                <td>{{ i?.count }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal Category -->
<div
  class="modal fade"
  id="staticBackdropCategory"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "Category" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="mt-2 col-md-12">
        <table class="w-100">
          <tbody>
            <ng-container
              *ngFor="
                let i of data.dataGraph?.totalCate
                  | chunk : 5 : data.dataGraph?.totalCate?.length
              "
            >
              <tr>
                <td class="text-center">
                  <div class="marking"></div>
                </td>
                <td>{{ i?.name }}</td>
                <td>{{ i?.percent | number : "1.0-2" }}%</td>
                <td>{{ i?.count }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal Bank -->
<div
  class="modal fade"
  id="staticBackdropBank"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "EDC Bank" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="mt-2 col-md-12">
        <table class="w-100">
          <tbody>
            <ng-container
              *ngFor="
                let i of data.dataGraph?.totalAcq
                  | chunk : 5 : data.dataGraph?.totalAcq?.length
              "
            >
              <tr>
                <td class="text-center">
                  <div class="marking"></div>
                </td>
                <td>{{ i?.name }}</td>
                <td>{{ i?.percent | number : "1.0-2" }}%</td>
                <td>{{ i?.count }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal Card Acceptance -->
<div
  class="modal fade"
  id="staticBackdropCard"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "Card Acceptance" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="mt-2 col-md-12">
        <table class="w-100">
          <tbody>
            <ng-container
              *ngFor="
                let i of data.dataGraph?.totalCard
                  | chunk : 5 : data.dataGraph?.totalCard?.length
              "
            >
              <tr>
                <td class="text-center">
                  <div class="marking"></div>
                </td>
                <td>{{ i?.name }}</td>
                <td>{{ i?.percent | number : "1.0-2" }}%</td>
                <td>{{ i?.count }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal Competitor -->
<div
  class="modal fade"
  id="staticBackdropCompetitor"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "Competitor" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div class="mt-2 col-md-12">
        <table class="w-100">
          <tbody>
            <ng-container
              *ngFor="
                let i of data.dataGraph?.totalComp
                  | chunk : 5 : data.dataGraph?.totalComp?.length
              "
            >
              <tr>
                <td class="text-center">
                  <div class="marking"></div>
                </td>
                <td>{{ i?.name }}</td>
                <td>{{ i?.percent | number : "1.0-2" }}%</td>
                <td>{{ i?.count }}</td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- Modal export-->
<!-- <div
  class="modal fade"
  id="Export"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <ng-container *ngIf="!isLoading">
          <div class="row">
            <div class="col-md-12 text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <span
                class="material-symbols-outlined"
                style="font-size: 100px; color: #388087"
              >
                error
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <span>
                {{ "Do you want to export data with photo?" | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-5 d-flex">
              <button
                class="btnsave w-100"
                data-bs-toggle="modal"
                data-bs-target="#ExportLoading"
                (click)="downloadFile(true)"
              >
                {{ "Yes" | translate }}
              </button>
              <button
                class="btncancel w-100 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#ExportLoading"
                (click)="downloadFile(false)"
              >
                {{ "No" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->
<!-- Modal export loading-->
<div
  class="modal"
  id="ExportLoading"
  data-bs-keyboard="false"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div
          class="d-flex justify-content-center"
          *ngIf="!isDone && errMessage.length === 0"
        >
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <p class="text-center my-2" *ngIf="!isDone && errMessage.length === 0">
          {{ "Exporting the data, please wait" | translate }}...
        </p>
        <div class="col-md-12 text-center" *ngIf="isDone">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>

        <p
          class="text-center my-2 text-danger"
          *ngIf="isDone && errMessage.length !== 0"
        >
          {{ errMessage | translate }}
        </p>
        <div
          class="progress"
          role="progressbar"
          aria-label="Success example"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          *ngIf="errMessage.length === 0"
        >
          <div class="progress-bar bg-success" [style]="style"></div>
        </div>

        <div class="col-md-12 mt-2 d-flex" *ngIf="isDone">
          <button
            class="btncancel w-100 ms-1"
            #btnclose
            data-bs-dismiss="modal"
            (click)="progress = 0"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading -->
<div class="overlay" *ngIf="isPageLoading">
  <div class="center">
    <div
      class="spinner-border text-success"
      style="width: 10em; height: 10em"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

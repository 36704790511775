import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.css'],
})
export class AdminManagementComponent implements OnInit {
  data: any = [];
  p: any = 1;
  row: any = 10;
  language: any;
  email: any = '';
  sortOrderBy: any = 'asc';
  keyword: any = '';
  constructor(private service: ServiceService, private router: Router) {}

  ngOnInit(): void {
    this.getAlllistadmin();
  }
  totalCount: any;
  async getAlllistadmin(isPagination?: any) {
    if (!isPagination) {
      this.p = 1;
    }
    let list: any = await this.service.GetTokenPathParams(
      '/user/admin?skip=' +
        this.p +
        '&limit=' +
        this.row +
        '&sortBy=email' +
        '&sortOrderBy=' +
        this.sortOrderBy +
        '&search=' +
        this.keyword
    );

    this.data = list.totalData;
    this.totalCount = list.totalCount;
  }

  async pageChanged(event: any) {
    this.p = event;

    await this.getAlllistadmin(true);
  }

  Detail(id: any) {
    this.router.navigate(['/admin-info/', id]);
  }

  Inviteadmin() {
    this.router.navigate(['/invite-admin']);
  }
  async sortOrder() {
    (await this.sortOrderBy) === 'asc'
      ? (this.sortOrderBy = 'desc')
      : (this.sortOrderBy = 'asc');

    await this.getAlllistadmin();
  }

  async searchByKeyword() {
    await this.getAlllistadmin();
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private languageChangedSubject = new Subject<string>();

  constructor() {}

  setLanguage(lang: string) {
    // Set the language here using TranslateService or any other method
    // ...

    // Emit the new language to all subscribers
    this.languageChangedSubject.next(lang);
  }

  languageChanged$() {
    return this.languageChangedSubject;
  }
}

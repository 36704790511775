<div class="container">
  <div>
    <a class="back" routerLink="/deployment/database">
      <span
        class="material-symbols-outlined"
        style="font-size: 24px; color: #388087"
      >
        keyboard_backspace
      </span>
      <span style="color: #388087">{{ "Back" | translate }}</span></a
    >
  </div>
  <div class="background row">
    <div class="col-md-12 d-flex align-items-center mb-1">
      <span class="material-symbols-outlined"> badge </span>
      <span class="ps-2 small-content"> {{ user?.name }}</span>
    </div>
    <div class="col-md-12 mb-0 small-content">
      <span class="small-content"> {{ user?.roles }}</span>
    </div>
  </div>
  <form [formGroup]="myForm">
    <!-- merchant detail -->
    <div class="background row mt-4">
      <div class="col-md-12 d-flex align-items-center">
        <p>
          {{ "Merchant Detail" | translate }}
        </p>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <div class="col-md-12 mt-2" *ngIf="i?.fieldName === 'Merchant name'">
          <span class="small-title">{{ "Merchant name" | translate }}*</span>
          <input
            type="text"
            class="form-control mt-2 small-title"
            formControlName="merchantName"
            [required]="i?.isRequired"
            placeholder="{{ 'Fill merchant name' | translate }}"
          />
        </div>
        <div class="col-md-6 mt-3" *ngIf="i?.fieldName === 'Category'">
          <span class="small-title">{{ "Category" | translate }}*</span>

          <mat-select
            formControlName="category"
            class="form-control mt-2 small-title"
            [required]="i?.isRequired"
            (ngModelChange)="toggleCategoryInputRequired($event)"
            placeholder="{{ 'Select Category' | translate }}"
          >
            <mat-option *ngFor="let j of i.choices" [value]="j">
              {{ j }}
            </mat-option>
            <mat-option value="Others">Others</mat-option>
          </mat-select>
        </div>
        <div
          class="col-md-6 d-flex align-items-end"
          *ngIf="i?.fieldName === 'Category'"
        >
          <input
            type="text"
            class="form-control small-title mt-2"
            formControlName="newCategory"
            [required]="categoryInputRequired"
            placeholder="{{ 'Fill new category name' | translate }}"
          />
        </div>
      </ng-container>
    </div>

    <!-- Location -->
    <div class="background row mt-4">
      <div class="col-md-12 mt-2">
        <p>
          {{ "Location" | translate }}
        </p>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Location'">
          <div class="col-md-6 mt-2">
            <span class="small-title">{{ "Location" | translate }}*</span>
            <mat-select
              formControlName="locationName"
              class="form-control mt-2 small-title"
              [required]="i?.isRequired"
              (ngModelChange)="toggleLocationNameInputRequired($event)"
              placeholder="{{ 'Select Location' | translate }}"
            >
              <mat-option *ngFor="let j of i.choices" [value]="j?.name">
                {{ j?.name }}
              </mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </div>
          <div class="col-md-6">
            <span class="small-content my-1">
              {{ "(Shopping mall, Building, Market, etc.)" | translate }}
            </span>
            <input
              type="text"
              formControlName="newLocationName"
              [required]="locationNameInputRequired"
              class="form-control mt-2"
              placeholder="{{ 'Fill new location name' | translate }}"
            />
          </div>
        </ng-container>
      </ng-container>

      <span class="small-title mt-3">{{ "Location type" | translate }}</span>

      <div class="row mt-2 p-1 ps-4">
        <!-- <input type="checkbox" formControlName="isOnStreet" /><span
          class="small-content"
        >
          {{
            "This Merchant are on street (Not in any Shopping mall, Building, Market, etc.)"
              | translate
          }}</span
        > -->

        <div class="form-check form-check-inline col-5">
          <input
            formControlName="isOnStreet"
            class="form-check-input"
            type="radio"
            name="isOnStreet"
            id="inlineRadio2"
            [value]="false"
          />
          <label class="form-check-label small-title" for="inlineRadio2">{{
            "In Building" | translate
          }}</label>
        </div>
        <div class="form-check form-check-inline col-5">
          <input
            formControlName="isOnStreet"
            class="form-check-input"
            type="radio"
            name="isOnStreet"
            id="inlineRadio1"
            [value]="true"
          />
          <label class="form-check-label small-title" for="inlineRadio1">{{
            "On Street" | translate
          }}</label>
        </div>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Shop phone number' && i?.isShow">
          <div class="col-md-6 mt-2">
            <span *ngIf="i?.isRequired" class="small-title"
              >{{ "Shop phone number" | translate }}*</span
            >
            <span *ngIf="!i?.isRequired" class="small-title">{{
              "Shop phone number" | translate
            }}</span>
            <ng-container
              *ngIf="country === 'Thailand' || country === 'Cambodia'"
            >
              <input
                maxlength="10"
                type="text"
                class="form-control mt-2 small-title"
                formControlName="shopPhoneNumber"
                [required]="i?.isRequired"
                placeholder="{{ 'Shop phone number' | translate }}"
              />
            </ng-container>
            <ng-container *ngIf="country === 'Myanmar'">
              <input
                maxlength="11"
                type="text"
                class="form-control mt-2 small-title"
                formControlName="shopPhoneNumber"
                [required]="i?.isRequired"
                placeholder="{{ 'Shop phone number' | translate }}"
              />
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-md-6 mt-2">
        <span class="small-title">{{ "Floor" | translate }}</span>
        <input
          type="text"
          formControlName="floor"
          class="form-control mt-2 small-title"
          placeholder="{{ 'Floor' | translate }}"
        />
      </div>

      <div class="col-md-6 mt-2">
        <span class="small-title">{{ "Street" | translate }}*</span>
        <input
          type="text"
          formControlName="street"
          class="form-control mt-2 small-title"
          [required]="true"
          placeholder="{{ 'Street' | translate }}"
        />
      </div>
      <div class="col-md-6 mt-2" *ngIf="country === 'Thailand'">
        <span class="small-title">{{ "District" | translate }}*</span>
        <input
          type="text"
          formControlName="district"
          class="form-control mt-2"
          [required]="true"
          placeholder="{{ 'District' | translate }}"
          [matAutocomplete]="autoDistrict"
          matInput
        />
        <mat-autocomplete #autoDistrict="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptionDistrict | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-6 mt-2" *ngIf="country !== 'Thailand'">
        <span class="small-title">{{ "District" | translate }}*</span>
        <input
          type="text"
          formControlName="district"
          class="form-control mt-2"
          [required]="true"
          placeholder="{{ 'District' | translate }}"
        />
      </div>

      <div class="col-md-6 mt-2" *ngIf="country === 'Thailand'">
        <span class="small-title">{{ "Province" | translate }}*</span>
        <input
          type="text"
          formControlName="province"
          class="form-control mt-2"
          [required]="true"
          placeholder="{{ 'Province' | translate }}"
          [matAutocomplete]="auto"
          matInput
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredOptionProvince | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-6 mt-2" *ngIf="country !== 'Thailand'">
        <span class="small-title">{{ "Province" | translate }}*</span>
        <input
          type="text"
          formControlName="province"
          class="form-control mt-2"
          [required]="true"
          placeholder="{{ 'Province' | translate }}"
        />
      </div>

      <div class="col-md-6 mt-2">
        <span class="small-title">{{ "Zipcode" | translate }}*</span>
        <input
          type="text"
          formControlName="zipcode"
          class="form-control mt-2 small-title"
          [required]="true"
          placeholder="{{ 'Zipcode' | translate }}"
        />
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Latitude' && i?.isShow">
          <div class="col-md-6 mt-2">
            <span class="small-title" *ngIf="i?.required"
              >{{ "Location on map" | translate }}*</span
            >
            <span class="small-title" *ngIf="!i?.required">{{
              "Location on map" | translate
            }}</span>
            <div class="input-group mt-2">
              <button
                [ngClass]="
                  myForm.get('latitude')?.invalid &&
                  myForm.get('latitude')?.touched
                    ? 'btn-invalid'
                    : ''
                "
                class="btncancel w-100 small-title"
                (click)="Getlocation()"
                [disabled]="locationLoading"
              >
                <span class="material-symbols-outlined me-2">
                  location_on
                </span>
                Location on map
              </button>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-end mt-2">
            <span
              *ngIf="!data.lat && !data.long && !locationLoading"
              class="small-title"
            >
              {{ "Location didn't mark yet" | translate }}
            </span>
            <span *ngIf="locationLoading" class="small-title">
              {{ "Marking your location... please wait" | translate }}
              <div
                class="spinner-border spinner-border-sm text-success"
                text-success
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <div
              class="w-100 d-flex justify-content-between"
              *ngIf="data.lat && data.long && !locationLoading"
            >
              <span class="small-title">
                Latitude : {{ data.lat.toFixed(2) }}N
              </span>
              <span class="small-title">
                Longitude : {{ data.long.toFixed(2) }}E
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <!-- Contact -->
    <div class="background row mt-4">
      <div class="col-md-12">
        <p>
          {{ "Contact" | translate }}
        </p>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container
          *ngIf="i?.fieldName === 'Contact person name' && i?.isShow"
        >
          <div class="col-md-12 mt-2">
            <span *ngIf="i?.isRequired" class="small-title">
              {{ "Contact person" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired" class="small-title">
              {{ "Contact person" | translate }}
            </span>
            <input
              type="text"
              formControlName="contactName"
              class="form-control mt-2 small-title"
              [required]="i?.isRequired"
              placeholder="{{ 'Contact person name' | translate }}"
            />
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <div
          class="col-md-6 mt-2"
          *ngIf="i?.fieldName === 'Phone number' && i?.isShow"
        >
          <span *ngIf="i?.isRequired" class="small-title">
            {{ "Phone number" | translate }}*
          </span>
          <span *ngIf="!i?.isRequired" class="small-title">
            {{ "Phone number" | translate }}
          </span>
          <ng-container
            *ngIf="country === 'Thailand' || country === 'Cambodia'"
          >
            <input
              maxlength="10"
              type="text"
              class="form-control mt-2 small-title"
              formControlName="phoneNumber"
              [required]="i?.isRequired"
              placeholder="{{ 'Phone number' | translate }}"
            />
          </ng-container>
          <ng-container *ngIf="country === 'Myanmar'">
            <input
              maxlength="11"
              type="text"
              class="form-control mt-2 small-title"
              formControlName="phoneNumber"
              [required]="i?.isRequired"
              placeholder="{{ 'Phone number' | translate }}"
            />
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <div
          class="col-md-6 mt-2"
          *ngIf="i?.fieldName === 'Email' && i?.isShow"
        >
          <span *ngIf="i?.isRequired" class="small-title">
            {{ "Email" | translate }}*
          </span>
          <span *ngIf="!i?.isRequired" class="small-title">
            {{ "Email" | translate }}
          </span>
          <input
            type="text"
            formControlName="email"
            class="form-control mt-2 small-title"
            [required]="i?.isRequired"
            placeholder="{{ 'Email' | translate }}"
          />
        </div>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <div class="col-md-6 mt-2" *ngIf="i?.fieldName === 'FAX' && i?.isShow">
          <span *ngIf="i?.isRequired" class="small-title">
            {{ "FAX" | translate }}*
          </span>
          <span *ngIf="!i?.isRequired" class="small-title">
            {{ "FAX" | translate }}
          </span>
          <input
            type="text"
            formControlName="fax"
            class="form-control mt-2 small-title"
            [required]="i?.isRequired"
            placeholder="{{ 'FAX' | translate }}"
          />
        </div>
      </ng-container>
    </div>

    <!-- Type of Payment -->
    <div class="background row mt-4">
      <div class="col-md-12">
        <p>
          {{ "Type of Payment" | translate }}
        </p>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Card Acceptance' && i?.isShow">
          <app-custom-input
            [data]="i"
            [isValue]="isCardAcceptanceControlValid('cardAcceptance')"
          ></app-custom-input>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <div
          class="col-md-6 mt-2"
          *ngIf="i?.fieldName === 'Minimum payment' && i?.isShow"
        >
          <span *ngIf="i?.isRequired" class="small-title">
            {{ "Minimum spending" | translate }}*
          </span>
          <span *ngIf="!i?.isRequired" class="small-title">
            {{ "Minimum spending" | translate }}
          </span>
          <input
            type="text"
            formControlName="minimumPayment"
            [required]="i?.isRequired"
            class="form-control mt-2 small-title"
            (input)="onlyNumber($event, 'minimumPayment')"
            placeholder="{{ 'Minimum spending' | translate }}"
          />
          <div class="mt-2">
            <input
              type="checkbox"
              formControlName="no_minimumPayment"
              (change)="toggleDisable('no_minimumPayment')"
            />
            <span class="small-title ms-1">
              {{ "No Minimum spending" | translate }}
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <div class="col-md-6" *ngIf="i?.fieldName === 'Surcharge' && i?.isShow">
          <span *ngIf="i?.isRequired" class="small-title">
            {{ "Surcharge" | translate }} (%)*
          </span>
          <span *ngIf="!i?.isRequired" class="small-title">
            {{ "Surcharge" | translate }} (%)
          </span>
          <input
            type="text"
            formControlName="surcharge"
            class="form-control mt-2 small-title"
            [required]="i?.isRequired"
            (input)="onlyNumber($event, 'surcharge')"
            placeholder="{{ 'Surcharge' | translate }}"
          />
          <div class="mt-2">
            <input
              type="checkbox"
              formControlName="no_surcharge"
              (change)="toggleDisable('no_surcharge')"
            />
            <span class="small-title ms-1">
              {{ "No Surcharge" | translate }}
            </span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Other Payment' && i?.isShow">
          <app-custom-input
            [data]="i"
            [isValue]="isCardAcceptanceControlValid('otherPayment')"
          ></app-custom-input>
        </ng-container>
      </ng-container>
    </div>

    <!-- EDC terminal -->
    <div class="background row mt-4">
      <div class="col-md-12">
        <p>
          {{ "EDC Terminal" | translate }}
        </p>
      </div>

      <ng-container formArrayName="edcData">
        <ng-container
          *ngFor="let edcForm of edcData.controls; let index = index"
        >
          <div class="edcTerminal" [formGroupName]="index">
            <!-- <button
                type="button"
                *ngIf="index !== 0"
                (click)="deleteEdcData(index)"
                class="btn-close close-btn"
                aria-label="Close"
                style="width: 5px; height: 5px"
              ></button> -->
            <hr *ngIf="index !== 0" />
            <ng-container *ngFor="let i of formManagement">
              <div
                class="col-md-6 mt-2"
                *ngIf="i?.fieldName === 'EDC Bank' && i?.isShow"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span *ngIf="i?.isRequired" class="small-title">
                    {{ "Bank name" | translate }}*
                  </span>
                  <span *ngIf="!i?.isRequired" class="small-title">
                    {{ "Bank name" | translate }}
                  </span>
                  <span
                    (click)="deleteEdcData(index)"
                    class="small-title text-danger"
                    style="text-decoration: underline"
                    *ngIf="index !== 0"
                    >Remove EDC</span
                  >
                </div>

                <mat-select
                  class="form-control mt-2 small-title"
                  [required]="i?.isRequired"
                  formControlName="edcBank"
                  placeholder="{{ 'Select Bank' | translate }}"
                >
                  <mat-option *ngFor="let bank of i.choices" [value]="bank">
                    {{ bank }}
                  </mat-option>
                </mat-select>
              </div>
            </ng-container>
            <ng-container *ngFor="let i of formManagement">
              <div
                class="col-md-6 mt-2"
                *ngIf="i?.fieldName === 'Terminal ID' && i?.isShow"
              >
                <span *ngIf="i?.isRequired" class="small-title">
                  {{ "Terminal ID" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired" class="small-title">
                  {{ "Terminal ID" | translate }}
                </span>
                <input
                  type="text"
                  class="form-control mt-2 small-title"
                  placeholder="{{ 'Terminal ID' | translate }}"
                  formControlName="edcTerminalId"
                  [required]="i?.isRequired"
                />
                <div class="col-md-6">
                  <div class="my-2">
                    <input
                      formControlName="no_TerminalId"
                      type="checkbox"
                      (change)="toggleDisable('no_TerminalId', index)"
                    />

                    <span class="small-content">
                      {{ "No Terminal ID" | translate }}
                    </span>
                  </div>
                </div>
                <div
                  class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  [attr.data-bs-target]="'#edc' + index"
                >
                  <div>
                    <p class="small-title m-0">
                      {{ "EDC Status" | translate }} /
                    </p>
                    <p class="small-title m-0">
                      {{ "Contactless Acceptance" | translate }}
                    </p>
                  </div>

                  <span
                    class="btn btn-primary small-title"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    [attr.data-bs-target]="'#edc' + index"
                    class="material-symbols-outlined ms-4 curser"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </ng-container>
            <!-- <div
              class="col-md-1 d-flex justify-content-center align-items-center"
            >
              <button
                type="button"
                *ngIf="index !== 0"
                (click)="deleteEdcData(index)"
                class="btn-close"
                aria-label="Close"
              ></button>
            </div> -->
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let item of formManagement">
        <ng-container *ngIf="item.fieldName === 'EDC Bank' && item?.isShow">
          <div class="col-md-12 mt-4">
            <button class="btncancel w-100" (click)="addEdcData()">
              <span class="material-symbols-outlined small-title"> add </span>
              <span class="small-title">{{ "Add EDC" | translate }}</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow">
          <ng-container *ngFor="let j of formManagement">
            <ng-container *ngIf="j?.fieldName === 'Issue Photo' && j?.isShow">
              <div class="col-md-12 mt-2">
                <input
                  style="display: none"
                  formControlName="edcNormalPhoto"
                  [required]="i?.isRequired"
                />
                <div
                  class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightNormal"
                  aria-controls="offcanvasRight"
                >
                  <p class="small-title">
                    {{ "Normal EDC Issue Photo" | translate }} ({{
                      myForm.get("edcNormalPhoto")?.value
                        ? myForm.get("edcNormalPhoto")?.value?.length
                        : "0"
                    }})
                  </p>
                  <span
                    class="btn btn-primary small-title"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightNormal"
                    aria-controls="offcanvasRight"
                    class="material-symbols-outlined ms-4 curser"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <ng-container
          *ngIf="i?.fieldName === 'Contactless Acceptance' && i?.isShow"
        >
          <ng-container *ngFor="let j of formManagement">
            <ng-container
              *ngIf="
                j?.fieldName === 'Issue Photo' &&
                j?.isShow &&
                isContactlessInput
              "
            >
              <div class="col-md-12 mt-2">
                <input
                  style="display: none"
                  formControlName="contactPhoto"
                  [required]="i?.isRequired && isContactlessInput"
                />
                <div
                  class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightContact"
                  aria-controls="offcanvasRight"
                >
                  <p class="small-title">
                    {{ "Contactless Issue Photo" | translate }} ({{
                      myForm.get("contactPhoto")?.value
                        ? myForm.get("contactPhoto")?.value?.length
                        : "0"
                    }})
                  </p>
                  <span
                    class="btn btn-primary small-title"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRightContact"
                    aria-controls="offcanvasRight"
                    class="material-symbols-outlined ms-4 curser"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <!-- Visa Material -->
    <div class="background row mt-4">
      <div class="col-md-12">
        <p>
          {{ "Visa Material" | translate }}
        </p>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'General Material' && i?.isShow">
          <div
            class="col-md-12 mt-2 d-flex justify-content-between align-items-center"
          >
            <span *ngIf="i?.isRequired" class="small-title">
              {{ "General Material" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired" class="small-title">
              {{ "General Material" | translate }}
            </span>
          </div>
          <div class="col-md-12 mt-1">
            <div
              class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRightGeneralQty"
              aria-controls="offcanvasRight"
            >
              <p class="small-title">
                {{ "Material Quantity" | translate }}
              </p>
              <span
                class="btn btn-primary small-title"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightGeneralQty"
                aria-controls="offcanvasRight"
                class="material-symbols-outlined ms-4 curser"
              >
                arrow_forward_ios
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <input
              style="display: none"
              formControlName="materialPhoto"
              [required]="i?.isRequired"
            />
            <div
              class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRightGeneral"
              aria-controls="offcanvasRight"
            >
              <p class="small-title">
                {{ "Manage Photo" | translate }} ({{
                  myForm.get("materialPhoto")?.value
                    ? myForm.get("materialPhoto")?.value?.length
                    : "0"
                }})
              </p>
              <span
                class="btn btn-primary small-title"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightGeneral"
                aria-controls="offcanvasRight"
                class="material-symbols-outlined ms-4 curser"
              >
                arrow_forward_ios
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <ng-container
          *ngIf="i?.fieldName === 'Customize Material' && i?.isShow"
        >
          <div
            class="col-md-12 mt-4 d-flex justify-content-between align-items-center"
          >
            <span *ngIf="i?.isRequired" class="small-title">
              {{ "Customized Material" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired" class="small-title">
              {{ "Customized Material" | translate }}
            </span>
          </div>
          <div class="col-md-12 mt-1">
            <div
              class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRightCustomizedQty"
              aria-controls="offcanvasRight"
            >
              <p class="small-title">
                {{ "Material Quantity" | translate }}
              </p>
              <span
                class="btn btn-primary small-title"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightCustomizedQty"
                aria-controls="offcanvasRight"
                class="material-symbols-outlined ms-4 curser"
              >
                arrow_forward_ios
              </span>
            </div>
          </div>
          <div class="col-md-12">
            <input
              style="display: none"
              formControlName="customizeMaterialPhoto"
              [required]="i?.isRequired"
            />
            <div
              class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRightCustomized"
              aria-controls="offcanvasRight"
            >
              <p class="small-title">
                {{ "Manage Photo" | translate }} ({{
                  myForm.get("customizeMaterialPhoto")?.value
                    ? myForm.get("customizeMaterialPhoto")?.value?.length
                    : "0"
                }})
              </p>
              <span
                class="btn btn-primary small-title"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightCustomized"
                aria-controls="offcanvasRight"
                class="material-symbols-outlined ms-4 curser"
              >
                arrow_forward_ios
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <!-- Competitor Info -->
    <div class="background row mt-4">
      <div class="col-md-12 d-flex justify-content-between">
        <p>
          {{ "Competitor Info" | translate }}
        </p>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            formControlName="isCompetitionInfo"
            (ngModelChange)="toggleIsCompetitionInfo($event)"
          />
        </div>
      </div>
      <ng-container *ngIf="isCompetitionInfo">
        <ng-container *ngFor="let i of formManagement">
          <ng-container
            *ngIf="
              i?.fieldName === 'Competitor Bank' &&
              i?.isShow &&
              isCompetitionInfo
            "
          >
            <input
              style="display: none"
              formControlName="competitorBank"
              [required]="i?.isRequired && isCompetitionInfo"
            />

            <app-custom-input
              [data]="i"
              [isValue]="isCardAcceptanceControlValid('competitorBank')"
              [isTouched]="myForm.get('competitorBank')?.touched"
            ></app-custom-input>
          </ng-container>
        </ng-container>

        <ng-container *ngFor="let i of formManagement">
          <ng-container
            *ngIf="i?.fieldName === 'Competitor Photo' && i?.isShow"
          >
            <div class="col-md-12">
              <input
                style="display: none"
                formControlName="competitorPhoto"
                [required]="i?.isRequired && isCompetitionInfo"
              />
              <div
                [ngClass]="
                  myForm.get('competitorPhoto')?.touched &&
                  myForm.get('competitorPhoto')?.invalid
                    ? 'background-pic-invalid'
                    : ''
                "
                class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRightCompetitor"
                aria-controls="offcanvasRight"
              >
                <p class="small-title">
                  {{ "Manage Photo" | translate }} ({{
                    myForm.get("competitorPhoto")?.value
                      ? myForm.get("competitorPhoto")?.value?.length
                      : "0"
                  }})
                </p>
                <span
                  class="btn btn-primary small-title"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRightCompetitor"
                  aria-controls="offcanvasRight"
                  class="material-symbols-outlined ms-4 curser"
                >
                  arrow_forward_ios
                </span>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
  <div class="row end-add">
    <div class="col-md-12">
      <p class="text-danger small-title text-center" *ngIf="errorMessage">
        {{ errorMessage | translate }}
      </p>
      <button
        class="btnsave small-title w-100"
        *ngIf="!isLoading"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#confirmAdd"
      >
        {{ "Add Merchant" | translate }}
      </button>
      <button
        class="btnsave small-title w-100"
        type="button"
        disabled
        *ngIf="isLoading"
      >
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ "Adding Merchant Please Wait" | translate }}...
      </button>
    </div>
  </div>
</div>

<!-- modal picture -->
<!-- edcnormal photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightNormal"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Normal EDC Issue Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngFor="let i of formManagement">
      <ng-container *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow">
        <ng-container *ngFor="let j of formManagement">
          <ng-container *ngIf="j?.fieldName === 'Issue Photo' && j?.isShow">
            <app-dragndropinput
              [data]="j"
              [fieldName]="i?.fieldName"
              [isTouched]="myForm.get('edcNormalPhoto')?.touched"
            ></app-dragndropinput>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<!-- contact photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightContact"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Contactless Issue Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngFor="let i of formManagement">
      <ng-container
        *ngIf="
          i?.fieldName === 'Contactless Acceptance' &&
          i?.isShow &&
          isContactlessInput
        "
      >
        <ng-container *ngFor="let j of formManagement">
          <ng-container *ngIf="j?.fieldName === 'Issue Photo' && j?.isShow">
            <app-dragndropinput
              [data]="j"
              [fieldName]="i?.fieldName"
              [isTouched]="myForm.get('contactPhoto')?.touched"
            ></app-dragndropinput>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- General Qty -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightGeneralQty"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "General Material Quantity" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="myForm">
      <ng-container formArrayName="materialQty">
        <ng-container
          *ngFor="let choice of materialQty.controls; let index = index"
        >
          <div class="materialQty" [formGroupName]="index">
            <div
              class="backgroundQty d-flex align-items-center justify-content-between"
            >
              <div class="key d-flex align-items-center gap-2">
                <mat-checkbox
                  formControlName="isValue"
                  type="checkbox"
                  (change)="toggleDisable('isMatQty', index)"
                ></mat-checkbox
                ><span class="small-title" style="max-width: 175px">{{
                  choice.value.key
                }}</span>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 d-flex align-items-center">
                  <div
                    [ngClass]="
                      !choice.value.isValue ? 'wrapper-disable' : 'wrapper'
                    "
                  >
                    <button
                      class="plusminus"
                      (click)="handleMinus(index, 'matQty')"
                      [disabled]="
                        !choice.value.isValue || choice.value.value <= 1
                      "
                    >
                      -
                    </button>
                    {{ choice.value.value }}
                    <button
                      class="plusminus"
                      (click)="handleAdd(index, 'matQty')"
                      [disabled]="!choice.value.isValue"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <p *ngIf="materialQty.length <= 0" class="text-center my-5">
          {{ "No choice for General Material" | translate }}
        </p>
      </ng-container>
    </form>
    <!-- <div
      class="backgroundQty d-flex align-items-center justify-content-between"
    >
      <div class="key d-flex align-items-center gap-2">
        <input
          type="text"
          class="choice small-title"
          placeholder="{{ 'New Material' | translate }}"
          [(ngModel)]="choiceMaterial"
        />
      </div>
      <div class="row mt-2">
        <div class="col-md-12 d-flex align-items-center">
          <div class="wrapper">
            <button
              class="plusminus"
              (click)="handleMinusNewMaterial('matQty')"
            >
              -
            </button>
            {{ choiceQty }}
            <button class="plusminus" (click)="handleAddNewMaterial('matQty')">
              +
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-md-12 mt-4">
      <button class="btncancel w-100" (click)="addMaterialQty('matQty')">
        <span class="material-symbols-outlined small-title"> add </span>
        <span class="small-title">{{ "Add Material" | translate }}</span>
      </button>
    </div> -->
  </div>
</div>
<!-- General photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightGeneral"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "General Material Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngFor="let i of formManagement">
      <ng-container *ngIf="i?.fieldName === 'General Material' && i?.isShow">
        <app-dragndropinput
          [data]="i"
          [fieldName]="i?.fieldName"
          [isTouched]="myForm.get('materialPhoto')?.touched"
        ></app-dragndropinput>
      </ng-container>
    </ng-container>
  </div>
</div>
<!-- Customize mat Qty -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightCustomizedQty"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title text-center" id="offcanvasRightLabel">
      {{ "Customized Material Quantity" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <form [formGroup]="myForm">
      <ng-container formArrayName="materialCustomQty">
        <ng-container
          *ngFor="let choice of materialCustomQty.controls; let index = index"
        >
          <div class="materialCustomQty" [formGroupName]="index">
            <div
              class="backgroundQty d-flex align-items-center justify-content-between"
            >
              <div class="key d-flex align-items-center gap-2">
                <mat-checkbox
                  formControlName="isValue"
                  type="checkbox"
                  (change)="toggleDisable('isCustomMatQty', index)"
                ></mat-checkbox
                ><span class="small-title" style="max-width: 175px">{{
                  choice.value.key
                }}</span>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 d-flex align-items-center">
                  <div
                    [ngClass]="
                      !choice.value.isValue ? 'wrapper-disable' : 'wrapper'
                    "
                  >
                    <button
                      class="plusminus"
                      (click)="handleMinus(index, 'customMatQty')"
                      [disabled]="
                        !choice.value.isValue || choice.value.value <= 1
                      "
                    >
                      -
                    </button>
                    {{ choice.value.value }}
                    <button
                      class="plusminus"
                      (click)="handleAdd(index, 'customMatQty')"
                      [disabled]="!choice.value.isValue"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <p *ngIf="materialCustomQty.length <= 0" class="text-center my-5">
          {{ "No choice for Customize Material" | translate }}
        </p>
      </ng-container>
    </form>
    <!-- <div
      class="backgroundQty d-flex align-items-center justify-content-between"
    >
      <div class="key d-flex align-items-center gap-2">
        <input
          type="text"
          class="choice small-title"
          placeholder="{{ 'New Material' | translate }}"
          [(ngModel)]="choiceCustomMaterial"
        />
      </div>
      <div class="row mt-2">
        <div class="col-md-12 d-flex align-items-center">
          <div class="wrapper">
            <button
              class="plusminus"
              (click)="handleMinusNewMaterial('customMatQty')"
            >
              -
            </button>
            {{ choiceCustomQty }}
            <button
              class="plusminus"
              (click)="handleAddNewMaterial('customMatQty')"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mt-4">
      <button class="btncancel w-100" (click)="addMaterialQty('customMatQty')">
        <span class="material-symbols-outlined small-title"> add </span>
        <span class="small-title">{{ "Add Material" | translate }}</span>
      </button>
    </div> -->
  </div>
</div>
<!-- Customize mat photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightCustomized"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Customized Material Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngFor="let i of formManagement">
      <ng-container *ngIf="i?.fieldName === 'Customize Material' && i?.isShow">
        <app-dragndropinput
          [data]="i"
          [fieldName]="i?.fieldName"
          [isTouched]="myForm.get('customizeMaterialPhoto')?.touched"
        ></app-dragndropinput>
      </ng-container>
    </ng-container>
  </div>
</div>
<!-- Competitor photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightCompetitor"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button
      class="btnback"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="myForm.get('competitorPhoto')?.markAsTouched()"
    >
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Competitor Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <ng-container *ngFor="let i of formManagement">
      <ng-container
        *ngIf="
          i?.fieldName === 'Competitor Photo' && i?.isShow && isCompetitionInfo
        "
      >
        <app-dragndropinput
          [data]="i"
          [fieldName]="i?.fieldName"
          [isTouched]="myForm.get('competitorPhoto')?.touched"
        ></app-dragndropinput>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- offcanvas for edcData -->
<form [formGroup]="myForm">
  <ng-container formArrayName="edcData">
    <ng-container *ngFor="let edcForm of edcData.controls; let index = index">
      <div
        class="offcanvas offcanvas-bottom edcData"
        tabindex="-1"
        [id]="'edc' + index"
        aria-labelledby="offcanvasBottomLabel"
      >
        <div class="d-flex justify-content-center text-center edcData-header">
          <h2 class="edcData-title" id="offcanvasBottomLabel">
            {{ "EDC Status" | translate }}
          </h2>
        </div>
        <div class="background-edcData row mx-3">
          <div>
            <span class="small-title fw-semibold"
              >Bank : {{ edcForm.get("edcBank")?.value }}</span
            >
          </div>
          <div class="mt-1">
            <span class="small-title fw-semibold"
              >Terminal ID : {{ edcForm.get("edcTerminalId")?.value }}</span
            >
          </div>
        </div>
        <div class="offcanvas-body small edcBody" [formGroupName]="index">
          <ng-container *ngFor="let i of formManagement">
            <ng-container *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow">
              <ng-container *ngFor="let j of formManagement">
                <ng-container *ngIf="j?.fieldName === 'EDC Bank'">
                  <div
                    class="col-md-12 mt-2 d-flex justify-content-between align-items-center"
                  >
                    <span *ngIf="i?.isRequired" class="small-title">
                      {{ "Normal EDC Status" | translate }}*
                    </span>
                    <span *ngIf="!i?.isRequired" class="small-title">
                      {{ "Normal EDC status" | translate }}
                    </span>
                  </div>

                  <div class="col-md-12 mt-2">
                    <mat-select
                      class="form-control small-title"
                      formControlName="isNormalEDC"
                      [required]="i?.isRequired"
                      placeholder="{{ 'Select Status' | translate }}"
                    >
                      <mat-option [value]="false">{{
                        "Not ready" | translate
                      }}</mat-option>
                      <mat-option [value]="true">
                        {{ "Ready to use" | translate }}</mat-option
                      >
                    </mat-select>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let i of formManagement">
            <ng-container *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow">
              <ng-container *ngFor="let j of formManagement">
                <ng-container
                  *ngIf="j?.fieldName === 'Issue Remark' && j?.isShow"
                >
                  <div class="col-md-12 mt-3">
                    <span *ngIf="j?.isRequired" class="small-title">
                      {{ "Normal EDC Issue Remark" | translate }}*
                    </span>
                    <span *ngIf="!j?.isRequired" class="small-title">
                      {{ "Normal EDC Issue Remark" | translate }}
                    </span>
                    <textarea
                      formControlName="edcNormalIssue"
                      placeholder="{{ 'Issue remark' | translate }}..."
                      style="height: 126px !important"
                      rows="10"
                      class="form-control mt-2 small-title"
                      [required]="j?.isRequired"
                    ></textarea>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let i of formManagement">
            <ng-container
              *ngIf="i?.fieldName === 'Contactless Acceptance' && i?.isShow"
            >
              <ng-container *ngFor="let j of formManagement">
                <ng-container *ngIf="j?.fieldName === 'EDC Bank'">
                  <div class="col-md-12 mt-4 d-flex justify-content-between">
                    <span *ngIf="i?.isRequired" class="small-title">
                      {{ "Contactless Acceptance" | translate }}*
                    </span>
                    <span *ngIf="!i?.isRequired" class="small-title">
                      {{ "Contactless Acceptance" | translate }}
                    </span>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        formControlName="isContactAcceptance"
                        (ngModelChange)="
                          toggleIsContactAcceptance($event, index)
                        "
                      />
                    </div>
                  </div>
                  <ng-container
                    *ngIf="edcForm.get('isContactAcceptance')?.value"
                  >
                    <div
                      class="col-md-12 mt-4 d-flex justify-content-between align-items-center"
                    >
                      <span *ngIf="i?.isRequired" class="small-title">
                        {{ "Contactless EDC" | translate }}*
                      </span>
                      <span *ngIf="!i?.isRequired" class="small-title">
                        {{ "Contactless EDC" | translate }}
                      </span>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="edcForm.get('isContactAcceptance')?.value"
                  >
                    <div class="col-md-12 mt-2">
                      <mat-select
                        class="form-control small-title"
                        formControlName="isContactEDC"
                        [required]="
                          i?.isRequired &&
                          edcForm.get('isContactAcceptance')?.value
                        "
                        placeholder="{{ 'Select Status' | translate }}"
                      >
                        <mat-option [value]="false">{{
                          "Not ready" | translate
                        }}</mat-option>
                        <mat-option [value]="true">
                          {{ "Ready to use" | translate }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let i of formManagement">
            <ng-container
              *ngIf="
                i?.fieldName === 'Contactless Acceptance' &&
                i?.isShow &&
                edcForm.get('isContactAcceptance')?.value
              "
            >
              <ng-container *ngFor="let j of formManagement">
                <ng-container
                  *ngIf="j?.fieldName === 'Issue Remark' && j?.isShow"
                >
                  <div class="col-md-12 mt-3">
                    <span *ngIf="j?.isRequired" class="small-title">
                      {{ "Contactless Issue Remark" | translate }}*
                    </span>
                    <span *ngIf="!j?.isRequired" class="small-title">
                      {{ "Contactless Issue Remark" | translate }}
                    </span>
                    <textarea
                      formControlName="contactIssue"
                      placeholder="{{ 'Issue remark' | translate }}..."
                      style="height: 126px !important"
                      rows="10"
                      class="form-control mt-2 small-title"
                      [required]="
                        j?.isRequired &&
                        edcForm.get('isContactAcceptance')?.value
                      "
                    ></textarea>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngFor="let i of formManagement">
            <ng-container
              *ngIf="
                i?.fieldName === 'Contactless Acceptance' &&
                i?.isShow &&
                edcForm.get('isContactAcceptance')?.value
              "
            >
              <div class="col-md-12 mt-4">
                <span *ngIf="i?.isRequired" class="small-title">
                  {{ "EDC Placement (for Contactless only)" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired" class="small-title">
                  {{ "EDC Placement (for Contactless only)" | translate }}
                </span>
              </div>
              <div class="col-md-12 mt-2">
                <mat-select
                  class="form-control small-title mt-2"
                  formControlName="contactPlacement"
                  [required]="
                    i?.isRequired && edcForm.get('isContactAcceptance')?.value
                  "
                  placeholder="{{ 'Select EDC placement' | translate }}"
                >
                  <mat-option value="Facing to customer">{{
                    "Facing to customer" | translate
                  }}</mat-option>
                  <mat-option value="On counter but not facing to customer">
                    {{ "On counter but not facing to customer" | translate }}
                  </mat-option>
                  <mat-option value="Keep in counter">
                    {{ "Keep in counter" | translate }}</mat-option
                  >
                </mat-select>
              </div>

              <div class="col-md-12 mt-4">
                <span *ngIf="i?.isRequired" class="small-title">
                  {{ "Staff performance (for Contactless only)" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired" class="small-title">
                  {{ "Staff performance (for Contactless only)" | translate }}
                </span>
              </div>
              <div class="col-md-12 mt-2">
                <mat-select
                  class="form-control small-title mt-2"
                  formControlName="staffPerformance"
                  [required]="
                    i?.isRequired && edcForm.get('isContactAcceptance')?.value
                  "
                  placeholder="{{ 'Select Staff Performance' | translate }}"
                >
                  <mat-option value="Welled perform">
                    {{ "Welled perform" | translate }}</mat-option
                  >
                  <mat-option value="Don't know how to process">
                    {{ "Don't know how to process" | translate }}
                  </mat-option>
                  <mat-option value="Don't know about Contactless">
                    {{ "Don't know about Contactless" | translate }}
                  </mat-option>
                </mat-select>
              </div>
              <div class="col-md-12 mt-4">
                <span *ngIf="i?.isRequired" class="small-title">
                  {{
                    "Contactless training requirement at the merchant"
                      | translate
                  }}*
                </span>
                <span *ngIf="!i?.isRequired" class="small-title">
                  {{
                    "Contactless training requirement at the merchant"
                      | translate
                  }}
                </span>
              </div>
              <div class="col-md-12 mt-2">
                <mat-select
                  class="form-control small-title mt-2"
                  formControlName="isContactlessTraining"
                  [required]="
                    i?.isRequired && edcForm.get('isContactAcceptance')?.value
                  "
                  placeholder="{{ 'Select Training Requirement' | translate }}"
                >
                  <mat-option [value]="false">
                    {{ "No" | translate }}
                  </mat-option>
                  <mat-option [value]="true">
                    {{ "Yes" | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div
          class="d-flex gap-2 fixed-bottom justify-content-center pb-5 bg-white"
          style="z-index: 1000; padding-top: 20px"
        >
          <button
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            class="btnsave btnsave-custom d-flex align-items-center justify-content-center w-100 mx-3"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</form>

<!-- Modal Confirm Add -->
<div
  class="modal fade"
  id="confirmAdd"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              error
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Are you sure to add this merchant" | translate }}?
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 d-flex">
            <button
              class="btnsave w-100"
              (click)="acceptSubmit()"
              data-bs-dismiss="modal"
            >
              {{ "Yes" | translate }}
            </button>
            <button class="btncancel w-100 ms-1" data-bs-dismiss="modal">
              {{ "No" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal success -->
<button
  id="btnsuccess"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#success"
>
  Launch demo modal
</button>
<div class="modal fade" data-bs-backdrop="static" id="success" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 text-center">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>
        <div class="col-md-12 text-center">
          {{ "Merchant has been added" | translate }}
        </div>
        <div class="col-md-12 mt-4">
          <button
            data-bs-dismiss="modal"
            class="btnsave w-100"
            (click)="back()"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { COMMA, ENTER, T } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { Observable, map, startWith } from 'rxjs';
import { CustomInputService } from '../custom-input.service';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css'],
})
export class CustomInputComponent implements OnInit, OnChanges {
  @ViewChild('cardInput') cardInput: any;
  @ViewChild('auto') matAutocomplete: any;
  @ViewChild('autocompleteTriggerCard') matACTriggerCard: any;
  cardAcceptanceCtrl = new FormControl();
  filteredCardAcceptance?: Observable<string[]>;
  cardList: string[] = [];
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  isValid: any = false;
  other = '';
  placeholder = '';

  @Input() data: any;
  @Input() isValue: any;
  @Input() isTouched: any;
  @Input() isEdit: any;
  @Input() merchantDataCardAcceptance: any;
  @Input() merchantDataOtherPayment: any;

  @Input() merchantDataCompetition: any;
  constructor(private inputService: CustomInputService) {
    this.filteredCardAcceptance = this.cardAcceptanceCtrl.valueChanges.pipe(
      startWith(null),
      map((card: string | null) =>
        card ? this._filter(card) : this.data.choices.slice()
      )
    );
  }

  ngOnInit(): void {
    if (this.merchantDataCardAcceptance) {
      this.cardList = this.merchantDataCardAcceptance;
    } else if (this.merchantDataOtherPayment) {
      this.cardList = this.merchantDataOtherPayment;
    } else if (this.merchantDataCompetition) {
      this.cardList = this.merchantDataCompetition;
    }

    if (this.data.fieldName === 'Competitor Bank') {
      this.placeholder = `Select Competitors`;
    } else {
      this.placeholder = `Select ${this.data.fieldName}`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isValid =
      this.data.isRequired && !changes['isValue']?.currentValue && !this.isEdit;

    if (changes['isTouched']?.currentValue) {
      this.cardAcceptanceCtrl.markAsTouched();
    }
  }

  add(event: any): void {
    const input = event.input;
    const value = event.value;

    // Add our item
    if ((value || '').trim()) {
      this.cardList.push(value.trim());
      this.data.choices.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    if (this.data.fieldName === 'Other Payment') {
      this.inputService.OtherPaymentUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Card Acceptance') {
      this.inputService.CardAcceptanceUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Competitor Bank') {
      this.inputService.CompetitionUpdated.emit(this.cardList);
    }
    // this.myForm.get('cardAcceptance')?.setValue(this.cardList);
    this.cardAcceptanceCtrl.reset();
  }

  remove(item: string): void {
    const index = this.cardList.indexOf(item);

    if (index >= 0) {
      this.cardList.splice(index, 1);
    }

    if (this.data.fieldName === 'Other Payment') {
      this.inputService.OtherPaymentUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Card Acceptance') {
      this.inputService.CardAcceptanceUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Competitor Bank') {
      this.inputService.CompetitionUpdated.emit(this.cardList);
    }
    // this.myForm.get('cardAcceptance')?.setValue(this.cardList);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const newValue = event.option.viewValue;

    if (this.cardList.includes(newValue)) {
      this.cardList = [...this.cardList.filter((fruit) => fruit !== newValue)];
    } else {
      this.cardList.push(newValue);
    }
    if (this.data.fieldName === 'Other Payment') {
      this.inputService.OtherPaymentUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Card Acceptance') {
      this.inputService.CardAcceptanceUpdated.emit(this.cardList);
    } else if (this.data.fieldName === 'Competitor Bank') {
      this.inputService.CompetitionUpdated.emit(this.cardList);
    }

    this.cardInput.nativeElement.value = '';
    this.cardAcceptanceCtrl.reset();

    // this.myForm.get('cardAcceptance')?.setValue(this.cardList);
    requestAnimationFrame(() => {
      this.openAuto(this.matACTriggerCard);
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.data.choices.filter(
      (card: any) => card.toLowerCase().indexOf(filterValue) >= 0
    );
  }

  openAuto(trigger: MatAutocompleteTrigger) {
    trigger.openPanel();

    this.cardInput.nativeElement.focus();
  }
}

<div
  class="dropimage"
  ngClass="{{ dragAreaClass }}"
  [ngStyle]="{ 'border-color': isInValid ? 'red' : '#388087' }"
>
  <div class="row">
    <div class="col-md-12 text-center" *ngIf="previews.length === 0">
      <span [ngClass]="{ 'text-danger': isInValid }">
        {{ "Drag photo here or" | translate }}
      </span>
    </div>
    <div class="row g-3">
      <!-- edcNormalPhoto from Database -->
      <ng-container *ngIf="isEdit && edcNormalImg">
        <div
          class="col-md-2 mb-2 img-wrapper"
          *ngFor="
            let previewEdcNormal of edcNormalImg?.images;
            let index = index
          "
        >
          <button
            type="button"
            class="btn-close close"
            (click)="deleteimageDatabase(index)"
          ></button>
          <img [src]="previewEdcNormal.image" class="preview databaseImg" />
        </div>
      </ng-container>
      <!-- contactPhoto from Database -->
      <ng-container *ngIf="isEdit && contactImg">
        <div
          class="col-md-2 mb-2 img-wrapper"
          *ngFor="let previewContact of contactImg?.images; let index = index"
        >
          <button
            type="button"
            class="btn-close close"
            (click)="deleteimageDatabase(index)"
          ></button>
          <img [src]="previewContact.image" class="preview databaseImg" />
        </div>
      </ng-container>
      <!-- general material photo from Database -->
      <ng-container *ngIf="isEdit && materialImg">
        <div
          class="col-md-2 mb-2 img-wrapper"
          *ngFor="let previewMaterial of materialImg?.images; let index = index"
        >
          <button
            type="button"
            class="btn-close close"
            (click)="deleteimageDatabase(index)"
          ></button>
          <img [src]="previewMaterial.image" class="preview databaseImg" />
        </div>
      </ng-container>
      <!-- customize material photo from Database -->
      <ng-container *ngIf="isEdit && customizeMaterialImg">
        <div
          class="col-md-2 mb-2 img-wrapper"
          *ngFor="
            let previewCustomize of customizeMaterialImg?.images;
            let index = index
          "
        >
          <button
            type="button"
            class="btn-close close"
            (click)="deleteimageDatabase(index)"
          ></button>
          <img [src]="previewCustomize.image" class="preview databaseImg" />
        </div>
      </ng-container>
      <!-- competitorPhoto from Database -->
      <ng-container *ngIf="isEdit && competitorImg">
        <div
          class="col-md-2 mb-2 img-wrapper"
          *ngFor="
            let previewCompetitor of competitorImg?.images;
            let index = index
          "
        >
          <button
            type="button"
            class="btn-close close"
            (click)="deleteimageDatabase(index)"
          ></button>
          <img [src]="previewCompetitor.image" class="preview databaseImg" />
        </div>
      </ng-container>
      <div
        class="col-md-2 mb-2 img-wrapper"
        *ngFor="let preview of previews; let index = index"
      >
        <button
          type="button"
          class="btn-close close"
          (click)="deleteimage(index)"
        ></button>
        <img [src]="preview" class="preview" />
      </div>
    </div>
    <div class="col-md-12 text-center d-flex justify-content-center">
      <button
        class="btnsave d-flex align-items-center"
        (click)="fileissueremark.click()"
        [ngClass]="{ btnError: isInValid }"
        *ngIf="previews.length === 0"
      >
        <span class="material-symbols-outlined"> add </span>
        <span>
          {{ "Browse photo" | translate }}
        </span>
      </button>
      <button
        class="btnsave d-flex align-items-center"
        (click)="fileissueremark.click()"
        [ngClass]="{ btnError: isInValid }"
        *ngIf="previews.length !== 0"
      >
        <span class="material-symbols-outlined"> add </span>
        <span>
          {{ "Add more photo" | translate }}
        </span>
      </button>

      <input
        type="file"
        #fileissueremark
        multiple
        accept="image/*"
        (change)="onFileChange($event)"
        style="display: none"
      />
    </div>
  </div>
  <p *ngIf="isInValid" class="text-center text-danger mt-2">
    Please upload photo!
  </p>
</div>

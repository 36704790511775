<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <div class="text-title">
        {{ "Activity record" | translate }}
      </div>
    </div>
  </div>
  <div class="background p-3">
    <div class="row">
      <div class="col-md-6 mt-2">
        <span>
          {{ "Activity" | translate }}
        </span>
        <mat-select
          class="form-control mt-2"
          [(ngModel)]="servicename"
          (change)="getActivitydetail()"
          placeholder="{{ 'Select Activity' | translate }}"
        >
          <mat-option [value]="i" *ngFor="let i of activity">
            {{ i }}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-6 mt-2">
        <span>
          {{ "Admin" | translate }}
        </span>
        <mat-select
          class="form-control mt-2"
          [(ngModel)]="adminName"
          (change)="getActivitydetail()"
          placeholder="{{ 'Select Admin' | translate }}"
        >
          <mat-option [value]="i" *ngFor="let i of admins">
            {{ i }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mt-4">
        <div class="w-100 d-flex justify-content-between">
          <span>
            {{ "Date range" | translate }}
          </span>
          <span class="Clear" (click)="clearDate()">
            {{ "Clear" | translate }}
          </span>
        </div>
        <div class="form-control mt-2 d-flex align-items-center">
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              [placeholder]="this.lang === 'th' ? 'วันเริ่มต้น' : 'Start date'"
              [(ngModel)]="dateFrom"
              (change)="getActivitydetail()"
            />
            <input
              matEndDate
              [placeholder]="this.lang === 'th' ? 'วันสิ้นสุด' : 'End date'"
              [(ngModel)]="dateEnd"
              (change)="getActivitydetail()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
      </div>
      <div class="col-md-6 mt-3 d-flex align-items-end justify-content-end">
        <button
          class="btnsave me-3 d-flex align-items-center"
          (click)="getActivitydetail()"
        >
          <span class="material-symbols-outlined"> search </span>
          {{ "Search" | translate }}
        </button>
        <button class="btncancel" (click)="clearFilter()">
          {{ "Clear filter" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 d-flex align-items-center">
      <div class="text-title me-3">
        {{ "Result" | translate }}
      </div>
      <span class="badge"> {{ data.totalCount | number }} </span>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <!-- <div class="input-group mb-3 w-50">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'Search' | translate }}"
          aria-label="Username"
          aria-describedby="basic-addon1"
          [(ngModel)]="search"
          (input)="searchData(search)"
        />
        <span
          id="basic-addon1"
          class="material-symbols-outlined input-group-text"
        >
          search
        </span>
      </div> -->
      <button
        class="btncancel ms-3"
        style="padding: 15px 15px !important"
        (click)="sortOrder()"
      >
        <span class="material-symbols-outlined"> swap_vert </span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <table class="w-100">
        <thead>
          <tr>
            <th>{{ "Activity" | translate }}</th>
            <th>{{ "Date" | translate }}</th>
            <th>{{ "Time" | translate }}</th>
            <th>{{ "Done by" | translate }}</th>
            <th>{{ "Role" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let i of data['totalData']
                | paginate
                  : {
                      itemsPerPage: row,
                      currentPage: p,
                      totalItems: totalCount
                    }
            "
          >
            <tr>
              <td>
                <span
                  class="detail"
                  data-bs-toggle="modal"
                  attr.data-bs-target="#Detai{{ i._id }}"
                  (click)="getdetail(i._id)"
                  >{{ i.servicename }}</span
                >
              </td>
              <td>{{ i.createdAt | date : "dd/MM/yyyy" }}</td>
              <td>{{ i.createdAt | date : "hh:mm a" }}</td>
              <td>{{ i.createdBy.name }}</td>
              <td>{{ i.createdBy.roles }}</td>
              <td
                *ngIf="i.flag != 3"
                class="d-flex align-items-center justify-content-end"
              >
                <span
                  class="material-symbols-outlined ms-4 curser"
                  data-bs-toggle="modal"
                  attr.data-bs-target="#Detai{{ i._id }}"
                  (click)="getdetail(i._id)"
                >
                  arrow_forward_ios
                </span>
              </td>
              <td
                *ngIf="i.flag == 3"
                class="d-flex align-items-center justify-content-end"
              >
                <span class="material-symbols-outlined ms-4">
                  arrow_forward_ios
                </span>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="data?.length == 0">
            <tr>
              <td colspan="7" class="text-center">
                {{ "No data" | translate }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 d-flex align-items-center justify-content-between">
      <div>
        <pagination-controls
          (pageChange)="pageChanged($event)"
          [responsive]="true"
          previousLabel=""
          nextLabel=""
        ></pagination-controls>
      </div>
      <select
        [(ngModel)]="row"
        class="form-select w-auto"
        (change)="getActivitydetail()"
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  </div>
</div>
<ng-container *ngFor="let i of data['totalData']">
  <!-- Modal Detail-->
  <div
    class="modal fade"
    id="Detai{{ i._id }}"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">
            {{ "Activity Detail" | translate }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="container-fluid p-3">
          <div class="background p-3">
            <div class="row">
              <div class="col-md-6 d-flex flex-column">
                <span>
                  {{ detail?.servicename | translate }}
                </span>
                <span class="mt-2">
                  {{ detail?.createdAt | date : "dd/MM/yyyy" }}
                  {{ detail?.createdAt | date : "hh:mm a" }}
                </span>
              </div>
              <div class="col-md-6">
                <div class="btncard">
                  <span class="material-symbols-outlined me-2"> badge </span>
                  {{ detail?.createdBy.name }}
                </div>
              </div>
            </div>
          </div>
          <ng-container>
            <div class="background p-4">
              <div class="row">
                <div
                  class="col-md-12 mt-2 d-flex justify-content-between align-items-center"
                  *ngFor="
                    let i of detail?.message | keyvalue;
                    let index = index
                  "
                >
                  <span *ngIf="i.key != 'data'">
                    {{ i.key }} : {{ i.value }}
                  </span>
                  <span *ngIf="i.key == 'data'">
                    {{ i.key }} : {{ i.value | json }}
                  </span>
                  <span
                    class="material-symbols-outlined ms-4 curser"
                    *ngIf="index == 0"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <div class="background p-4" *ngFor="let j of i.edit">
              <div class="row">
                <div class="col-md-12">
                  <span>
                    {{ j.list }}
                  </span>
                </div>
                <div class="col-md-12 mt-2 d-flex align-items-center">
                  <span>
                    {{ j.original_data }}
                  </span>
                  <span class="material-symbols-outlined ms-2 me-2">
                    arrow_forward_ios
                  </span>
                  <span>
                    {{ j.new_data }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="row">
            <div class="col-md-12 mt-5">
              <button class="btnsave w-100" data-bs-dismiss="modal">
                {{ "Done" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

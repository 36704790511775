import { T } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ServiceService } from 'src/app/service/service.service';
import { StorageService } from 'src/app/service/storage.service';
import { Roles } from 'src/app/shares/roles.enum';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
})
export class EditUserComponent implements OnInit {
  data: any = {};
  roles: any;
  isLoading = false;
  errMessage = '';
  name = new FormControl(null, Validators.required);
  phoneNumber = new FormControl(null, Validators.minLength(10));
  constructor(
    private service: ServiceService,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.roles = this.authService.getRole();
  }

  back() {
    if (this.roles === Roles.DEPLOYMENT) {
      this.router.navigate(['/deployment/database']);
    } else {
      this.router.navigate(['/database']);
    }
  }

  updateuser() {
    document.getElementById('btnsuccess')?.click();
  }

  async getUser() {
    let user: any = await this.service.GetTokenPathParams('/user/myProfile');
    this.data = user;
    this.name.patchValue(this.data.name);
    this.phoneNumber.patchValue(this.data.phoneNumber);
  }

  async updateProfile() {
    this.isLoading = false;
    this.errMessage = '';

    if (this.name.invalid && this.phoneNumber.invalid) {
      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

      firstInvalidControl.focus();
    } else {
      this.isLoading = true;
      this.errMessage = '';
      const dataBody = {
        name: this.name.value,
        phoneNumber: this.phoneNumber.value,
      };

      let user: any = await this.service.PatchTokenPathBody(
        '/user/myProfile',
        dataBody
      );
      if (user.message == 'Update success') {
        this.isLoading = false;
        let users: any = await this.service.GetTokenPathParams(
          '/user/myProfile'
        );
        this.storageService.store('proFile', users);
        this.updateuser();
      } else {
        this.isLoading = false;

        if (user.error.message) {
          this.errMessage = user.error.message[0];
        } else {
          this.errMessage = 'Something went wrong, please try again later!';
        }
      }
    }
  }
}

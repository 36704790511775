<div class="container">
  <div class="forMobile">
    <a class="back" routerLink="/deployment/database">
      <span
        class="material-symbols-outlined"
        style="font-size: 24px; color: #388087"
      >
        keyboard_backspace
      </span>
      <span style="color: #388087">{{ "Back" | translate }}</span></a
    >
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div class="row">
          <div class="col-md-12">
            <h1>{{ "Change Password" | translate }}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            {{ "Current Password" | translate }}
          </div>
          <div class="col-md-12 mt-2">
            <input
              type="password"
              class="form-control"
              required
              [formControl]="currentPassword"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            {{ "New Password" | translate }}
          </div>
          <div class="col-md-12 mt-2">
            <input
              type="password"
              class="form-control"
              required
              [formControl]="Password"
              minlength="8"
            />
          </div>
          <div
            *ngIf="Password.errors && Password.touched"
            class="text-danger fs-6"
          >
            {{ "Password must be at least 8 characters." | translate }}
          </div>
          <div class="col-md-12 mt-2 fs-6 fw-lighter">
            {{ "Required at least 8 characters" | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            {{ "Confirm new Password" | translate }}
          </div>
          <div class="col-md-12 mt-2">
            <input
              type="password"
              class="form-control"
              required
              [formControl]="newPassword"
            />
          </div>
        </div>
        <div class="row" *ngIf="errMessage">
          <div class="col-md-12 mt-4">
            <p class="text-danger fs-6">{{ errMessage }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <button
              class="btnsave w-100"
              (click)="changePassword()"
              *ngIf="!isLoading"
            >
              {{ "Change password" | translate }}
            </button>
            <button class="btnsave w-100" [disabled]="true" *ngIf="isLoading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<button
  id="btnsuccess"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#success"
>
  Launch demo modal
</button>

<!-- Modal success -->
<div class="modal fade" data-bs-backdrop="static" id="success" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 text-center">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            error
          </span>
        </div>
        <div class="col-md-12 text-center">
          {{ "Password has been changed" | translate }}
        </div>
        <div class="col-md-12 mt-4">
          <button
            data-bs-dismiss="modal"
            class="btnsave w-100"
            (click)="back()"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/service/auth.service';
import { LanguageService } from 'src/app/service/language.service';
import { ServiceService } from 'src/app/service/service.service';
import { StorageService } from 'src/app/service/storage.service';

@Component({
  selector: 'app-deployment',
  templateUrl: './deployment.component.html',
  styleUrls: ['./deployment.component.css'],
})
export class DeploymentComponent implements OnInit {
  isAuthenticate?: boolean;
  role: any;
  user: any;
  translate: TranslateService;
  data: any = {};
  roleUI: any;

  constructor(
    translate: TranslateService,
    private service: ServiceService,
    private langChange: LanguageService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private authService: AuthService
  ) {
    this.translate = translate;
  }

  ngOnInit(): void {
    let x: any = localStorage.getItem('lang');
    if (!x) {
      localStorage.setItem('lang', 'en');
      this.translate?.use('en');
    } else {
      let useLang: any = localStorage.getItem('lang');
      this.translate?.use(useLang);
    }

    let role: any = localStorage.getItem('role');
    let user: any = localStorage.getItem('proFile');
    this.role = JSON.parse(role);
    this.data = JSON.parse(user);

    this.storageService.changes.subscribe((profile) => {
      const value = profile.value;
      this.data.name = value.name;
      this.data.phoneNumber = value.phoneNumber;
    });

    if (this.role.roles[0] === 'SUPERADMIN') {
      this.roleUI = 'Super Admin';
    } else if (this.role.roles[0] === 'ADMIN') {
      this.roleUI = 'Admin';
    } else if (this.role.roles[0] === 'EXTERNAL') {
      this.roleUI = 'External Admin';
    } else if (this.role.roles[0] === 'DEPLOYMENT') {
      this.roleUI = 'Deployment team';
    }
  }
  async Logout() {
    let logout: any = await this.service.GetTokenPathParams('/auth/logout');
    localStorage.clear();

    window.location.replace('/login');
  }
  changeLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.langChange.setLanguage(lang);
    this.translate.use(lang);
  }
  editUser() {
    this.router.navigate(['/deployment/edit-user']);
  }

  changePassword() {
    this.router.navigate(['/deployment/change-password']);
  }
  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}

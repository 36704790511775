<div class="container pt-3 custom-margin">
  <div class="row mb-4">
    <div class="input-group col-md-12">
      <input
        type="text"
        class="form-control"
        style="border-right: none"
        placeholder="{{ 'Search Merchant' | translate }}"
        aria-label="Username"
        aria-describedby="basic-addon1"
        [(ngModel)]="selectData.merchantName"
        (keyup.enter)="onSearch()"
      />
      <span
        *ngIf="selectData.merchantName"
        class="material-symbols-outlined input-group-text"
        style="border-left: none"
        (click)="clearOnSearch()"
        >clear</span
      >
      <span
        id="basic-addon1"
        class="material-symbols-outlined input-group-text"
        style="border-left: none"
        (click)="onSearch()"
      >
        search
      </span>
    </div>
    <div class="d-flex mt-3 gap-2">
      <button class="btncancel w-50" (click)="openSnackBar()">
        <span class="material-symbols-outlined me-1"> map </span>
        {{ "View on Map" | translate }}
      </button>

      <button
        class="btncancel w-50"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasBottom"
        aria-controls="offcanvasBottom"
      >
        <span class="material-symbols-outlined me-1"> tune </span>
        {{ "Filter" | translate }}
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between align-items-center mb-2">
    <p class="col-9" style="margin: 0 !important">
      {{ "No. of Merchant" | translate }} :
      <span class="badge">{{ totalCount | number }}</span>
    </p>
    <button
      class="btncancel ms-3 col-3"
      style="width: 10px !important"
      (click)="sortOrder()"
    >
      <span class="material-symbols-outlined"> swap_vert </span>
    </button>
  </div>
  <div
    infinite-scroll
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="1000"
    (scrolled)="onScroll()"
    class="row g-3 row-cols-md-2"
  >
    <div
      class="highlight-box-custom"
      style="cursor: pointer"
      *ngFor="let data of dataList"
      (click)="detail(data._id)"
    >
      <div class="card h-100 shadow-sm p-3">
        <div class="wrapper-content d-flex justify-content-between">
          <div class="content-name d-flex flex-column justify-content-between">
            <span class="fw-lighter small-title">{{ data?.merchantId }}</span>
            <p>{{ data?.merchantName }}</p>
            <span class="small-title">{{ data?.locationName }}</span>
          </div>
          <div class="content-detail d-flex flex-column gap-2 align-items-end">
            <div class="d-flex align-items-center">
              <span class="material-symbols-outlined"> badge </span>
              <span class="ps-2 small-title">{{ data?.createdBy?.name }}</span>
            </div>
            <span class="fw-lighter small-title">{{
              data?.createdAt | date : "dd/MM/yyyy"
            }}</span>
            <span class="badge fw-lighter small-badge">{{
              data.category
            }}</span>
          </div>
        </div>

        <!-- <div class="wrapper-bank d-flex justify-content-between mt-4">
          <div class="bankleft">
            <span class="badge fw-lighter small-badge">{{ "VISA" }}</span>
            <span class="badge fw-lighter small-badge mx-1">{{
              "Mastercard"
            }}</span>
            <span class="badge fw-lighter small-badge">{{ "+2" }}</span>
          </div>
          <div class="bankright">
            <span class="badge fw-lighter small-badge me-1">{{ "VISA" }}</span>
            <span class="badge fw-lighter small-badge">{{ "+2" }}</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="text-center my-3" *ngIf="dataList?.length === 0 && !isLoading">
    No Match Data
  </div>
  <div class="text-center my-3" *ngIf="isLoading">Loading...</div>
  <div class="text-center my-3" *ngIf="endScroll" style="margin-bottom: 120px">
    {{ endScroll }}
  </div>
</div>
<div
  class="d-flex gap-2 fixed-bottom justify-content-center pb-5 bg-white"
  style="z-index: 1000; padding-top: 20px"
>
  <button
    class="btnsave btnsave-custom d-flex align-items-center justify-content-center"
    routerLink="/deployment/add-merchant"
  >
    <span class="material-symbols-outlined me-3"> add </span>
    {{ "Add Merchant" | translate }}
  </button>
</div>
<!-- for filter  -->
<!-- offcanvas for mobile -->
<div
  class="offcanvas offcanvas-bottom"
  tabindex="-1"
  id="offcanvasBottom"
  aria-labelledby="offcanvasBottomLabel"
>
  <div class="offcanvas-header d-flex justify-content-center">
    <h2 class="offcanvas-title" id="offcanvasBottomLabel">
      {{ "Filter" | translate }}
    </h2>
  </div>
  <div class="offcanvas-body small">
    <div class="row custom-margin">
      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "Location" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          placeholder="{{ 'Select Location' | translate }}"
          [(ngModel)]="selectData.locationName"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalLocation"
            [value]="i?._id"
            >{{ i?.locationName }}</mat-option
          >
        </mat-select>
      </div>
      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "Category" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.category"
          placeholder="{{ 'Select Category' | translate }}"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalCate"
            [value]="i?._id"
            >{{ i?.category }}</mat-option
          >
        </mat-select>
      </div>
      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "Card acceptance" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.cardAcceptance"
          placeholder="{{ 'Select Card acceptance' | translate }}"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalCardAcc"
            [value]="i?._id"
            >{{ i?.cardAcceptance }}</mat-option
          >
        </mat-select>
      </div>
      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "EDC Bank" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.acquirerBank"
          placeholder="{{ 'Select Bank' | translate }}"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalEdcBank"
            [value]="i?._id"
            >{{ i?.edcBank }}</mat-option
          >
        </mat-select>
      </div>
      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "Contactless acceptance" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.contractlessStatus"
          placeholder="{{ 'Contactless acceptance' | translate }}"
        >
          <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
          <mat-option [value]="false">{{ "No" | translate }}</mat-option>
        </mat-select>
      </div>

      <div class="col-md-4 mt-4">
        <div class="select-item">
          <span>{{ "VISA Materials" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.generalMaterial"
          placeholder="{{ 'Select VISA materials' | translate }}"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalGeneral"
            [value]="i?._id"
            >{{ i?.key }}</mat-option
          >
        </mat-select>
      </div>
      <div class="col-md-4 mt-4 mb-4">
        <div class="select-item">
          <span>{{ "VISA Customized Material" | translate }}</span>
        </div>
        <mat-select
          class="form-control mt-3 custom-mat-select"
          [(ngModel)]="selectData.customizeMaterial"
          placeholder="{{ 'Select VISA Customized Material' | translate }}"
        >
          <mat-option
            *ngFor="let i of dataFilters?.totalCustomize"
            [value]="i?._id"
            >{{ i?.key }}</mat-option
          >
        </mat-select>
      </div>

      <div
        class="d-flex gap-2 fixed-bottom justify-content-center pb-5 bg-white"
      >
        <button
          class="btnsave d-flex align-items-center justify-content-center"
          (click)="onSearch()"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span class="material-symbols-outlined"> search </span>
          {{ "Search" | translate }}
        </button>
        <button class="btncancel" (click)="Clearall()">
          {{ "Clear filter" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomInputService {
  CardAcceptanceUpdated = new EventEmitter<string[]>();
  QrUpdated = new EventEmitter<string[]>();
  EwalletUpdated = new EventEmitter<string[]>();
  OtherPaymentUpdated = new EventEmitter<string[]>();
  CompetitionUpdated = new EventEmitter<string[]>();
  constructor() {}
}

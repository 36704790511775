import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk',
})
export class ChunkPipe implements PipeTransform {
  transform(arr: any[], startIndex: number, stopIndex: number): any[] {
    const chunks = arr?.slice(startIndex, stopIndex);

    return chunks;
  }
}

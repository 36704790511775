<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/admin-management">
              {{ "Admin management" | translate }}
            </a>
          </li>
          <li class="me-2 ms-2">></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Admin info" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4 d-flex justify-content-between">
      <div class="text-title d-flex align-items-center">
        {{ "Admin info" | translate }}
      </div>
      <button
        data-
        class="btncancel"
        data-bs-toggle="modal"
        data-bs-target="#Removeadmin"
      >
        {{ "Remove Admin" | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4">
      <div class="background p-4">
        <div class="row">
          <div class="col-md-12 d-flex align-items-center">
            <span class="material-symbols-outlined me-2"> badge </span>
            {{ data?.name }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <span class="badge"> {{ data?.roles }} </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <span> {{ "Country" | translate }} &nbsp;: </span>
            <span>{{ data?.formId?.country }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <span> {{ "Phone number" | translate }}&nbsp;: </span>
            <span>{{ data?.phoneNumber }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <span> {{ "Email" | translate }}&nbsp;: </span>
            <span>{{ data?.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Remove-->
<div
  class="modal fade"
  id="Removeadmin"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              error
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Are you sure to remove admin" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 text-center">
            <span style="font-size: 14px">
              {{
                "Please confirm admin password to confirm this action"
                  | translate
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <input
              type="password"
              class="form-control"
              placeholder="{{ 'Confirm Admin Password' | translate }}"
              [(ngModel)]="password"
            />
          </div>
        </div>
        <div class="row">
          <button
            class="btnsave"
            id="btnsuccess"
            data-bs-toggle="modal"
            data-bs-target="#Deleteadmin"
            style="display: none"
          >
            {{ "Invite Admin" | translate }}
          </button>
          <div class="col-md-12 mt-2 text-danger text-center">
            {{ responds }}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 d-flex justify-content-evenly">
            <button
              class="btnsave"
              (click)="deleteUseradmin()"
              *ngIf="!isLoading"
            >
              {{ "Confirm" | translate }}
            </button>
            <button class="btnsave w-100" [disabled]="true" *ngIf="isLoading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
            <button
              class="btncancel"
              #btnclose
              data-bs-dismiss="modal"
              *ngIf="!isLoading"
            >
              {{ "Cancel" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Remove-->
<div
  class="modal fade"
  id="Deleteadmin"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              check_circle
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Delete Success" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-2 text-center">
            <span style="font-size: 14px">
              {{ "This admin has been already deleted!" | translate }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <button
              class="btnsave w-100"
              data-bs-dismiss="modal"
              (click)="back()"
            >
              {{ "Done" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

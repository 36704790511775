<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/database">
              {{ "Database" | translate }}
            </a>
          </li>
          <li class="me-2 ms-2">></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Merchant info" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="text-title d-flex align-items-center">
        {{ "Merchant info" | translate }}
      </div>
      <ng-container *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'">
        <div class="d-flex">
          <button
            class="btncancel"
            [routerLink]="['/database-marchant-edit', id]"
          >
            {{ "Edit Merchant" | translate }}
          </button>
          <button
            class="btndelete ms-2"
            data-bs-toggle="modal"
            data-bs-target="#Delete"
          >
            {{ "Delete Merchant" | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <!-- Left -->
    <div class="col-md-6">
      <div class="background p-3">
        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="title">
              {{ data?.merchantName | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <p>Date record : {{ data?.createdAt | date : "dd/MM/yyyy" }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-3">
            <span class="badge">
              {{ data?.category ? data?.category : "-" }}
            </span>
          </div>
        </div>
      </div>
      <div class="background p-3">
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="title">
              {{ "Contact" | translate }}
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <b>
              {{ "Contact Person" | translate }}
            </b>
            <p>
              {{ data?.contactName ? data?.contactName : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <b>
              {{ "Phone" | translate }}
            </b>
            <p>
              {{ data?.phoneNumber ? data?.phoneNumber : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <b>
              {{ "Email" | translate }}
            </b>
            <p>
              {{ data?.email ? data?.email : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <b>
              {{ "Fax" | translate }}
            </b>
            <p>
              {{ data?.fax ? data?.fax : "-" }}
            </p>
          </div>
        </div>
      </div>
      <div class="background p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              {{ "EDC Terminal" | translate }}
            </div>
          </div>
        </div>
        <div class="box-background p-3 mb-3" *ngFor="let i of data?.edcData">
          <div class="row">
            <div class="col-md-6">
              <b>
                {{ "Bank" | translate }}
              </b>
              <p>
                {{ i.edcBank ? i.edcBank : "-" }}
              </p>
            </div>
            <div class="col-md-6">
              <b>
                {{ "Terminal ID" | translate }}
              </b>
              <p>
                {{ i.edcTerminalId ? i.edcTerminalId : "-" }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b>
                {{ "EDC Status" | translate }}
              </b>
              <p class="mb-0">
                {{ i.isNormalEDC ? "Ready to use" : "Not Ready" }}
              </p>
            </div>
            <div class="col-md-12">
              <b>
                {{ "Normal EDC Issue Remark" | translate }}
              </b>
              <p>
                {{ i.edcNormalIssue ? i.edcNormalIssue : "-" }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b>
                {{ "Contactless acceptance" | translate }}
              </b>
              <p class="mb-0">
                {{ i?.isContactAcceptance ? "Yes" : "No" }}
              </p>
            </div>
            <div class="col-md-12">
              <b>
                {{ "Contactless EDC" | translate }}
              </b>
              <p class="mb-0" *ngIf="i?.isContactAcceptance">
                {{ i?.isContactEDC ? "Ready to use" : "Not ready" }}
              </p>
              <p class="mb-0" *ngIf="!i?.isContactAcceptance">-</p>
            </div>
            <div class="col-md-12">
              <b>
                {{ "Contactless Issue Remark" | translate }}
              </b>
              <p class="mb-0">
                {{ i?.contactIssue ? i?.contactIssue : "-" }}
              </p>
            </div>
            <div class="col-md-12">
              <b>
                {{ "Contactless Training Requirement" | translate }}
              </b>
              <!-- <ng-container
                *ngIf="i?.isContactlessTraining; else trainingDefult"
              >
                <p class="mb-0">
                  {{ "Training" | translate }}
                </p>
              </ng-container>
              <ng-template #trainingDefult>
                <p class="mb-0">
                  {{ "No Training" | translate }}
                </p>
              </ng-template> -->
              <p
                class="mb-0"
                *ngIf="
                  i?.isContactAcceptance && i?.isContactlessTraining !== ''
                "
              >
                {{ i?.isContactlessTraining ? "Yes" : "No" }}
              </p>
              <p
                class="mb-0"
                *ngIf="
                  (i?.isContactAcceptance && i?.isContactlessTraining === '') ||
                  !i?.isContactAcceptance
                "
              >
                -
              </p>
            </div>
          </div>
        </div>
        <b>
          {{ "Normal EDC Issue Photo" | translate }}
        </b>
        <div class="row g-2 mt-2 mb-3">
          <ng-container *ngFor="let i of data?.images">
            <ng-container *ngIf="i._id === 'edcNormalPhoto'">
              <!-- <div class="col-md-4" *ngFor="let j of i.images | chunk : 0 : 2">
                <img src="{{ j.image }}" class="box" alt="" />
              </div> -->
              <app-gallery [data]="i"></app-gallery>
            </ng-container>
          </ng-container>
          <div *ngIf="!checkIfImagesExist('edcNormalPhoto')">-</div>
        </div>
        <b>
          {{ "Contactless Issue Photo" | translate }}
        </b>
        <div class="row g-2 mt-2">
          <ng-container *ngFor="let i of data?.images">
            <ng-container *ngIf="i._id === 'contactPhoto'">
              <!-- <div class="col-md-4" *ngFor="let j of i.images">
                <img src="{{ j.image }}" class="box" alt="" />
              </div> -->
              <app-gallery [data]="i"></app-gallery>
            </ng-container>
          </ng-container>
          <div *ngIf="!checkIfImagesExist('contactPhoto')">-</div>
        </div>
      </div>
    </div>
    <!--End Left -->

    <!-- Right -->
    <div class="col-md-6">
      <div class="background p-3">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-between">
            <div class="title">
              {{ "Location" | translate }}
            </div>
            <span class="material-symbols-outlined icon-border">
              location_on
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <b>
              {{ "Shop Location" | translate }}
            </b>
            <p>
              {{
                data?.isOnStreet
                  ? ("[On street]" | translate)
                  : ("[In Building]" | translate)
              }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-2">
            <b>
              {{ "Shop phone number" | translate }}
            </b>
            <p>
              {{ data?.shopPhoneNumber ? data?.shopPhoneNumber : "-" }}
            </p>
          </div>
          <div class="col-md-6 mt-2">
            <b>
              {{ "Floor" | translate }}
            </b>
            <p>
              {{ data?.floor ? data?.floor : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-2">
            <b>
              {{ "Street" | translate }}
            </b>
            <p>
              {{ data?.street ? data?.street : "-" }}
            </p>
          </div>
          <div class="col-md-6 mt-2">
            <b>
              {{ "District" | translate }}
            </b>
            <p>
              {{ data?.district ? data?.district : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-2">
            <b>
              {{ "Province" | translate }}
            </b>
            <p>
              {{ data?.province ? data?.province : "-" }}
            </p>
          </div>
          <div class="col-md-6 mt-2">
            <b>
              {{ "Zipcode" | translate }}
            </b>
            <p>
              {{ data?.zipcode ? data?.zipcode : "-" }}
            </p>
          </div>
        </div>
      </div>
      <div class="background p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              {{ "Type of Payment" | translate }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <h2>
              {{ "Card Acceptance" | translate }}
            </h2>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-auto p-0" *ngFor="let i of data?.cardAcceptance">
            <div class="chishp">{{ i }}</div>
          </div>
          <p *ngIf="data?.cardAcceptance?.length === 0">-</p>
        </div>
        <div class="row">
          <div class="col-md-6 mt-4">
            <b>
              {{ "Minimum Payment" | translate }}
            </b>
            <p>
              {{ data?.minimumPayment ? data?.minimumPayment : "-" }}
            </p>
          </div>
          <div class="col-md-6 mt-4">
            <b> {{ "Surcharge" | translate }} (%) </b>
            <p>
              {{ data?.surcharge ? data?.surcharge : "-" }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5">
            <h2>
              {{ "Other Payment" | translate }}
            </h2>
          </div>
        </div>
        <div class="row px-2">
          <div class="col-md-auto p-0" *ngFor="let i of data?.otherPayment">
            <div class="chishp">{{ i }}</div>
          </div>
          <p *ngIf="data?.otherPayment?.length === 0">-</p>
        </div>
      </div>

      <div class="background p-3">
        <div class="row">
          <div class="col-md-12">
            <div class="title">
              {{ "VISA Material" | translate }}
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12 mb-0">
            <h2 class="mb-2">
              {{ "General Material" | translate }}
            </h2>
          </div>
          <div class="col-md-6" *ngFor="let item of data?.materialQty">
            <p
              class="mx-0 my-1 fs-6"
              [ngClass]="item.value > 0 ? '' : 'no-value'"
            >
              {{ item.key | translate }} ({{ item.value }})
            </p>
          </div>
          <p *ngIf="data?.materialQty && data?.materialQty?.length === 0">-</p>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 mb-0">
            <h2 class="mb-2">
              {{ "Customized Material" | translate }}
            </h2>
          </div>

          <div class="col-md-6" *ngFor="let item of data?.materialCustomQty">
            <p
              class="mx-0 my-1 fs-6"
              [ngClass]="item.value > 0 ? '' : 'no-value'"
            >
              {{ item.key | translate }} ({{ item.value }})
            </p>
          </div>
          <p
            *ngIf="
              data?.materialCustomQty && data?.materialCustomQty?.length === 0
            "
          >
            -
          </p>
          <div class="row mt-4 mb-3">
            <div class="col-md-12">
              <div class="title">
                {{ "Competitor info" | translate }}
              </div>
            </div>
          </div>
          <div class="row px-4">
            <div class="col-md-auto p-0" *ngFor="let i of data?.competitorBank">
              <div class="chishp">{{ i }}</div>
            </div>
          </div>
          <p *ngIf="data?.competitorBank?.length === 0">-</p>
        </div>

        <!--End Right -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div class="title">
          {{ "Image Section" | translate }}
        </div>
        <button
          class="btncancel"
          data-bs-toggle="modal"
          data-bs-target="#ExportLoading"
          (click)="exportMerchant()"
        >
          <span class="material-symbols-outlined me-3"> download </span>
          {{ "Export" | translate }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "VISA General Meterial" | translate }} ({{
                  generalMaterialPhotoNumber + migratePhoto
                }}
                {{ generalMaterialPhotoNumber > 1 ? "Photos" : "Photo" }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <ng-container *ngFor="let item of data.images">
                <ng-container *ngIf="item._id === 'generalMaterialPhoto'">
                  <!-- <div class="col-md-3 me-3" *ngFor="let image of item.images">
                    <img
                      src="{{ image.image }}"
                      class="box"
                      alt="{{ item._id }}"
                    />
                  </div> -->
                  <app-gallery [data]="item"></app-gallery>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let i of data?.images">
                <ng-container
                  *ngIf="i._id === 'imageMigrate' && i.images?.length > 0"
                >
                  <div class="col-md-12 mb-2">
                    {{ i._id | formatPropertyName }}
                  </div>
                  <div
                    class="col-md-2 me-3"
                    *ngFor="let j of i.images; let index = index"
                  >
                    <a
                      href="{{ j.image }}"
                      target="_blank"
                      class="d-flex align-items-center"
                    >
                      <span class="material-symbols-outlined"> link </span>
                      <span style="text-decoration: underline"
                        >Photo {{ index + 1 }}</span
                      ></a
                    >
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "VISA Customized Meterial" | translate }} ({{
                  customizeMaterialPhotoNumber
                }}
                {{ customizeMaterialPhotoNumber > 1 ? "Photos" : "Photo" }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <ng-container *ngFor="let item of data.images">
                <ng-container *ngIf="item._id === 'customizeMaterialPhoto'">
                  <!-- <div class="col-md-3 me-3" *ngFor="let image of item.images">
                    <img
                      src="{{ image.image }}"
                      class="box"
                      alt="{{ item._id }}"
                    />
                  </div> -->
                  <app-gallery [data]="item"></app-gallery>
                </ng-container>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ "Competitor's Photo" | translate }} ({{ competitorPhoto }}
                {{ competitorPhoto > 1 ? "Photos" : "Photo" }})
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <ng-container *ngFor="let i of data?.images">
                <ng-container *ngIf="i._id === 'competitorPhoto'">
                  <!-- <div class="col-md-3 me-3" *ngFor="let j of i.images">
                    <img src="{{ j.image }}" class="box" alt="" />
                  </div> -->
                  <app-gallery [data]="i"></app-gallery>
                </ng-container>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <!-- Modal Delete-->
    <div
      class="modal fade"
      id="Delete"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="container p-5 pt-2 pb-3">
            <div class="row">
              <div class="col-md-12 text-center">
                <span
                  class="material-symbols-outlined"
                  style="font-size: 100px; color: #388087"
                >
                  error
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <span>
                  {{ "Are you sure to permanent delete merchant" | translate }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-2 text-center">
                <span style="font-size: 14px">
                  {{ "Please confirm admin password to process" | translate }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-4">
                <input
                  type="password"
                  class="form-control"
                  placeholder="{{ 'Confirm Admin Password' | translate }}"
                  [(ngModel)]="password"
                />
              </div>
              <p class="text-danger text-center mt-2" *ngIf="response">
                {{ response }}
              </p>
            </div>
            <div class="row">
              <div class="col-md-12 mt-5 d-flex">
                <button class="btnsavedelete w-100" (click)="confirmDelete()">
                  {{ "Confirm Delete" | translate }}
                </button>
                <button
                  #btnclose
                  class="btncancel w-100 ms-1"
                  data-bs-dismiss="modal"
                >
                  {{ "Cancel" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal export loading-->
<div
  class="modal"
  id="ExportLoading"
  data-bs-keyboard="false"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div
          class="d-flex justify-content-center"
          *ngIf="!isDone && errMessage.length === 0"
        >
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <p class="text-center my-2" *ngIf="!isDone && errMessage.length === 0">
          {{ "Exporting the data, please wait" | translate }}...
        </p>
        <div class="col-md-12 text-center" *ngIf="isDone">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>

        <p
          class="text-center my-2 text-danger"
          *ngIf="isDone && errMessage.length !== 0"
        >
          {{ errMessage | translate }}
        </p>
        <div
          class="progress"
          role="progressbar"
          aria-label="Success example"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          *ngIf="errMessage.length === 0"
        >
          <div class="progress-bar bg-success" [style]="style"></div>
        </div>

        <div class="col-md-12 mt-2 d-flex" *ngIf="isDone">
          <button
            class="btncancel w-100 ms-1"
            #btnclose
            data-bs-dismiss="modal"
            (click)="progress = 0"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a routerLink="/database">
              {{ "Database" | translate }}
            </a>
          </li>
          <li class="me-2 ms-2">></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ "Add Merchant" | translate }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="text-title">
        {{ "Add Merchant" | translate }}
      </div>
      <select
        class="form-select w-50"
        (ngModelChange)="getFormId($event)"
        [(ngModel)]="countryId"
      >
        <option *ngFor="let i of totalCountry" [value]="i._id">
          {{ i.country }}
        </option>
      </select>
    </div>
  </div>
  <div class="background p-3">
    <div class="row">
      <div class="col-md-12 d-flex align-items-center">
        <span class="material-symbols-outlined text-name me-2"> badge </span>
        {{ user?.name }}
      </div>
      <div class="col-md-12 mb-0">
        {{ user?.roles }}
      </div>
    </div>
  </div>

  <form [formGroup]="myForm">
    <div class="background p-3">
      <div class="row">
        <div class="col-md-12 d-flex align-items-center">
          <div class="text-title">
            {{ "Merchant Detail" | translate }}
          </div>
        </div>
        <ng-container *ngFor="let i of formManagement">
          <div class="col-md-12 mt-2" *ngIf="i?.fieldName === 'Merchant name'">
            <span>{{ "Merchant name" | translate }}*</span>
            <input
              type="text"
              class="form-control mt-2"
              formControlName="merchantName"
              [required]="i?.isRequired"
              placeholder="{{ 'Fill merchant name' | translate }}"
            />
          </div>
          <div class="col-md-6" *ngIf="i?.fieldName === 'Category'">
            <span>{{ "Category" | translate }}*</span>

            <mat-select
              formControlName="category"
              class="form-control mt-2"
              [required]="i?.isRequired"
              placeholder="{{ 'Select Category' | translate }}"
              (ngModelChange)="toggleCategoryInputRequired($event)"
            >
              <mat-option *ngFor="let j of i.choices" [value]="j">
                {{ j }}
              </mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </div>
          <div
            class="col-md-6 d-flex align-items-end"
            *ngIf="i?.fieldName === 'Category'"
          >
            <input
              type="text"
              class="form-control"
              formControlName="newCategory"
              [required]="categoryInputRequired"
              placeholder="{{ 'Fill new category name' | translate }}"
            />
          </div>
        </ng-container>
      </div>
    </div>

    <div class="background p-3">
      <div class="row">
        <div class="col-md-12 mt-2">
          <div class="text-title">
            {{ "Location" | translate }}
          </div>
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <div class="row" *ngIf="i?.fieldName === 'Location'">
          <div class="col-md-6 mt-2">
            <span>
              {{
                "Location (Shopping mall, Building, Market, etc.)" | translate
              }}
            </span>
            <mat-select
              formControlName="locationName"
              class="form-control mt-2"
              [required]="i?.isRequired"
              placeholder="{{ 'Select Location' | translate }}"
              (ngModelChange)="toggleLocationNameInputRequired($event)"
            >
              <mat-option *ngFor="let j of i.choices" [value]="j?.name">
                {{ j?.name }}
              </mat-option>
              <mat-option value="Others">Others</mat-option>
            </mat-select>
          </div>
          <div class="col-md-6 d-flex align-items-end">
            <input
              type="text"
              formControlName="newLocationName"
              [required]="locationNameInputRequired"
              class="form-control"
              placeholder="{{ 'Fill new location name' | translate }}"
            />
          </div>
        </div>
      </ng-container>

      <div class="row mt-2 mb-2">
        <div class="col-md-6 mt-2">
          {{ "Location type" | translate }}
        </div>
      </div>

      <div class="row mb-2 p-1 ps-4">
        <!-- <input type="checkbox" formControlName="isOnStreet" />
        {{
          "This Merchant are on street (Not in any Shopping mall, Building, Market, etc.)"
            | translate
        }} -->

        <div class="form-check form-check-inline col-5">
          <input
            formControlName="isOnStreet"
            class="form-check-input"
            type="radio"
            name="isOnStreet"
            id="inlineRadio2"
            [value]="false"
          />
          <label class="form-check-label small-title" for="inlineRadio2">{{
            "In Building" | translate
          }}</label>
        </div>
        <div class="form-check form-check-inline col-5">
          <input
            formControlName="isOnStreet"
            class="form-check-input"
            type="radio"
            name="isOnStreet"
            id="inlineRadio1"
            [value]="true"
          />
          <label class="form-check-label small-title" for="inlineRadio1">{{
            "On Street" | translate
          }}</label>
        </div>
      </div>
      <div class="row">
        <ng-container *ngFor="let i of formManagement">
          <ng-container
            *ngIf="i?.fieldName === 'Shop phone number' && i?.isShow"
          >
            <div class="col-md-6 mt-2">
              <span *ngIf="i?.isRequired"
                >{{ "Shop phone number" | translate }}*</span
              >
              <span *ngIf="!i?.isRequired">{{
                "Shop phone number" | translate
              }}</span>
              <ng-container
                *ngIf="country === 'Thailand' || country === 'Cambodia'"
              >
                <input
                  maxlength="10"
                  type="text"
                  class="form-control mt-2"
                  formControlName="shopPhoneNumber"
                  [required]="i?.isRequired"
                  placeholder="{{ 'Shop phone number' | translate }}"
                />
              </ng-container>
              <ng-container *ngIf="country === 'Myanmar'">
                <input
                  maxlength="11"
                  type="text"
                  class="form-control mt-2"
                  formControlName="shopPhoneNumber"
                  [required]="i?.isRequired"
                  placeholder="{{ 'Shop phone number' | translate }}"
                />
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <div class="col-md-6 mt-2">
          <span>{{ "Floor" | translate }}</span>
          <input
            type="text"
            formControlName="floor"
            class="form-control mt-2"
            placeholder="{{ 'Floor' | translate }}"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-2">
          <span>{{ "Street" | translate }}*</span>
          <input
            type="text"
            formControlName="street"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'Street' | translate }}"
          />
        </div>
        <div class="col-md-6 mt-2" *ngIf="country === 'Thailand'">
          <span>{{ "District" | translate }}*</span>
          <input
            type="text"
            formControlName="district"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'District' | translate }}"
            [matAutocomplete]="autoDistrict"
            matInput
          />
          <mat-autocomplete #autoDistrict="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptionDistrict | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-6 mt-2" *ngIf="country !== 'Thailand'">
          <span>{{ "District" | translate }}*</span>
          <input
            type="text"
            formControlName="district"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'District' | translate }}"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-2" *ngIf="country === 'Thailand'">
          <span>{{ "Province" | translate }}*</span>
          <input
            type="text"
            formControlName="province"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'Province' | translate }}"
            [matAutocomplete]="auto"
            matInput
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of filteredOptionProvince | async"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-6 mt-2" *ngIf="country !== 'Thailand'">
          <span>{{ "Province" | translate }}*</span>
          <input
            type="text"
            formControlName="province"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'Province' | translate }}"
          />
        </div>
        <div class="col-md-6 mt-2">
          <span>{{ "Zipcode" | translate }}*</span>
          <input
            type="text"
            formControlName="zipcode"
            class="form-control mt-2"
            [required]="true"
            placeholder="{{ 'Zipcode' | translate }}"
          />
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Latitude' && i?.isShow">
          <div class="row">
            <div class="col-md-6 mt-2">
              <span *ngIf="i?.isRequired"
                >{{ "Location on map" | translate }}*</span
              >
              <span *ngIf="!i?.isRequired">{{
                "Location on map" | translate
              }}</span>
              <div class="input-group mt-2">
                <button
                  [ngClass]="
                    myForm.get('latitude')?.invalid &&
                    myForm.get('latitude')?.touched
                      ? 'btn-invalid'
                      : ''
                  "
                  class="btncancel w-100"
                  (click)="Getlocation()"
                  [disabled]="locationLoading"
                >
                  <span class="material-symbols-outlined me-2">
                    location_on
                  </span>
                  Location on map
                </button>
              </div>
              <input
                style="display: none"
                formControlName="latitude"
                [required]="i?.isRequired"
              />
              <!-- <p
                class="text-danger text-center"
                *ngIf="
                  myForm.get('latitude')?.invalid &&
                  myForm.get('latitude')?.touched
                "
              >
                Please mark the location!
              </p> -->
            </div>
            <div class="col-md-6 d-flex align-items-end mt-2">
              <span *ngIf="!data.lat && !data.long && !locationLoading">
                {{ "Location didn't mark yet" | translate }}
              </span>
              <span *ngIf="locationLoading">
                {{ "Marking your location... please wait" | translate }}
                <div
                  class="spinner-border spinner-border-sm text-success"
                  text-success
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </span>
              <div
                class="w-100 d-flex justify-content-between"
                *ngIf="data.lat && data.long && !locationLoading"
              >
                <span> Latitude : {{ data.lat.toFixed(2) }}N </span>
                <span> Longitude : {{ data.long.toFixed(2) }}E </span>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="background p-3">
      <div class="row">
        <div class="col-md-12">
          <div class="text-title">
            {{ "Contact" | translate }}
          </div>
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <div
          class="row"
          *ngIf="i?.fieldName === 'Contact person name' && i?.isShow"
        >
          <div class="col-md-12 mt-2">
            <span *ngIf="i?.isRequired">
              {{ "Contact person" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired">
              {{ "Contact person" | translate }}
            </span>
            <input
              type="text"
              formControlName="contactName"
              class="form-control mt-2"
              [required]="i?.isRequired"
              placeholder="{{ 'Contact person name' | translate }}"
            />
          </div>
        </div>
      </ng-container>
      <div class="row">
        <ng-container *ngFor="let i of formManagement">
          <div
            class="col-md-6 mt-2"
            *ngIf="i?.fieldName === 'Phone number' && i?.isShow"
          >
            <span *ngIf="i?.isRequired">
              {{ "Phone number" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired">
              {{ "Phone number" | translate }}
            </span>
            <ng-container
              *ngIf="country === 'Thailand' || country === 'Cambodia'"
            >
              <input
                maxlength="10"
                type="text"
                class="form-control mt-2"
                formControlName="phoneNumber"
                [required]="i?.isRequired"
                placeholder="{{ 'Phone number' | translate }}"
              />
            </ng-container>
            <ng-container *ngIf="country === 'Myanmar'">
              <input
                maxlength="11"
                type="text"
                class="form-control mt-2"
                formControlName="phoneNumber"
                [required]="i?.isRequired"
                placeholder="{{ 'Phone number' | translate }}"
              />
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngFor="let i of formManagement">
          <div
            class="col-md-6 mt-2"
            *ngIf="i?.fieldName === 'Email' && i?.isShow"
          >
            <span *ngIf="i?.isRequired"> {{ "Email" | translate }}* </span>
            <span *ngIf="!i?.isRequired">
              {{ "Email" | translate }}
            </span>
            <input
              type="text"
              formControlName="email"
              class="form-control mt-2"
              [required]="i?.isRequired"
              placeholder="{{ 'Email' | translate }}"
            />
          </div>
        </ng-container>

        <ng-container *ngFor="let i of formManagement">
          <div
            class="col-md-6 mt-2"
            *ngIf="i?.fieldName === 'FAX' && i?.isShow"
          >
            <span *ngIf="i?.isRequired"> {{ "FAX" | translate }}* </span>
            <span *ngIf="!i?.isRequired">
              {{ "FAX" | translate }}
            </span>
            <input
              type="text"
              formControlName="fax"
              class="form-control mt-2"
              [required]="i?.isRequired"
              placeholder="{{ 'FAX' | translate }}"
            />
          </div>
        </ng-container>
      </div>
    </div>
    <div class="background p-3">
      <div class="row">
        <div class="col-md-12">
          <div class="text-title">
            {{ "Type of Payment" | translate }}
          </div>
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <div
          class="row"
          *ngIf="i?.fieldName === 'Card Acceptance' && i?.isShow"
        >
          <app-custom-input
            [data]="i"
            [isValue]="isCardAcceptanceControlValid('cardAcceptance')"
          ></app-custom-input>
        </div>
      </ng-container>

      <div class="row mt-2">
        <ng-container *ngFor="let i of formManagement">
          <div
            class="col-md-6"
            *ngIf="i?.fieldName === 'Minimum payment' && i?.isShow"
          >
            <span *ngIf="i?.isRequired">
              {{ "Minimum spending" | translate }}*
            </span>
            <span *ngIf="!i?.isRequired">
              {{ "Minimum spending" | translate }}
            </span>
            <input
              type="text"
              formControlName="minimumPayment"
              [required]="i?.isRequired"
              class="form-control mt-2"
              (input)="onlyNumber($event, 'minimumPayment')"
              placeholder="{{ 'Minimum spending' | translate }}"
            />
            <div class="mt-2">
              <input
                type="checkbox"
                formControlName="no_minimumPayment"
                (change)="toggleDisable('no_minimumPayment')"
              />
              <span>
                {{ "No Minimum spending" | translate }}
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngFor="let i of formManagement">
          <div
            class="col-md-6"
            *ngIf="i?.fieldName === 'Surcharge' && i?.isShow"
          >
            <span *ngIf="i?.isRequired">
              {{ "Surcharge" | translate }} (%)*
            </span>
            <span *ngIf="!i?.isRequired">
              {{ "Surcharge" | translate }} (%)
            </span>
            <input
              type="text"
              formControlName="surcharge"
              class="form-control mt-2"
              [required]="i?.isRequired"
              (input)="onlyNumber($event, 'surcharge')"
              placeholder="{{ 'Surcharge' | translate }}"
            />
            <div class="mt-2">
              <input
                type="checkbox"
                formControlName="no_surcharge"
                (change)="toggleDisable('no_surcharge')"
              />
              <span>
                {{ "No Surcharge" | translate }}
              </span>
            </div>
          </div>
        </ng-container>
      </div>

      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Other Payment' && i?.isShow">
          <app-custom-input
            [data]="i"
            [isValue]="isCardAcceptanceControlValid('otherPayment')"
          ></app-custom-input>
        </ng-container>
      </ng-container>
    </div>

    <div class="background p-3">
      <div class="row">
        <div class="col-md-12">
          <div class="text-title">
            {{ "EDC Terminal" | translate }}
          </div>
        </div>
      </div>
      <ng-container formArrayName="edcData">
        <ng-container
          *ngFor="let edcForm of edcData.controls; let index = index"
        >
          <div class="row edcTerminal" [formGroupName]="index">
            <!-- <button
              type="button"
              *ngIf="index !== 0"
              (click)="deleteEdcData(index)"
              class="btn-close close-btn"
              aria-label="Close"
              style="width: 5px; height: 5px"
            ></button> -->
            <hr class="mt-3" *ngIf="index !== 0" />
            <ng-container *ngFor="let i of formManagement">
              <div
                class="col-md-6 mt-2"
                *ngIf="i?.fieldName === 'EDC Bank' && i?.isShow"
              >
                <span *ngIf="i?.isRequired">
                  {{ "Bank name" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired">
                  {{ "Bank name" | translate }}
                </span>

                <mat-select
                  class="form-control mt-2"
                  [required]="i?.isRequired"
                  formControlName="edcBank"
                  placeholder="{{ 'Select Bank' | translate }}"
                >
                  <mat-option *ngFor="let bank of i.choices" [value]="bank">
                    {{ bank }}
                  </mat-option>
                </mat-select>
              </div>
            </ng-container>
            <ng-container *ngFor="let i of formManagement">
              <div
                class="col-md-6 mt-2"
                *ngIf="i?.fieldName === 'Terminal ID' && i?.isShow"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span *ngIf="i?.isRequired">
                    {{ "Terminal ID" | translate }}*
                  </span>
                  <span *ngIf="!i?.isRequired">
                    {{ "Terminal ID" | translate }}
                  </span>
                  <span
                    (click)="deleteEdcData(index)"
                    class="small-title text-danger"
                    style="text-decoration: underline; cursor: pointer"
                    *ngIf="index !== 0"
                    >Remove EDC</span
                  >
                </div>

                <input
                  type="text"
                  class="form-control mt-2"
                  placeholder="{{ 'Terminal ID' | translate }}"
                  formControlName="edcTerminalId"
                  [required]="i?.isRequired"
                />
                <div class="my-2">
                  <input
                    formControlName="no_TerminalId"
                    type="checkbox"
                    (change)="toggleDisable('no_TerminalId', index)"
                  />
                  <span>
                    {{ "No Terminal ID" | translate }}
                  </span>
                </div>
              </div>
            </ng-container>
            <button
              class="addphoto mt-2 mb-3"
              data-bs-toggle="modal"
              [attr.data-bs-target]="'#edc' + index"
            >
              <div
                class="d-flex background-pic justify-content-between align-items-center"
              >
                <span>{{ "EDC Status" | translate }} / </span>
                <span>
                  {{ "Contactless Acceptance" | translate }}
                </span>
              </div>
              <span class="material-symbols-outlined"> chevron_right </span>
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let item of formManagement">
        <div class="row" *ngIf="item.fieldName === 'EDC Bank' && item?.isShow">
          <div class="col-md-12 mt-4">
            <button class="btncancel w-100" (click)="addEdcData()">
              <span class="material-symbols-outlined"> add </span>
              {{ "Add EDC" | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow">
          <ng-container *ngFor="let j of formManagement">
            <ng-container *ngIf="j?.fieldName === 'Issue Photo' && j?.isShow">
              <div class="row">
                <div class="col-md-12">
                  <span *ngIf="j?.isRequired">
                    {{ "Normal EDC Issue Photo" | translate }}*
                  </span>
                  <span *ngIf="!j?.isRequired">
                    {{ "Normal EDC Issue Photo" | translate }}
                  </span>
                </div>
              </div>
              <input
                style="display: none"
                formControlName="edcNormalPhoto"
                [required]="i?.isRequired"
              />
              <app-dragndropinput
                [data]="j"
                [fieldName]="i?.fieldName"
                [isTouched]="myForm.get('edcNormalPhoto')?.touched"
              ></app-dragndropinput>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let i of formManagement">
        <ng-container
          *ngIf="
            i?.fieldName === 'Contactless Acceptance' &&
            i?.isShow &&
            isContactlessInput
          "
        >
          <ng-container *ngFor="let j of formManagement">
            <ng-container *ngIf="j?.fieldName === 'Issue Photo' && j?.isShow">
              <div class="row mt-3">
                <div class="col-md-12">
                  <span *ngIf="j?.isRequired">
                    {{ "Contactless Issue Photo" | translate }}*
                  </span>
                  <span *ngIf="!j?.isRequired">
                    {{ "Contactless Issue Photo" | translate }}
                  </span>
                </div>
              </div>
              <input
                style="display: none"
                formControlName="contactPhoto"
                [required]="i?.isRequired && isContactlessInput"
              />
              <app-dragndropinput
                [data]="j"
                [fieldName]="i?.fieldName"
                [isTouched]="myForm.get('contactPhoto')?.touched"
              ></app-dragndropinput>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div class="background p-3">
      <div class="row">
        <div class="col-md-12 mt-4">
          <div class="text-title">
            {{ "VISA Material" | translate }}
          </div>
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'General Material' && i?.isShow">
          <div class="row">
            <div class="col-md-12 mt-4">
              <span *ngIf="i?.isRequired">
                {{ "General Material" | translate }}*
              </span>
              <span *ngIf="!i?.isRequired">
                {{ "General Material" | translate }}
              </span>

              <button
                class="addphoto mt-2 mb-3"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropGeneral"
              >
                {{ "Material Quantity" | translate }}
                <span class="material-symbols-outlined"> chevron_right </span>
              </button>
              <div class="mb-2">
                <span *ngIf="i?.isRequired">
                  {{ "General Material Photo" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired">
                  {{ "General Material Photo" | translate }}
                </span>
              </div>
              <input
                style="display: none"
                formControlName="materialPhoto"
                [required]="i?.isRequired"
              />
              <app-dragndropinput
                [data]="i"
                [fieldName]="i?.fieldName"
                [isTouched]="myForm.get('materialPhoto')?.touched"
              ></app-dragndropinput>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let i of formManagement">
        <ng-container
          *ngIf="i?.fieldName === 'Customize Material' && i?.isShow"
        >
          <div class="row">
            <div class="col-md-12 mt-4">
              <span *ngIf="i?.isRequired">
                {{ "Customized Material" | translate }}*
              </span>
              <span *ngIf="!i?.isRequired">
                {{ "Customized Material" | translate }}
              </span>

              <button
                class="addphoto mt-2 mb-3"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdropCustomize"
              >
                {{ "Material Quantity" | translate }}
                <span class="material-symbols-outlined"> chevron_right </span>
              </button>
              <div class="mb-2">
                <span *ngIf="i?.isRequired">
                  {{ "Customized Material Photo" | translate }}*
                </span>
                <span *ngIf="!i?.isRequired">
                  {{ "Customized Material Photo" | translate }}
                </span>
              </div>
              <input
                style="display: none"
                formControlName="customizeMaterialPhoto"
                [required]="i?.isRequired"
              />
              <app-dragndropinput
                [data]="i"
                [fieldName]="i?.fieldName"
                [isTouched]="myForm.get('customizeMaterialPhoto')?.touched"
              ></app-dragndropinput>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="background p-3">
      <div class="row">
        <div class="col-md-12 mt-4 d-flex justify-content-between">
          <div class="text-title">
            {{ "Competitor info" | translate }}
          </div>
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              formControlName="isCompetitionInfo"
              (ngModelChange)="toggleIsCompetitionInfo($event)"
            />
          </div>
        </div>
      </div>
      <ng-container *ngFor="let i of formManagement">
        <ng-container *ngIf="i?.fieldName === 'Competitor Bank' && i?.isShow">
          <ng-container *ngIf="isCompetitionInfo">
            <input
              style="display: none"
              formControlName="competitorBank"
              [required]="i?.isRequired && isCompetitionInfo"
            />
            <app-custom-input
              [data]="i"
              [isValue]="isCardAcceptanceControlValid('competitorBank')"
            ></app-custom-input>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="i?.fieldName === 'Competitor Photo' && i?.isShow">
          <ng-container *ngIf="isCompetitionInfo">
            <input
              style="display: none"
              formControlName="competitorPhoto"
              [required]="i?.isRequired && isCompetitionInfo"
            />
            <app-dragndropinput
              [data]="i"
              [fieldName]="i?.fieldName"
              [isTouched]="myForm.get('competitorPhoto')?.touched"
            ></app-dragndropinput>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
  <div class="row">
    <div class="col-md-12">
      <p class="text-danger" *ngIf="errorMessage">
        {{ errorMessage | translate }}
      </p>
      <button
        class="btnsave"
        *ngIf="!isLoading"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#confirmAdd"
      >
        {{ "Add Merchant" | translate }}
      </button>
      <button class="btnsave" type="button" disabled *ngIf="isLoading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {{ "Adding Merchant Please Wait" | translate }}...
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<!-- Modal General Material -->
<div
  class="modal fade"
  id="staticBackdropGeneral"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "VISA General Material" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>

      <form [formGroup]="myForm">
        <ng-container formArrayName="materialQty">
          <ng-container
            *ngFor="let choice of materialQty.controls; let index = index"
          >
            <div class="materialQty" [formGroupName]="index">
              <div
                class="backgroundQty d-flex align-items-center justify-content-between"
              >
                <div class="key d-flex align-items-center gap-2">
                  <mat-checkbox
                    formControlName="isValue"
                    type="checkbox"
                    (change)="toggleDisable('isMatQty', index)"
                  ></mat-checkbox
                  ><span class="small-title">{{ choice.value.key }}</span>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12 d-flex align-items-center">
                    <div
                      [ngClass]="
                        !choice.value.isValue ? 'wrapper-disable' : 'wrapper'
                      "
                    >
                      <button
                        class="plusminus"
                        (click)="handleMinus(index, 'matQty')"
                        [disabled]="
                          !choice.value.isValue || choice.value.value <= 1
                        "
                      >
                        -
                      </button>
                      <span> {{ choice.value.value }}</span>

                      <button
                        class="plusminus"
                        (click)="handleAdd(index, 'matQty')"
                        [disabled]="!choice.value.isValue"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <p *ngIf="materialQty.length <= 0" class="text-center my-5">
            {{ "No choice for General Material" | translate }}
          </p>
        </ng-container>
      </form>
      <!-- <div
        class="backgroundQty d-flex align-items-center justify-content-between"
      >
        <div class="key d-flex align-items-center gap-2">
          <input
            type="text"
            class="choice small-title"
            placeholder="{{ 'New Material' | translate }}"
            [(ngModel)]="choiceMaterial"
          />
        </div>
        <div class="row mt-2">
          <div class="col-md-12 d-flex align-items-center">
            <div class="wrapper">
              <button
                class="plusminus"
                (click)="handleMinusNewMaterial('matQty')"
              >
                -
              </button>
              {{ choiceQty }}
              <button
                class="plusminus"
                (click)="handleAddNewMaterial('matQty')"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <button class="btncancel w-100" (click)="addMaterialQty('matQty')">
          <span class="material-symbols-outlined small-title"> add </span>
          <span class="small-title">{{ "Add Material" | translate }}</span>
        </button>
      </div> -->
    </div>
  </div>
</div>
<!-- Modal Customize Material -->
<div
  class="modal fade"
  id="staticBackdropCustomize"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
  style="background-color: rgba(0, 0, 0, 0.5)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between">
          <div class="text-title" id="staticBackdropLabel">
            {{ "VISA Customized Material" | translate }}
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
      </div>

      <form [formGroup]="myForm">
        <ng-container formArrayName="materialCustomQty">
          <ng-container
            *ngFor="let choice of materialCustomQty.controls; let index = index"
          >
            <div class="materialCustomQty" [formGroupName]="index">
              <div
                class="backgroundQty d-flex align-items-center justify-content-between"
              >
                <div class="key d-flex align-items-center gap-2">
                  <mat-checkbox
                    formControlName="isValue"
                    type="checkbox"
                    (change)="toggleDisable('isCustomMatQty', index)"
                  ></mat-checkbox
                  ><span class="small-title">{{ choice.value.key }}</span>
                </div>
                <div class="row mt-2">
                  <div class="col-md-12 d-flex align-items-center">
                    <div
                      [ngClass]="
                        !choice.value.isValue ? 'wrapper-disable' : 'wrapper'
                      "
                    >
                      <button
                        class="plusminus"
                        (click)="handleMinus(index, 'customMatQty')"
                        [disabled]="
                          !choice.value.isValue || choice.value.value <= 1
                        "
                      >
                        -
                      </button>
                      {{ choice.value.value }}
                      <button
                        class="plusminus"
                        (click)="handleAdd(index, 'customMatQty')"
                        [disabled]="!choice.value.isValue"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <p *ngIf="materialCustomQty.length <= 0" class="text-center my-5">
            {{ "No choice for Customize Material" | translate }}
          </p>
        </ng-container>
      </form>
      <!-- <div
        class="backgroundQty d-flex align-items-center justify-content-between"
      >
        <div class="key d-flex align-items-center gap-2">
          <input
            type="text"
            class="choice small-title"
            placeholder="{{ 'New Material' | translate }}"
            [(ngModel)]="choiceCustomMaterial"
          />
        </div>
        <div class="row mt-2">
          <div class="col-md-12 d-flex align-items-center">
            <div class="wrapper">
              <button
                class="plusminus"
                (click)="handleMinusNewMaterial('customMatQty')"
              >
                -
              </button>
              {{ choiceCustomQty }}
              <button
                class="plusminus"
                (click)="handleAddNewMaterial('customMatQty')"
              >
                +
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-4">
        <button
          class="btncancel w-100"
          (click)="addMaterialQty('customMatQty')"
        >
          <span class="material-symbols-outlined small-title"> add </span>
          <span class="small-title">{{ "Add Material" | translate }}</span>
        </button>
      </div> -->
    </div>
  </div>
</div>

<!--  edcData -->

<form [formGroup]="myForm">
  <ng-container formArrayName="edcData">
    <ng-container *ngFor="let edcForm of edcData.controls; let index = index">
      <div
        class="modal fade"
        [id]="'edc' + index"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style="background-color: rgba(0, 0, 0, 0.5)"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content p-3">
            <div class="row modal-header">
              <div class="col-md-12 d-flex justify-content-between">
                <div class="text-title" id="staticBackdropLabel">
                  {{ "EDC Status" | translate }} /
                  {{ "Contactless Acceptance" | translate }}
                </div>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="background-edcData row">
                <div>
                  <span class="fw-semibold"
                    >Bank : {{ edcForm.get("edcBank")?.value }}</span
                  >
                </div>
                <div class="mt-1">
                  <span class="fw-semibold"
                    >Terminal ID :
                    {{ edcForm.get("edcTerminalId")?.value }}</span
                  >
                </div>
              </div>
            </div>
            <div class="modal-body" [formGroupName]="index">
              <ng-container *ngFor="let i of formManagement">
                <ng-container
                  *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow"
                >
                  <ng-container *ngFor="let j of formManagement">
                    <ng-container *ngIf="j?.fieldName === 'EDC Bank'">
                      <div class="row">
                        <div
                          class="col-md-12 mt-4 d-flex justify-content-between align-items-center"
                        >
                          <span *ngIf="i?.isRequired">
                            {{ "Normal EDC Status" | translate }}*
                          </span>
                          <span *ngIf="!i?.isRequired">
                            {{ "Normal EDC Status" | translate }}
                          </span>
                          <mat-select
                            class="form-control w-25"
                            formControlName="isNormalEDC"
                            [required]="i?.isRequired"
                            placeholder="{{ 'Select Status' | translate }}"
                          >
                            <mat-option [value]="false">{{
                              "Not ready" | translate
                            }}</mat-option>
                            <mat-option [value]="true">
                              {{ "Ready to use" | translate }}</mat-option
                            >
                          </mat-select>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let i of formManagement">
                <ng-container
                  *ngIf="i?.fieldName === 'Normal EDC' && i?.isShow"
                >
                  <ng-container *ngFor="let j of formManagement">
                    <ng-container
                      *ngIf="j?.fieldName === 'Issue Remark' && j?.isShow"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <span *ngIf="j?.isRequired">
                            {{ "Normal EDC Issue Remark" | translate }}*
                          </span>
                          <span *ngIf="!j?.isRequired">
                            {{ "Normal EDC Issue Remark" | translate }}
                          </span>
                          <textarea
                            formControlName="edcNormalIssue"
                            placeholder="{{ 'Issue remark...' | translate }}"
                            style="height: 200px !important"
                            rows="10"
                            class="form-control mt-2"
                            [required]="j?.isRequired"
                          ></textarea>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let i of formManagement">
                <ng-container
                  *ngIf="i?.fieldName === 'Contactless Acceptance' && i?.isShow"
                >
                  <div class="row">
                    <div class="col-md-12 mt-4 d-flex justify-content-between">
                      <span *ngIf="i?.isRequired">
                        {{ "Contactless Acceptance" | translate }}*
                      </span>
                      <span *ngIf="!i?.isRequired">
                        {{ "Contactless Acceptance" | translate }}
                      </span>
                      <div class="form-check form-switch">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          formControlName="isContactAcceptance"
                          (ngModelChange)="
                            toggleIsContactAcceptance($event, index)
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="edcForm.get('isContactAcceptance')?.value"
                  >
                    <div
                      class="col-md-12 mt-4 d-flex justify-content-between align-items-center"
                    >
                      <span *ngIf="i?.isRequired">
                        {{ "Contactless EDC" | translate }}*
                      </span>
                      <span *ngIf="!i?.isRequired">
                        {{ "Contactless EDC" | translate }}
                      </span>
                      <mat-select
                        class="form-control w-25 mt-2"
                        formControlName="isContactEDC"
                        [required]="
                          i?.isRequired &&
                          edcForm.get('isContactAcceptance')?.value
                        "
                        placeholder="{{ 'Select Status' | translate }}"
                      >
                        <mat-option [value]="false">{{
                          "Not ready" | translate
                        }}</mat-option>
                        <mat-option [value]="true">
                          {{ "Ready to use" | translate }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngFor="let i of formManagement">
                <ng-container
                  *ngIf="
                    i?.fieldName === 'Contactless Acceptance' &&
                    i?.isShow &&
                    edcForm.get('isContactAcceptance')?.value
                  "
                >
                  <ng-container *ngFor="let j of formManagement">
                    <ng-container
                      *ngIf="j?.fieldName === 'Issue Remark' && j?.isShow"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <span *ngIf="j?.isRequired">
                            {{ "Contactless Issue Remark" | translate }}*
                          </span>
                          <span *ngIf="!j?.isRequired">
                            {{ "Contactless Issue Remark" | translate }}
                          </span>
                          <textarea
                            formControlName="contactIssue"
                            placeholder="{{ 'Issue remark...' | translate }}"
                            style="height: 200px !important"
                            rows="10"
                            class="form-control mt-2"
                            [required]="
                              j?.isRequired &&
                              edcForm.get('isContactAcceptance')?.value
                            "
                          ></textarea>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <ng-container *ngFor="let i of formManagement">
                <ng-container
                  *ngIf="
                    i?.fieldName === 'Contactless Acceptance' &&
                    i?.isShow &&
                    edcForm.get('isContactAcceptance')?.value
                  "
                >
                  <div class="row">
                    <div
                      class="col-md-12 mt-4 d-flex justify-content-between align-items-center"
                    >
                      <span *ngIf="i?.isRequired">
                        {{
                          "EDC Placement (for Contactless only)" | translate
                        }}*
                      </span>
                      <span *ngIf="!i?.isRequired">
                        {{ "EDC Placement (for Contactless only)" | translate }}
                      </span>
                      <mat-select
                        class="form-control w-25 mt-2"
                        formControlName="contactPlacement"
                        [required]="
                          i?.isRequired &&
                          edcForm.get('isContactAcceptance')?.value
                        "
                        placeholder="{{ 'Select EDC placement' | translate }}"
                      >
                        <mat-option value="Facing to customer">{{
                          "Facing to customer" | translate
                        }}</mat-option>
                        <mat-option
                          value="On counter but not facing to customer"
                        >
                          {{
                            "On counter but not facing to customer" | translate
                          }}
                        </mat-option>
                        <mat-option value="Keep in counter">
                          {{ "Keep in counter" | translate }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 mt-2 d-flex justify-content-between align-items-center"
                    >
                      <span *ngIf="i?.isRequired">
                        {{
                          "Staff performance (for Contactless only)"
                            | translate
                        }}*
                      </span>
                      <span *ngIf="!i?.isRequired">
                        {{
                          "Staff performance (for Contactless only)" | translate
                        }}
                      </span>
                      <mat-select
                        class="form-control w-25 mt-2"
                        formControlName="staffPerformance"
                        [required]="
                          i?.isRequired &&
                          edcForm.get('isContactAcceptance')?.value
                        "
                        placeholder="{{
                          'Select Staff Performance' | translate
                        }}"
                      >
                        <mat-option value="Welled perform">
                          {{ "Welled perform" | translate }}</mat-option
                        >
                        <mat-option value="Don't know how to process">
                          {{ "Don't know how to process" | translate }}
                        </mat-option>
                        <mat-option value="Don't know about Contactless">
                          {{ "Don't know about Contactless" | translate }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 mt-2 d-flex justify-content-between align-items-center"
                    >
                      <span *ngIf="i?.isRequired">
                        {{
                          "Contactless training requirement at the merchant"
                            | translate
                        }}*
                      </span>
                      <span *ngIf="!i?.isRequired">
                        {{
                          "Contactless training requirement at the merchant"
                            | translate
                        }}
                      </span>
                      <mat-select
                        class="form-control w-25 mt-2"
                        formControlName="isContactlessTraining"
                        [required]="
                          i?.isRequired &&
                          edcForm.get('isContactAcceptance')?.value
                        "
                        placeholder="{{
                          'Select Training Requirement' | translate
                        }}"
                      >
                        <mat-option [value]="false">
                          {{ "No" | translate }}
                        </mat-option>
                        <mat-option [value]="true">
                          {{ "Yes" | translate }}
                        </mat-option>
                      </mat-select>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container></ng-container
  >
</form>

<!-- Modal Confirm Add -->
<div
  class="modal fade"
  id="confirmAdd"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <span
              class="material-symbols-outlined"
              style="font-size: 100px; color: #388087"
            >
              error
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <span>
              {{ "Are you sure to add this merchant" | translate }}?
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-5 d-flex">
            <button
              class="btnsave w-100"
              (click)="acceptSubmit()"
              data-bs-dismiss="modal"
            >
              {{ "Yes" | translate }}
            </button>
            <button class="btncancel w-100 ms-1" data-bs-dismiss="modal">
              {{ "No" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  id="btnsuccess"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#success"
>
  Launch demo modal
</button>

<!-- Modal success -->
<div class="modal fade" data-bs-backdrop="static" id="success" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 text-center">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>
        <div class="col-md-12 text-center">
          {{ "Merchant has been added" | translate }}
        </div>
        <div class="col-md-12 mt-4">
          <button
            data-bs-dismiss="modal"
            class="btnsave w-100"
            (click)="back()"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

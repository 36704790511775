<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 d-flex justify-content-between">
      <div class="text-title d-flex align-items-center">
        {{ "Admin management" | translate }}
      </div>
      <button class="btncancel" (click)="Inviteadmin()">
        <span class="material-symbols-outlined"> outgoing_mail </span>
        {{ "Invite Admin" | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-4 d-flex align-items-center justify-content-end">
      <div class="input-group w-25">
        <input
          type="text"
          class="form-control"
          placeholder="{{ 'Search' | translate }}"
          aria-label="Username"
          aria-describedby="basic-addon1"
          [(ngModel)]="keyword"
          (keyup.enter)="searchByKeyword()"
        />
        <span
          id="basic-addon1"
          class="material-symbols-outlined input-group-text"
          (click)="searchByKeyword()"
          style="cursor: pointer"
        >
          search
        </span>
      </div>
      <button
        class="btncancel ms-3"
        style="padding: 15px 15px !important"
        (click)="sortOrder()"
      >
        <span class="material-symbols-outlined"> swap_vert </span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 mt-5">
      <table class="w-100">
        <thead>
          <tr>
            <th class="text-center">
              {{ "Admin name" | translate }}
            </th>
            <th>
              {{ "Role" | translate }}
            </th>
            <th>
              {{ "Date added" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let i of data
                | paginate
                  : {
                      itemsPerPage: row,
                      currentPage: p,
                      totalItems: totalCount
                    }
            "
          >
            <tr>
              <td class="d-flex align-items-center">
                <span class="material-symbols-outlined me-2"> badge </span>
                <span class="name" (click)="Detail(i?._id)">{{ i?.name }}</span>
              </td>
              <td>{{ i?.roles }}</td>
              <td>{{ i?.createdAt | date : "dd/MM/yyyy" }}</td>
              <td class="d-flex align-items-center justify-content-end">
                <span
                  class="material-symbols-outlined ms-4 curser"
                  (click)="Detail(i?._id)"
                >
                  arrow_forward_ios
                </span>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="data?.length == 0">
            <tr>
              <td colspan="4" class="text-center">
                {{ "No data" | translate }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div
      class="col-md-12 d-flex align-items-center justify-content-between"
      *ngIf="totalCount > 10"
    >
      <div>
        <pagination-controls
          (pageChange)="pageChanged($event)"
          [responsive]="true"
          previousLabel=""
          nextLabel=""
        ></pagination-controls>
      </div>
      <select
        [(ngModel)]="row"
        class="form-select w-auto"
        (change)="getAlllistadmin(false)"
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  </div>
</div>

import { T } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { ServiceService } from 'src/app/service/service.service';
import { Roles } from 'src/app/shares/roles.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  password: string = '';
  email: string = '';
  flagLogin: any = true;
  data: any = {};
  alert: any = '';
  isLoading = false;
  constructor(
    private service: ServiceService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.isAuthenticated().then((result) => {
      if (result) {
        const role = this.authService.getRole();
        if (role === Roles.DEPLOYMENT) {
          this.router.navigate(['/deployment/database']);
        } else {
          this.router.navigate(['/database']);
        }
      }
    });
  }

  Login() {
    this.isLoading = true;
    const body: any = {
      email: this.email,
      password: this.password,
    };

    // this.data = this.service
    //   .PostPathBody('/auth/signin', body)
    //   .then(async (result) => {
    //     localStorage.setItem('role', JSON.stringify(result));
    //     this.alert = '';
    //     const role = this.authService.getRole();
    //     await this.getProfile();
    //     if (role === Roles.DEPLOYMENT) {
    //       this.router.navigate(['/deployment/database']);
    //     } else {
    //       this.router.navigate(['/database']);
    //     }
    //   });

    this.authService.login(body).subscribe({
      next: async (result) => {
        localStorage.setItem('role', JSON.stringify(result));
      },
      error: (error) => {
        this.isLoading = false;
        this.alert = error;
      },
      complete: async () => {
        this.isLoading = false;
        this.alert = '';
        const role = this.authService.getRole();
        await this.getProfile();
        if (role === Roles.DEPLOYMENT) {
          this.router.navigate(['/deployment/database']);
        } else {
          this.router.navigate(['/database']);
        }
      },
    });
  }

  async getProfile() {
    this.alert = '';
    let proFile: any = await this.service.GetTokenPathParams('/user/myProfile');
    if (proFile?.error) {
      this.alert = proFile?.error;
      return;
    }
    localStorage.setItem('proFile', JSON.stringify(proFile));
  }

  async forgotPassword() {
    let body: any = {
      email: this.email,
    };
    let proFile: any = await this.service.PostPathBody(
      '/auth/forgotPassword',
      body
    );
    if (proFile.msg == 'Success') {
      document.getElementById('btnsuccess')?.click();
    }
    this.alert = proFile.error;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { ServiceService } from 'src/app/service/service.service';
import { CanComponentDeactivate } from 'src/app/service/candeactivate.guard';
import { Observable, map, startWith } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DiscardChangeComponent } from 'src/app/components/discard-change/discard-change.component';

@Component({
  selector: 'app-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.css'],
})
export class FormManagementComponent implements OnInit, CanComponentDeactivate {
  constructor(
    private service: ServiceService,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {}
  filteredOptionProvince?: Observable<string[]>;
  filteredOptionDistrict?: Observable<string[]>;
  Formdata!: FormGroup;
  countries: any;
  data: any[] = [];
  totalCountry: any;
  countryId: any;
  country: any;
  password: any = '';
  currentData: any[] = [];
  isSuccess: boolean = false;

  choiceLocation: any = {};

  isChanged = false;
  isLoading = false;

  choice: any = {
    choiceCategory: '',
    choiceAcceptance: '',
    choicePay: '',
    choiceWallet: '',
    choiceBank: '',
    choiceGeneralMat: '',
    choiceCustomizelMat: '',
    choiceCompetitor: '',
  };
  ngOnInit(): void {
    this.Formdata = this.fb.group({
      district: '',
      province: '',
    });
    this.getCountry();
    this.Getprovince();
    this.Getdistrict();
    this.filteredOptionProvince = this.Formdata.get(
      'province'
    )?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterProvince(value || ''))
    );
    this.filteredOptionDistrict = this.Formdata.get(
      'district'
    )?.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterDistrict(value || ''))
    );
  }
  private _filterProvince(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filterDistrict(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.district?.filter((option: any) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  async getCountry() {
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/filter?search=&skip=1&limit=10'
    );
    this.totalCountry = data.totalData;
    this.reorderCountries();

    for (let item of this.totalCountry) {
      if (item.country === 'Thailand') {
        this.countryId = item._id;
        this.country = item.country;
      }
    }
    await this.getFormmanagement(this.countryId);
  }

  reorderCountries() {
    this.totalCountry?.sort((a: any, b: any) =>
      a.country === 'Thailand' ? -1 : b.country === 'Thailand' ? 1 : 0
    );
  }

  async getFormId(event: any) {
    this.isChanged = true;
    const formId = event;
    this.countryId = formId;
    const getCountry = await this.totalCountry.filter(
      (item: any) => item._id === event
    );

    this.country = getCountry[0].country;
    this.getFormmanagement(formId);
  }

  async getFormmanagement(formId: any) {
    this.isLoading = true;
    // let data: any = await this.service.GetTokenPathParams('/backoffice/form/' + this.formId)
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/' + formId
    );

    this.data = data.formElement;

    console.log(this.data);
  }

  message: any = '';
  async onSubmit() {
    console.log(this.data);

    let body = {
      password: this.password,
      data: this.data,
    };
    let data: any = await this.service.PutTokenPathBody(
      '/backoffice/formElement',
      body
    );

    if (data.msg == 'Success') {
      this.isChanged = false;
      this.message = data.msg;
      this.isLoading = false;
    } else {
      if (data.error.message) {
        this.message = data.error.message;
        this.isLoading = false;
      } else {
        this.message = 'Something went wrong, please try again later!';
        this.isLoading = false;
      }
    }
  }

  refreshCurrentRoute() {
    // Use the Router service to navigate to the current route
    this.message = '';
    this.password = '';
    this.router.navigate([this.router.url]);
  }

  toggleIsShow(index: number, index2?: any) {
    this.isChanged = true;
    if (index >= 0 && index < this.data.length) {
      this.data[index].isShow = !this.data[index].isShow;
      if (this.data[index].isRequired === true) {
        this.data[index].isRequired = false;
      }
    }
    if (index2 >= 0) {
      this.data[index2].isShow = !this.data[index2].isShow;
      if (this.data[index2].isRequired === true) {
        this.data[index2].isRequired = false;
      }
    }
  }
  toggleIsRequired(event: any, index: number) {
    this.isChanged = true;
    this.data[index].isRequired = event;
  }

  toggleIsRequiredMap(event: any) {
    this.isChanged = true;
    if (this.data[4]?.isShow && this.data[5]?.isShow) {
      this.data[4].isRequired = event.target.checked;
      this.data[5].isRequired = event.target.checked;
    }
  }

  addDistrictProvince(event: any, subject: string) {
    if (subject === 'province') {
      this.choiceLocation.province = event;
    }
    if (subject === 'district') {
      this.choiceLocation.district = event;
    }
  }

  addchoices(
    index: number,
    subject: any,
    isLocation?: boolean,
    subjectString?: string
  ) {
    this.isChanged = true;
    if (isLocation) {
      if (JSON.stringify(subject) !== '{}') {
        const tempObj = { ...subject };

        this.data[index]['choices'].push(tempObj);

        this.choiceLocation = {};
        this.Formdata.get('province')?.setValue('');
        this.Formdata.get('district')?.setValue('');
      }
    } else {
      if (subject !== '') {
        this.data[index]['choices'].push(subject);
      }
      this.choice[`${subjectString}`] = '';
    }
  }
  deleteChoices(indexData: number, indexArray: number) {
    this.isChanged = true;
    this.data[indexData]['choices'].splice(indexArray, 1);
  }
  arraysAreEqual(arr1: any[], arr2: any[], propertyName: string) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Iterate through each element and compare the specified property
    for (let i = 0; i < arr1.length; i++) {
      console.log(arr1[i][propertyName] === arr2[i][propertyName]);

      if (!(arr1[i][propertyName] === arr2[i][propertyName])) {
        return false;
      }
    }

    // If all elements are equal, return true
    return true;
  }

  async Getprovince() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.countries = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }
  district: any;
  async Getdistrict() {
    let url: any =
      'https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_amphure.json';
    this.http.get(url).subscribe((res) => {
      if (Array.isArray(res)) {
        this.district = res.map((item: any) => {
          return item.name_th;
        });
      }
    });
  }
  openDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(DiscardChangeComponent, {
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.isChanged) {
      return this.openDialog();
    } else {
      return true;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPropertyName',
})
export class FormatPropertyNamePipe implements PipeTransform {
  transform(name: string): string {
    switch (name) {
      case 'welcomPhoto':
        return 'Welcome Sticker';
      case 'ocSignPhoto':
        return 'Open/close sign';
      case 'cashierSignPhoto':
        return 'Cashier sign';
      case 'signPadPhoto':
        return 'Sign Pad';
      case 'billFolderPhoto':
        return 'Bill folder';
      case 'reservedSignPhoto':
        return 'Reserved sign';
      case 'conciergePhoto':
        return 'Concierge';
      case 'cWelcomPhoto':
        return 'Contactless Welcome Sticker';
      case 'ccSignPhoto':
        return 'Contactless Cashier sign';
      case 'penStandPhoto':
        return 'Pen Stand';
      case 'cdStickerPhoto':
        return 'Contactless Door Sticker';
      case 'imageMigrate':
        return 'Migrate Photo';
      default:
        return name; // Return null for unmatched keys
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ServiceService } from '../service/service.service';
import { LanguageService } from '../service/language.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { StorageService } from '../service/storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  isAuthenticate?: boolean;
  role: any;
  user: any;
  data: any = {};
  roleUI: any;
  sidebar: any;
  translate: TranslateService;
  subscription?: Subscription;
  test?: boolean;
  constructor(
    translate: TranslateService,
    private service: ServiceService,
    private langChange: LanguageService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.translate = translate;
  }
  ngOnInit() {
    let x: any = localStorage.getItem('lang');
    if (!x) {
      localStorage.setItem('lang', 'en');
      this.translate.use('en');
    } else {
      let x: any = localStorage.getItem('lang');
      this.translate.use(x);
    }

    let role: any = localStorage.getItem('role');
    let user: any = localStorage.getItem('proFile');
    this.role = JSON.parse(role);
    this.data = JSON.parse(user);

    this.storageService.changes.subscribe((profile) => {
      const value = profile.value;
      this.data.name = value.name;
      this.data.phoneNumber = value.phoneNumber;
    });

    if (this.role.roles[0] === 'SUPERADMIN') {
      this.roleUI = 'Super Admin';
    } else if (this.role.roles[0] === 'ADMIN') {
      this.roleUI = 'Admin';
    } else if (this.role.roles[0] === 'EXTERNAL') {
      this.roleUI = 'External Admin';
    } else if (this.role.roles[0] === 'DEPLOYMENT') {
      this.roleUI = 'Deployment team';
    }
  }

  async Logout() {
    let logout: any = await this.service.GetTokenPathParams('/auth/logout');

    localStorage.clear();

    window.location.replace('/login');
  }
  changeLanguage(lang: string) {
    localStorage.setItem('lang', lang);
    this.langChange.setLanguage(lang);
    this.translate.use(lang);
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }
}

import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DragndropserviceService } from '../dragndropservice.service';
import { T } from '@angular/cdk/keycodes';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-dragndropinput',
  templateUrl: './dragndropinput.component.html',
  styleUrls: ['./dragndropinput.component.css'],
})
export class DragndropinputComponent implements OnInit, OnChanges {
  dragAreaClass: any = 'dragarea';
  files: File[] = [];
  previews: any = [];
  selectedFiles: any = [];
  isInValid: boolean = false;

  @Input() data: any;
  @Input() fieldName: any;
  @Input() isTouched: any;
  @Input() isEdit: any;
  @Input() edcNormalImg: any;
  @Input() contactImg: any;
  @Input() competitorImg: any;
  @Input() materialImg: any;
  @Input() customizeMaterialImg: any;

  @ViewChild('fileissueremark', { static: false })
  fileInputRef!: ElementRef;
  constructor(
    private dragndropService: DragndropserviceService,
    private service: ServiceService
  ) {}
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.files.length === 0 &&
      changes['isTouched'].currentValue &&
      this.data.isRequired &&
      !this.isEdit
    ) {
      this.isInValid = true;
    }
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragenter', ['$event']) onDragEnter(event: any) {
    this.dragAreaClass = 'droparea';
    event.preventDefault();
  }
  @HostListener('dragend', ['$event']) onDragEnd(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
  }
  @HostListener('drop', ['$event']) onDrop(event: any) {
    this.dragAreaClass = 'dragarea';
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.files.push(event.dataTransfer.files[0]);
      if (this.fieldName === 'Normal EDC') {
        this.dragndropService.edcNormalPhotoUpdated.emit(this.files);
      } else if (this.fieldName === 'Contactless Acceptance') {
        this.dragndropService.contactPhotoPhotoUpdated.emit(this.files);
      } else if (this.data.fieldName === 'Competitor Photo') {
        this.dragndropService.CompetitionPhotoUpdated.emit(this.files);
      } else if (this.data.fieldName === 'General Material') {
        this.dragndropService.materialPhotoPhotoUpdated.emit(this.files);
      } else if (this.data.fieldName === 'Customize Material') {
        this.dragndropService.customizedMaterialPhotoPhotoUpdated.emit(
          this.files
        );
      }
      this.selectFiles(event);
    }
  }
  clearFileInput() {
    if (this.fileInputRef) {
      this.fileInputRef.nativeElement.value = '';
    }
  }

  onFileChange(event: any) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.files.push(event.target.files[i]);
    }

    if (this.data.isRequired && this.files.length === 0 && !this.isEdit) {
      this.isInValid = true;
    } else {
      this.isInValid = false;
    }
    if (this.fieldName === 'Normal EDC') {
      this.dragndropService.edcNormalPhotoUpdated.emit(this.files);
    } else if (this.fieldName === 'Contactless Acceptance') {
      this.dragndropService.contactPhotoPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'Competitor Photo') {
      this.dragndropService.CompetitionPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'General Material') {
      this.dragndropService.materialPhotoPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'Customize Material') {
      this.dragndropService.customizedMaterialPhotoPhotoUpdated.emit(
        this.files
      );
    }
    this.selectFiles(event);
    this.clearFileInput();
  }

  selectFiles(event: any): void {
    event.type == 'change'
      ? (this.selectedFiles = event.target.files)
      : (this.selectedFiles = event.dataTransfer.files);
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
      }
    }
    if (this.data.isRequired && this.files.length === 0 && !this.isEdit) {
      this.isInValid = true;
    } else {
      this.isInValid = false;
    }
  }

  deleteimage(index: any) {
    this.previews.splice(index, 1);
    this.files.splice(index, 1);

    if (this.data.isRequired && this.files.length === 0 && !this.isEdit) {
      this.isInValid = true;
    } else {
      this.isInValid = false;
    }
    if (this.fieldName === 'Normal EDC') {
      this.dragndropService.edcNormalPhotoUpdated.emit(this.files);
    } else if (this.fieldName === 'Contactless Acceptance') {
      this.dragndropService.contactPhotoPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'Competitor Photo') {
      this.dragndropService.CompetitionPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'General Material') {
      this.dragndropService.materialPhotoPhotoUpdated.emit(this.files);
    } else if (this.data.fieldName === 'Customize Material') {
      this.dragndropService.customizedMaterialPhotoPhotoUpdated.emit(
        this.files
      );
    }
  }
  deleteimageDatabase(index: any) {
    if (this.edcNormalImg) {
      if (
        confirm('Are you sure you want to delete this image from database?')
      ) {
        const id = this.edcNormalImg.images[index]._id;
        const res = this.service.DeleteTokenPath(
          '/backoffice/merchant/image/' + id
        );
        console.log(res);

        this.edcNormalImg.images.splice(index, 1);
      }
    }
    if (this.contactImg) {
      if (
        confirm('Are you sure you want to delete this image from database?')
      ) {
        const id = this.contactImg.images[index]._id;
        const res = this.service.DeleteTokenPath(
          '/backoffice/merchant/image/' + id
        );
        this.contactImg.images.splice(index, 1);
      }
    }
    if (this.competitorImg) {
      if (
        confirm('Are you sure you want to delete this image from database?')
      ) {
        const id = this.competitorImg.images[index]._id;
        const res = this.service.DeleteTokenPath(
          '/backoffice/merchant/image/' + id
        );
        this.contactImg.images.splice(index, 1);
      }
    }
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatabaseComponent } from './pages/database/database.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddMerchantComponent } from './pages/add-merchant/add-merchant.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViewOnMapComponent } from './pages/view-on-map/view-on-map.component';
import { DatabaseExportsComponent } from './pages/database-exports/database-exports.component';
import { FormManagementComponent } from './pages/form-management/form-management.component';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { AdminInfoComponent } from './pages/admin-info/admin-info.component';
import { InviteAdminComponent } from './pages/invite-admin/invite-admin.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DatabaseMaerchantInfoComponent } from './pages/database-maerchant-info/database-maerchant-info.component';
import { ActivityRecordComponent } from './pages/activity-record/activity-record.component';
import { DatabaseMerchntEditComponent } from './pages/database-merchnt-edit/database-merchnt-edit.component';
import { MatInputModule } from '@angular/material/input';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectModule } from '@angular/material/select';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NavbarComponent } from './navbar/navbar.component';
import { CustomInputComponent } from './pages/add-merchant/custom-input/custom-input.component';
import { DragndropinputComponent } from './shares/dragndrop/dragndropinput/dragndropinput.component';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSlideToggleModule,
  _MatSlideToggleRequiredValidatorModule,
} from '@angular/material/slide-toggle';
import { DragndropinputCustomComponent } from './shares/dragndrop/dragndropinput-custom/dragndropinput-custom.component';
import { FormatPropertyNamePipe } from './shares/format-property-name.pipe';
import { DeploymentComponent } from './pages/deployment/deployment.component';
import { DatabaseDeploymentComponent } from './pages/deployment/database-deployment/database-deployment.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DeploymentMerchantInfoComponent } from './pages/deployment/deployment-merchant-info/deployment-merchant-info.component';
import { DeploymentAddMerchantComponent } from './pages/deployment/deployment-add-merchant/deployment-add-merchant.component';
import { ChunkPipe } from './shares/chunk.pipe';
import { AuthInterceptor } from './service/auth.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { GalleryComponent } from './shares/gallery/gallery.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BackDialogComponent } from './components/back-dialog/back-dialog.component';
import { DiscardChangeComponent } from './components/discard-change/discard-change.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    DatabaseComponent,
    AddMerchantComponent,
    ViewOnMapComponent,
    DatabaseExportsComponent,
    FormManagementComponent,
    AdminManagementComponent,
    AdminInfoComponent,
    InviteAdminComponent,
    DatabaseMaerchantInfoComponent,
    ActivityRecordComponent,
    DatabaseMerchntEditComponent,
    EditUserComponent,
    ChangePasswordComponent,
    NavbarComponent,
    CustomInputComponent,
    DragndropinputComponent,
    DragndropinputCustomComponent,
    FormatPropertyNamePipe,
    DeploymentComponent,
    DatabaseDeploymentComponent,
    DeploymentMerchantInfoComponent,
    DeploymentAddMerchantComponent,
    ChunkPipe,
    GalleryComponent,
    BackDialogComponent,
    DiscardChangeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatMenuModule,
    MatListModule,
    HttpClientModule,
    NgApexchartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    GalleryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    MatNativeDateModule,
    MatSliderModule,
    MatAutocompleteModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    MatChipsModule,
    MatCheckboxModule,
    MatInputModule,
    NgxPaginationModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonModule,
    MatSlideToggleModule,
    InfiniteScrollModule,
    MatSnackBarModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

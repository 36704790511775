<!-- navnbar -->
<nav class="navbar sticky-top">
  <div class="box-custom">
    <a
      class="navbar-brand"
      routerLink="/deployment/database"
      (click)="goToTop()"
    >
      <h3>VTHG POS</h3>
      <p style="font-size: 12px">{{ roleUI }}</p>
    </a>
    <div>
      <div class="isDesktop">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <mat-icon class="hamMenu">menu</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    id="offcanvasNavbar"
    aria-labelledby="offcanvasNavbarLabel"
  >
    <div class="offcanvas-header">
      <h1 class="offcanvas-title" id="offcanvasNavbarLabel">
        {{ "Menu" | translate }}
      </h1>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="dropdown-profile">
        <div class="d-flex align-items-center justify-content-between p-3">
          <div class="d-flex align-items-center">
            <span class="material-symbols-outlined text-name"> badge </span>
            <span class="ps-2 pe-2 text-name">{{ data.name }}</span>
          </div>

          <span class="badge fw-lighter">{{ "Deployer" }}</span>
        </div>

        <div class="p-3 pt-0 d-flex flex-column gap-1">
          <label class="font-small fw-lighter">Email : {{ data.email }}</label>
          <label class="font-small fw-lighter"
            >Phone : {{ data.phoneNumber }}</label
          >
        </div>
      </div>
      <div class="nav-menu-btn">
        <div
          class="wrapper-nav"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          (click)="editUser()"
        >
          <span class="ps-2 pe-2 text-name">{{
            "Edit Profile" | translate
          }}</span>
          <span class="material-symbols-outlined"> arrow_forward_ios </span>
        </div>
        <div
          class="wrapper-nav"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          (click)="changePassword()"
        >
          <span class="ps-2 pe-2 text-name">{{
            "Change Password" | translate
          }}</span>
          <span class="material-symbols-outlined"> arrow_forward_ios </span>
        </div>
        <div
          class="wrapper-nav"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          (click)="Logout()"
        >
          <span class="ps-2 pe-2 text-name">{{ "Logout" | translate }}</span>
          <span class="material-symbols-outlined"> arrow_forward_ios </span>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <span class="pe-2 text-name d-flex align-items-center">{{
          "Change Language" | translate
        }}</span>
        <div class="btn-group">
          <button
            type="button"
            class="btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div>{{ "Language" | translate }}</div>
          </button>
          <ul class="dropdown-menu dropdown-menu-end dropdown-menu--start">
            <li class="text-center">
              <a (click)="changeLanguage('th')"> TH </a>
            </li>
            <li class="text-center mt-2">
              <a (click)="changeLanguage('en')"> EN </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="ps-1 mt-3">
        <footer class="footer fw-lighter">©2023 Vathanagul group.co.ltd</footer>
      </div>
    </div>
  </div>
</nav>

<!-- navbar -->
<section>
  <router-outlet></router-outlet>
</section>

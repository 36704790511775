import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';

import { Observable, catchError, map, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';

import { L } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MapType } from '@angular/compiler';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  url = environment.url;
  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService
  ) {}
  async PostPathBody(path: any, body: any) {
    let url = this.url + path;
    return new Promise((resolve, reject) => {
      this.http.post<any>(url, body).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }
  async PostTokenPathBody(path: any, body: any) {
    let url = this.url + path;
    // const header = new HttpHeaders({
    //   Authorization: 'Bearer ' + token['accessToken'],
    // });
    return new Promise((resolve, reject) => {
      this.http.post<any>(url, body).subscribe({
        next: (res) => {
          resolve(res);
          // this.RefreshToken()
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }

  async GetTokenPathParams(path: any) {
    let url = this.url + path;

    // const header = new HttpHeaders({
    //   Authorization: 'Bearer ' + token['accessToken'],
    // });

    return new Promise((resolve, reject) => {
      this.http.get<any>(url).subscribe({
        next: (res) => {
          resolve(res);
        },
        error: (error) => {
          resolve(error);

          // this.RefreshToken();
        },
      });
    });
  }

  downloadFile(path: any): Observable<any> {
    let url = this.url + path;

    // const header = new HttpHeaders({
    //   Authorization: 'Bearer ' + token['accessToken'],
    // });
    return this.http.get<Blob>(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json',
    });
  }

  async PatchTokenPathBody(path: any, body: any) {
    let url = this.url + path;

    // const header = new HttpHeaders({
    //   Authorization: 'Bearer ' + token['accessToken'],
    // });
    return new Promise((resolve, reject) => {
      this.http.patch<any>(url, body).subscribe({
        next: (res) => {
          if (!res) {
            resolve(false);
          }
          resolve(res);
          // this.RefreshToken()
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }
  async PutTokenPathBody(path: any, body: any) {
    let url = this.url + path;

    // const header = new HttpHeaders({
    //   Authorization: 'Bearer ' + token['accessToken'],
    // });
    return new Promise((resolve, reject) => {
      this.http.put<any>(url, body).subscribe({
        next: (res) => {
          resolve(res);
          // this.RefreshToken()
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }

  async DeleteTokenPathBody(path: any, body: any) {
    let url = this.url + path;

    return new Promise((resolve, reject) => {
      this.http.delete<any>(url, { body: body }).subscribe({
        next: (res) => {
          resolve(res);
          // this.RefreshToken()
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }
  async DeleteTokenPath(path: any) {
    let url = this.url + path;

    return new Promise((resolve, reject) => {
      this.http.delete<any>(url).subscribe({
        next: (res) => {
          resolve(res);
          // this.RefreshToken()
        },
        error: (error) => {
          resolve(error);
        },
      });
    });
  }

  RefreshToken(): Observable<any> {
    console.log('RefreshToken');

    let refreshToken = this.authService.getRefreshToken();

    let header = new HttpHeaders({
      Authorization: 'Bearer ' + refreshToken,
    });
    return this.http.get<any>(`${this.url}/auth/refresh`, { headers: header });
  }

  checkErrorAuth(data: any) {
    if (data.error.statusCode) {
      return (window.location.href = '');
    }
    return;
  }
}

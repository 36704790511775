import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as ApexCharts from 'apexcharts';
import { ServiceService } from 'src/app/service/service.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
} from 'ng-apexcharts';
import { HttpEventType } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

export type chartAcceptance = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
};
export type chartAcq = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
};
export type chartLocations = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
};
export type chartCategory = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  responsive: ApexResponsive[] | any;
  labels: any;
};
export type chartMaterialDeploy = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  translate: TranslateService;
  @ViewChild('chart') chart: ChartComponent | any;

  language: any;
  country: any;
  data: any = {};
  countryList: any;
  progress = 0;
  errMessage = '';
  isLoading = false;
  isPageLoading = false;
  isDone = false;
  maxValueCardAcceptance: number = 0;
  maxValueEdcBank: number = 0;
  maxValueCompetitor: number = 0;
  constructor(
    translate: TranslateService,
    private service: ServiceService,
    private renderer: Renderer2,
    private _snackBar: MatSnackBar
  ) {
    this.translate = translate;
  }

  get style() {
    return 'width:' + this.progress + '%';
  }

  public chartAcceptance: any = {
    series: [{ data: [], name: 'Card Acceptance' }],
    chart: {
      height: 350,
      type: 'bar',
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 200,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          hideOverflowingLabels: true,
          orientation: 'vertical',
        },
      },
    },
    xaxis: {
      categories: [],
    },
    colors: [this.getColorBasedOnValue.bind(this)],
  };
  public chartAcq: any = {
    series: [
      {
        data: [],
        name: 'EDC Bank',
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 200,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          hideOverflowingLabels: true,
          orientation: 'vertical',
        },
      },
    },
    xaxis: {
      categories: [],
    },
    colors: [this.getColorBasedOnValue.bind(this)],
  };
  public chartLocations: any = {
    series: [
      {
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: 'pie',
    },
    xaxis: {
      categories: [],
    },
    labels: [],
  };
  public chartCategory: any = {
    series: [
      {
        data: [],
      },
    ],
    chart: {
      height: 350,
      type: 'pie',
    },
    xaxis: {
      categories: [],
    },
    labels: [],
  };
  public chartMaterialDeploy: any = {
    series: [
      {
        data: [],
        name: 'Competitor',
      },
    ],
    chart: {
      height: 350,
      type: 'bar',
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 200,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          hideOverflowingLabels: true,
          orientation: 'vertical',
        },
      },
    },
    xaxis: {
      categories: [],
    },
    colors: [this.getColorBasedOnValue.bind(this)],
  };

  ngOnInit(): void {
    this.getCountry();
  }

  async getCountry() {
    this.isPageLoading = true;
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/filter'
    );
    this.countryList = data.totalData;

    let ready = data.totalData?.find(
      (item: any) => item.country === 'Thailand'
    );

    this.country = ready._id;
    if (this.country) {
      await this.getDashboardbyId();
    }
  }
  async getDashboardbyId() {
    this.isPageLoading = true;
    this.resetChart();
    const date = new Date();

    let day = date.getDate();

    let month = date.getMonth();

    let year = date.getFullYear();

    const today = `${year}-${month + 1}-${day}`;

    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/dashboard/' + this.country + '?date=' + today
    );

    this.data = data;

    await this.getChartacceptance();
    await this.getChartAcq();
    await this.getChartLocations();
    await this.getchartCategory();
    await this.getchartComp();
    this.isPageLoading = false;
  }
  resetChart() {
    this.chartAcceptance.series[0].data = [];
    this.chartAcceptance.xaxis.categories = [];
    this.chartAcq.series[0].data = [];
    this.chartAcq.xaxis.categories = [];
    this.chartLocations.series = [];
    this.chartLocations.labels = [];
    this.chartCategory.series = [];
    this.chartCategory.labels = [];
    this.chartMaterialDeploy.series[0].data = [];
    this.chartMaterialDeploy.xaxis.categories = [];
    this.maxValueCardAcceptance = 0;
    this.maxValueCompetitor = 0;
    this.maxValueEdcBank = 0;
  }

  async getChartacceptance() {
    this.chartAcceptance.series[0].data = [];
    this.chartAcceptance.xaxis.categories = [];
    let Acq: any = this.data.dataGraph.totalCard;
    for (let i of Acq) {
      this.chartAcceptance.series[0].data?.push(i.count);
      this.chartAcceptance.xaxis.categories?.push(i.name);
    }
  }
  async getChartAcq() {
    this.chartAcq.series[0].data = [];
    this.chartAcq.xaxis.categories = [];
    let Acq: any = this.data.dataGraph.totalAcq;

    for (let i of Acq) {
      this.chartAcq.series[0].data?.push(i.count);
      this.chartAcq.xaxis.categories?.push(i.name);
    }
  }
  async getChartLocations() {
    this.chartLocations.series = [];
    this.chartLocations.labels = [];
    let Acq: any = this.data.dataGraph.totalLoc;

    for (let i of Acq) {
      this.chartLocations.series?.push(i.count);
      this.chartLocations.labels?.push(i.name);
    }
  }
  async getchartCategory() {
    this.chartCategory.series = [];
    this.chartCategory.labels = [];
    let Acq: any = this.data.dataGraph.totalCate;
    for (let i of Acq) {
      this.chartCategory.series?.push(i.count);
      this.chartCategory.labels?.push(i.name);
    }
  }
  async getchartComp() {
    this.chartMaterialDeploy.series[0].data = [];
    this.chartMaterialDeploy.xaxis.categories = [];
    let Acq: any = this.data.dataGraph.totalComp;
    for (let i of Acq) {
      this.chartMaterialDeploy.series[0].data?.push(i.count);
      this.chartMaterialDeploy.xaxis.categories?.push(i.name);
    }
  }

  downloadFile() {
    this.progress = 0;
    this.errMessage = '';
    this.isLoading = true;
    this.isDone = false;
    const date = new Date();

    let day = date.getDate();

    let month = date.getMonth();

    let year = date.getFullYear();

    const today = `${year}-${month + 1}-${day}`;
    let data: any = this.service
      .downloadFile(
        '/backoffice/dashboard/export/' +
          this.country +
          '?date=' +
          today +
          '&isPhoto=' +
          false
      )
      .subscribe({
        next: (event: any) => {
          if (event.type === HttpEventType.DownloadProgress) {
            this.handleDownloadProgress(event);
          } else if (event.type === HttpEventType.Response) {
            this.handleHttpResponse(event);
          }
        },
        error: (err: any) => {
          console.log(err);
          this.errMessage = 'Something went wrong, please try again later!';
          this.isDone = true;
          this.isLoading = false;
        },
        complete: () => {
          this.isDone = true;
          this.errMessage = '';
          this.isLoading = false;
        },
      });
  }

  private handleDownloadProgress(event: any): void {
    this.progress = Math.round((100 * event.loaded) / event.total);
    console.log(this.progress);
  }

  private handleHttpResponse(event: any): void {
    const file: Blob = event.body as Blob;
    this.downloadFileFunc(file);
  }

  private downloadFileFunc(file: Blob): void {
    const downloadLink = this.renderer.createElement('a');
    this.renderer.setProperty(
      downloadLink,
      'href',
      window.URL.createObjectURL(file)
    );
    this.renderer.setProperty(
      downloadLink,
      'download',
      'POSM_Report_Dashboard'
    ); // Set your desired filename here
    downloadLink.click();
  }

  getColorBasedOnValue({ value, seriesIndex, dataPointIndex, w }: any) {
    const seriesName = w.config.series[0].name;

    const maxValue = this.findMaxValue(value, seriesName);
    const percentage = (value / maxValue) * 100;

    if (percentage > 80) {
      return '#177EBE'; // Blue for values > 80% of max
    } else if (percentage > 60) {
      return '#66BB6A'; // Yellow for values > 60% of max
    } else if (percentage > 40) {
      return '#FFA500'; // Green for values > 40% of max
    } else {
      return '#D9534F'; // Red for values <= 40% of max
    }
  }

  findMaxValue(newValue: number, name: string) {
    if (name === 'Card Acceptance') {
      if (newValue > this.maxValueCardAcceptance) {
        this.maxValueCardAcceptance = newValue;
      }
      return this.maxValueCardAcceptance;
    } else if (name === 'EDC Bank') {
      if (newValue > this.maxValueEdcBank) {
        this.maxValueEdcBank = newValue;
      }
      return this.maxValueEdcBank;
    } else if (name === 'Competitor') {
      if (newValue > this.maxValueCompetitor) {
        this.maxValueCompetitor = newValue;
      }
      return this.maxValueCompetitor;
    } else {
      return newValue;
    }
  }
  openSnackBar() {
    this._snackBar.open('Coming soon..', 'X', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }
}

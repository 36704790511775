<div
  id="viewport"
  [ngClass]="
    role['roles'][0] === 'SUPERADMIN' || role['roles'][0] === 'ADMIN'
      ? ''
      : 'user'
  "
>
  <!-- topbar -->
  <div id="topbar">
    <nav class="navbar navbar-expand-lg d-flex justify-content-between w-100">
      <div class="container-fluid p-0 ps-4 pe-4">
        <a class="navbar-brand text-light w-25" routerLink="/database">
          <h3>VTHG POS System</h3>
          <p style="font-size: 12px">For {{ roleUI }}</p>
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- <div class="input-group w-50">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'Search' | translate }}"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
            <span
              id="basic-addon1"
              class="material-symbols-outlined input-group-text"
            >
              search
            </span>
          </div> -->

          <div class="col d-flex align-items-center justify-content-end">
            <button
              class="language pe-2"
              mat-button
              [matMenuTriggerFor]="language"
            >
              <div [translate]="'Language'"></div>
            </button>
            <mat-menu #language="matMenu">
              <button (click)="changeLanguage('th')" mat-menu-item translate>
                TH
              </button>
              <button (click)="changeLanguage('en')" mat-menu-item>EN</button>
            </mat-menu>
            <div class="badge bg-dark">
              <button class="profile" mat-button [matMenuTriggerFor]="menu">
                <span class="material-symbols-outlined"> badge </span>
                <span class="ps-2 pe-2">{{ data.name }}</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <div class="dropdown-profile">
                <div class="d-flex align-items-center p-3">
                  <span class="material-symbols-outlined text-name">
                    badge
                  </span>
                  <span class="ps-2 pe-2 text-name">{{ data.name }}</span>
                </div>
                <div class="p-3 pt-0">
                  <span class="badge bg-dark">{{ data.roles }}</span>
                </div>
                <div class="p-3 pt-0">
                  <label class="fs-6 fw-lighter"
                    >Email : {{ data.email }}</label
                  >
                  <label class="fs-6 fw-lighter"
                    >Phone : {{ data.phoneNumber }}</label
                  >
                </div>
              </div>
              <button mat-menu-item routerLink="/edit-user">
                Edit Profile
              </button>
              <button mat-menu-item (click)="changePassword()">
                Change password
              </button>
              <button (click)="Logout()" mat-menu-item>Logout</button>
            </mat-menu>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!-- sidebar -->

  <div
    id="sidebar"
    *ngIf="
      this.role['roles'][0] === 'SUPERADMIN' ||
      this.role['roles'][0] === 'ADMIN'
    "
  >
    <ul class="nav">
      <li>
        <a routerLink="/dashboard" routerLinkActive="active">
          <span class="material-symbols-outlined me-3"> leaderboard </span>
          <div [translate]="'Dashboard'"></div>
        </a>
      </li>
      <li>
        <a routerLink="/database" routerLinkActive="active">
          <span class="material-symbols-outlined me-3"> description </span>
          <div [translate]="'Database'"></div>
        </a>
      </li>
      <li>
        <a routerLink="/form-management" routerLinkActive="active">
          <span class="material-symbols-outlined me-3"> assignment </span>
          <div [translate]="'Form management'"></div>
        </a>
      </li>
      <li *ngIf="role['roles'][0] === 'SUPERADMIN'">
        <a routerLink="/admin-management" routerLinkActive="active">
          <span class="material-symbols-outlined me-3"> badge </span>
          <div [translate]="'Admin management'"></div>
        </a>
      </li>
      <li *ngIf="role['roles'][0] === 'SUPERADMIN'">
        <a routerLink="/activity-record" routerLinkActive="active">
          <span class="material-symbols-outlined me-3"> history </span>
          <div [translate]="'Activity record'"></div>
        </a>
      </li>
    </ul>
  </div>

  <div id="content">
    <router-outlet></router-outlet>
    <div class="pb-5 pt-5">
      <footer>©2023 Vathanagul group.co.ltd</footer>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { ActivatedRoute, RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DatabaseComponent } from './pages/database/database.component';
import { AddMerchantComponent } from './pages/add-merchant/add-merchant.component';
import { ViewOnMapComponent } from './pages/view-on-map/view-on-map.component';
import { DatabaseExportsComponent } from './pages/database-exports/database-exports.component';
import { FormManagementComponent } from './pages/form-management/form-management.component';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { AdminInfoComponent } from './pages/admin-info/admin-info.component';
import { InviteAdminComponent } from './pages/invite-admin/invite-admin.component';
import { DatabaseMaerchantInfoComponent } from './pages/database-maerchant-info/database-maerchant-info.component';
import { ActivityRecordComponent } from './pages/activity-record/activity-record.component';
import { DatabaseMerchntEditComponent } from './pages/database-merchnt-edit/database-merchnt-edit.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { NavbarComponent } from './navbar/navbar.component';
import { IsAuthenGuard } from './service/is-authen.guard';
import { Roles } from './shares/roles.enum';
import { RoleGuard } from './service/role.guard';
import { DeploymentComponent } from './pages/deployment/deployment.component';
import { DatabaseDeploymentComponent } from './pages/deployment/database-deployment/database-deployment.component';
import { DeploymentMerchantInfoComponent } from './pages/deployment/deployment-merchant-info/deployment-merchant-info.component';
import { DeploymentAddMerchantComponent } from './pages/deployment/deployment-add-merchant/deployment-add-merchant.component';
import { CandeactivateGuard } from './service/candeactivate.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: NavbarComponent,
    canActivate: [IsAuthenGuard],
    canActivateChild: [IsAuthenGuard],

    children: [
      {
        path: 'dashboard',
        canActivate: [RoleGuard],
        data: { requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN] },
        component: DashboardComponent,
      },
      {
        path: 'database',
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EXTERNAL],
        },
        component: DatabaseComponent,
      },
      {
        path: '',
        redirectTo: '/database',
        pathMatch: 'full',
      },
      {
        path: 'database-exports',
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EXTERNAL],
        },
        component: DatabaseExportsComponent,
      },
      {
        path: 'add-merchant',
        canActivate: [RoleGuard],
        canDeactivate: [CandeactivateGuard],
        data: {
          requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN],
        },
        component: AddMerchantComponent,
      },
      { path: 'view-on-map', component: ViewOnMapComponent },
      {
        path: 'form-management',
        canActivate: [RoleGuard],
        canDeactivate: [CandeactivateGuard],
        data: { requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN] },
        component: FormManagementComponent,
      },
      {
        path: 'admin-management',
        canActivate: [RoleGuard],
        data: { requiredRoles: [Roles.SUPERADMIN] },
        component: AdminManagementComponent,
      },
      {
        path: 'admin-info/:id',
        canActivate: [RoleGuard],
        data: { requiredRoles: [Roles.SUPERADMIN] },
        component: AdminInfoComponent,
      },
      {
        path: 'invite-admin',
        canActivate: [RoleGuard],
        canDeactivate: [CandeactivateGuard],
        data: { requiredRoles: [Roles.SUPERADMIN] },
        component: InviteAdminComponent,
      },
      {
        path: 'database-marchant-info/:id',
        canActivate: [RoleGuard],
        data: {
          requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EXTERNAL],
        },
        component: DatabaseMaerchantInfoComponent,
      },
      {
        path: 'activity-record',
        canActivate: [RoleGuard],
        data: { requiredRoles: [Roles.SUPERADMIN] },
        component: ActivityRecordComponent,
      },
      {
        path: 'database-marchant-edit/:id',
        canActivate: [RoleGuard],
        canDeactivate: [CandeactivateGuard],
        data: {
          requiredRoles: [Roles.SUPERADMIN, Roles.ADMIN],
        },
        component: DatabaseMerchntEditComponent,
      },
      {
        path: 'edit-user',

        component: EditUserComponent,
      },
      { path: 'change-password', component: ChangePasswordComponent },
    ],
  },
  {
    path: 'deployment',
    component: DeploymentComponent,
    canActivate: [IsAuthenGuard],
    canActivateChild: [IsAuthenGuard],
    children: [
      { path: 'change-password', component: ChangePasswordComponent },
      {
        path: 'edit-user',
        component: EditUserComponent,
      },
      {
        path: 'database',
        component: DatabaseDeploymentComponent,
      },
      {
        path: '',
        redirectTo: 'database',
        pathMatch: 'full',
      },
      {
        path: 'view-on-map',
        component: ViewOnMapComponent,
      },
      {
        path: 'database-marchant-info/:id',
        component: DeploymentMerchantInfoComponent,
      },
      {
        path: 'add-merchant',
        canDeactivate: [CandeactivateGuard],
        component: DeploymentAddMerchantComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

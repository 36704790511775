import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ServiceService } from 'src/app/service/service.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  data: any = {};
  currentPassword = new FormControl(null, Validators.required);
  Password = new FormControl(null, Validators.minLength(8));
  newPassword = new FormControl(null, Validators.required);
  errMessage = '';
  isLoading = false;
  constructor(
    private service: ServiceService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  async changePassword() {
    this.isLoading = false;
    this.errMessage = '';
    if (
      this.currentPassword.invalid ||
      this.Password.invalid ||
      this.newPassword.invalid
    ) {
      this.isLoading = false;
      this.currentPassword.markAllAsTouched();
      const currentPassword: any = document.querySelector(
        '.ng-invalid[formControl]'
      );
      this.Password.markAllAsTouched();
      const Password: any = document.querySelector('.ng-invalid[formControl]');
      this.newPassword.markAllAsTouched();
      const newPassword: any = document.querySelector(
        '.ng-invalid[formControl]'
      );
    } else {
      this.isLoading = true;
      if (this.Password.value === this.newPassword.value) {
        this.data.password = this.newPassword.value;
        this.data.currentPassword = this.currentPassword.value;
        let body = {
          currentPassword: this.data.currentPassword,
          password: this.data.password,
        };
        let data: any = await this.service.PatchTokenPathBody(
          '/auth/changePassword/normal',
          body
        );

        if (
          data?.error?.message[0] ===
          'password must be longer than or equal to 8 characters'
        ) {
          this.isLoading = false;
          this.errMessage =
            'password must be longer than or equal to 8 characters';
        } else if (data?.error?.message === 'Current password is invalid.') {
          this.isLoading = false;
          this.errMessage = data?.error?.message;
        } else if (data?.accessToken) {
          const tokenLocalstorage = this.authService.getToken();
          const token = JSON.parse(tokenLocalstorage);
          token.accessToken = data.accessToken;
          token.refreshToken = data.refreshToken;
          localStorage.setItem('role', JSON.stringify(token));
          this.isLoading = false;
          document.getElementById('btnsuccess')?.click();
        } else {
          this.isLoading = false;
          this.errMessage = 'Something went wrong, please try again later!';
        }
      } else {
        this.isLoading = false;
        this.errMessage =
          'Sorry, the new password and confirmed password do not match.';
      }
    }
  }
  back() {
    setTimeout(() => {
      this.router.navigate(['/database']);
    }, 500);
  }
}

<div class="container">
  <div>
    <a class="back" routerLink="/deployment/database">
      <span
        class="material-symbols-outlined"
        style="font-size: 24px; color: #388087"
      >
        keyboard_backspace
      </span>
      <span style="color: #388087">{{ "Back" | translate }}</span></a
    >
  </div>
  <div class="row background">
    <div class="col-md-12 d-flex justify-content-between align-items-center">
      <span class="small-content">
        {{ data?.merchantId | translate }}
      </span>
      <div class="d-flex align-items-center">
        <span class="material-symbols-outlined"> badge </span>
        <span class="ps-2 small-content">{{ data?.createdBy?.name }}</span>
      </div>
    </div>
  </div>
  <!-- merchant detail -->
  <div class="row background">
    <div
      class="d-flex flex-column justify-content-between align-items-start mb-3"
    >
      <p>{{ data?.merchantName }}</p>
      <span *ngIf="data?.category" class="badge small-content">
        {{ data?.category }}</span
      >
      <span *ngIf="!data?.category" class="badge small-content">-</span>
    </div>
    <div class="d-flex flex-column justify-content-between align-items-start">
      <span class="small-content">{{
        data?.createdAt | date : "dd/MM/yyyy"
      }}</span>
      <span class="small-content">{{ data?.locationName }}</span>
    </div>
  </div>
  <!-- location -->
  <div class="row background">
    <div
      class="d-flex flex-column justify-content-between align-items-start mb-2"
    >
      <p class="mb-3">Location</p>
      <span class="small-title"> {{ "Shop Location" | translate }}</span>
      <span class="small-content">
        {{
          data?.isOnStreet
            ? ("[On street]" | translate)
            : ("[In Building]" | translate)
        }}
      </span>
    </div>
    <div class="d-flex justify-content-start align-items-start gap-5">
      <div class="d-flex flex-column align-items-start">
        <span class="small-title"> {{ "Shop phone number" | translate }}</span>
        <span class="small-content">
          {{ data?.shopPhoneNumber ? data?.shopPhoneNumber : "-" }}
        </span>
        <span class="small-title"> {{ "Street" | translate }}</span>
        <span class="small-content">
          {{ data?.street ? data?.street : "-" }}
        </span>
        <span class="small-title"> {{ "Province" | translate }}</span>
        <span class="small-content">
          {{ data?.province ? data?.province : "-" }}
        </span>
      </div>
      <div class="d-flex flex-column align-items-start">
        <span class="small-title"> {{ "Floor" | translate }}</span>
        <span class="small-content">
          {{ data?.floor ? data?.floor : "-" }}
        </span>
        <span class="small-title"> {{ "District" | translate }}</span>
        <span class="small-content">
          {{ data?.district ? data?.district : "-" }}
        </span>
        <span class="small-title"> {{ "Zipcode" | translate }}</span>
        <span class="small-content">
          {{ data?.zipcode ? data?.zipcode : "-" }}
        </span>
      </div>
    </div>
    <div class="w-100 mt-4">
      <div class="d-flex flex-column gap-2">
        <button class="btncancel w-100" (click)="openSnackBar()">
          <span class="material-symbols-outlined me-1"> map </span>
          {{ "View on Map" | translate }}
        </button>

        <!-- <button class="btncancel w-100">
          <span class="material-symbols-outlined me-1"> near_me </span>
          {{ "Direction" | translate }}
        </button> -->
      </div>
    </div>
  </div>
  <!-- contact -->
  <div class="row background">
    <div
      class="d-flex flex-column justify-content-between align-items-start mb-2"
    >
      <p class="mb-3">{{ "Contact" | translate }}</p>
      <span class="small-title"> {{ "Contact person" | translate }}</span>
      <span class="small-content">
        {{ data?.contactName ? data?.contactName : "-" }}
      </span>
      <span class="small-title"> {{ "Phone" | translate }}</span>
      <span class="small-content">
        {{ data?.phoneNumber ? data?.phoneNumber : "-" }}
      </span>
      <span class="small-title"> {{ "Email" | translate }}</span>
      <span class="small-content">
        {{ data?.email ? data?.email : "-" }}
      </span>
      <span class="small-title"> {{ "Fax" | translate }}</span>
      <span class="small-content">
        {{ data?.fax ? data?.fax : "-" }}
      </span>
    </div>
  </div>
  <!-- type of payment -->
  <div class="row background">
    <div
      class="d-flex flex-column justify-content-between align-items-start mb-2"
    >
      <p class="mb-3">{{ "Type of Payment" | translate }}</p>
      <span class="small-title"> {{ "Card Acceptance" | translate }}</span>
      <div class="d-flex flex-wrap gap-1 mb-3">
        <div class="p-0 text-center" *ngFor="let i of data?.cardAcceptance">
          <div class="chishp small-title">{{ i }}</div>
        </div>
        <div *ngIf="data?.cardAcceptance?.length <= 0" class="small-content">
          {{ "None" | translate }}
        </div>
      </div>
      <span class="small-title"> {{ "Minimum Payment" | translate }}</span>
      <span class="small-content">
        {{ data?.minimumPayment ? data?.minimumPayment : "-" }}
      </span>
      <span class="small-title"> {{ "Surcharge" | translate }} (%)</span>
      <span class="small-content">
        {{ data?.surcharge ? data?.surcharge : "-" }}
      </span>
      <span class="small-title"> {{ "Other Payment" | translate }}</span>
      <div class="d-flex flex-wrap gap-1">
        <div class="p-0 text-center" *ngFor="let i of data?.otherPayment">
          <div class="chishp small-title">{{ i }}</div>
        </div>
        <div *ngIf="data?.otherPayment?.length <= 0" class="small-content">
          {{ "None" | translate }}
        </div>
      </div>
    </div>
  </div>

  <!-- edc terminal -->
  <div class="row background">
    <p>{{ "EDC Terminal" | translate }}</p>
    <div class="box-background p-3 w-100" *ngFor="let i of data?.edcData">
      <div class="row">
        <div class="col-6 d-flex flex-column gap-1">
          <span class="small-title">
            {{ "Bank" | translate }}
          </span>
          <span class="small-content">
            {{ i.edcBank ? i.edcBank : "-" }}
          </span>
        </div>
        <div class="col-6 d-flex flex-column">
          <span class="small-title">
            {{ "Terminal ID" | translate }}
          </span>
          <span class="small-content">
            {{ i.edcTerminalId ? i.edcTerminalId : "-" }}
          </span>
        </div>
        <div class="row my-4">
          <span class="small-title"> {{ "Normal EDC" | translate }}</span>
          <span class="small-content">
            {{ i?.isNormalEDC ? "Ready to use" : "Not ready" }}
          </span>
          <span class="small-title">
            {{ "Normal EDC Issue Remark" | translate }}</span
          >
          <span class="small-content">
            {{ i?.edcNormalIssue ? i?.edcNormalIssue : "-" }}
          </span>
        </div>
        <div class="row">
          <span class="small-title">
            {{ "Contactless acceptance" | translate }}</span
          >
          <span class="small-content">
            {{ i?.isContactAcceptance ? "Yes" : "No" }}
          </span>
          <span class="small-title"> {{ "Contactless EDC" | translate }}</span>
          <span class="small-content" *ngIf="i?.isContactAcceptance">
            {{ i?.isContactEDC ? "Ready to use" : "Not ready" }}
          </span>
          <span class="small-content" *ngIf="!i?.isContactAcceptance"> - </span>

          <span class="small-title">
            {{ "Contactless Issue Remark" | translate }}</span
          >
          <span class="small-content">
            {{ i?.contactIssue ? i?.contactIssue : "-" }}
          </span>

          <span class="small-title">
            {{ "Contactless Training Requirement" | translate }}</span
          >
          <span
            class="small-content"
            *ngIf="i?.isContactAcceptance && i?.isContactlessTraining !== ''"
          >
            {{ i?.isContactlessTraining ? "Yes" : "No" }}
          </span>
          <span
            class="small-content"
            *ngIf="
              (i?.isContactAcceptance && i?.isContactlessTraining === '') ||
              !i?.isContactAcceptance
            "
          >
            -
          </span>
        </div>
      </div>
    </div>
    <div
      class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm mt-3"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRightNormal"
      aria-controls="offcanvasRight"
    >
      <p class="title-pic">
        {{ "Normal EDC Issue Photo" | translate }} ({{ edcPhotoNumber }})
      </p>

      <span
        class="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRightNormal"
        aria-controls="offcanvasRight"
        class="material-symbols-outlined ms-4 curser"
      >
        arrow_forward_ios
      </span>
    </div>
    <div
      class="d-flex background-pic justify-content-between align-items-center w-100 shadow-sm mb-0"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRightContact"
      aria-controls="offcanvasRight"
    >
      <p class="title-pic">
        {{ "Contactless Issue Photo" | translate }} ({{ contactPhotoNumber }})
      </p>
      <span
        class="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRightContact"
        aria-controls="offcanvasRight"
        class="material-symbols-outlined ms-4 curser"
      >
        arrow_forward_ios
      </span>
    </div>
  </div>
  <!-- Visa material and Competitor info -->
  <div class="row background">
    <p class="mb-3">{{ "Visa Material" | translate }}</p>
    <p class="title-pic">{{ "General Material" | translate }}</p>
    <div class="col-md-6" *ngFor="let item of data?.materialQty">
      <span [ngClass]="item.value > 0 ? 'small-title' : 'small-content'">
        {{ item.key | translate }} ({{ item.value }})</span
      >
    </div>
    <div *ngIf="data?.materialQty?.length <= 0" class="small-content">
      {{ "None" | translate }}
    </div>
    <p class="title-pic mt-3">{{ "Customized Material" | translate }}</p>
    <div class="col-md-6" *ngFor="let item of data?.materialCustomQty">
      <span [ngClass]="item.value > 0 ? 'small-title' : 'small-content'">
        {{ item.key | translate }} ({{ item.value }})</span
      >
    </div>
    <div *ngIf="data?.materialCustomQty?.length <= 0" class="small-content">
      {{ "None" | translate }}
    </div>
    <p class="mt-3 mb-2">{{ "Competitor info" | translate }}</p>
    <div class="d-flex flex-wrap gap-1 mb-3">
      <div class="p-0 text-center" *ngFor="let i of data?.competitorBank">
        <div class="chishp small-title">{{ i }}</div>
      </div>
      <div *ngIf="data?.competitorBank?.length <= 0" class="small-content">
        {{ "None" | translate }}
      </div>
    </div>
  </div>
  <!-- image section -->
  <p class="mb-3">{{ "Image Section" | translate }}</p>
  <div
    class="d-flex background-pic justify-content-between align-items-center w-100 shadow"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRightGeneral"
    aria-controls="offcanvasRight"
  >
    <p class="title-pic">
      {{ "VISA General Material" | translate }} ({{
        generalMaterialPhotoNumber
      }})
    </p>

    <span
      class="btn btn-primary"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRightGeneral"
      aria-controls="offcanvasRight"
      class="material-symbols-outlined ms-4 curser"
    >
      arrow_forward_ios
    </span>
  </div>
  <div
    class="d-flex background-pic justify-content-between align-items-center w-100 shadow"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRightCustomized"
    aria-controls="offcanvasRight"
  >
    <p class="title-pic">
      {{ "VISA Customized Material" | translate }} ({{
        customizeMaterialPhotoNumber
      }})
    </p>

    <span
      class="btn btn-primary"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRightCustomized"
      aria-controls="offcanvasRight"
      class="material-symbols-outlined ms-4 curser"
    >
      arrow_forward_ios
    </span>
  </div>
  <div
    class="d-flex background-pic justify-content-between align-items-center w-100 shadow end-add"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvasRightCompetitor"
    aria-controls="offcanvasRight"
  >
    <p class="title-pic">
      {{ "Competitors Photo" | translate }} ({{ competitorPhoto }})
    </p>

    <span
      class="btn btn-primary"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRightCompetitor"
      aria-controls="offcanvasRight"
      class="material-symbols-outlined ms-4 curser"
    >
      arrow_forward_ios
    </span>
  </div>
</div>

<!-- modal picture -->
<!-- edcnormal photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightNormal"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Issue Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="row g-2">
      <ng-container *ngFor="let i of data?.images">
        <ng-container *ngIf="i._id === 'edcNormalPhoto'">
          <!-- <div class="col-3 col-lg-1" *ngFor="let j of i.images">
            <img src="{{ j.image }}" class="box" alt="" />
          </div> -->
          <app-gallery [data]="i" [isMobile]="true"></app-gallery>
        </ng-container>
      </ng-container>
      <div class="col-12" *ngIf="edcPhotoNumber === 0">
        <p class="text-center">No Photo</p>
      </div>
    </div>
  </div>
</div>
<!-- Contact Photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightContact"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Issue Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="row g-2">
      <ng-container *ngFor="let i of data?.images">
        <ng-container *ngIf="i._id === 'contactPhoto'">
          <!-- <div class="col-3 col-lg-1" *ngFor="let j of i.images">
            <img src="{{ j.image }}" class="box" alt="" />
          </div> -->
          <app-gallery [data]="i" [isMobile]="true"></app-gallery>
        </ng-container>
      </ng-container>
      <div class="col-12" *ngIf="contactPhotoNumber === 0">
        <p class="text-center">No Photo</p>
      </div>
    </div>
  </div>
</div>
<!-- VISA General Material Photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightGeneral"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "VISA General Material" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="row g-2">
      <ng-container *ngFor="let i of data?.images">
        <ng-container *ngIf="i._id === 'generalMaterialPhoto'">
          <!-- <div class="col-3 col-lg-1" *ngFor="let j of i.images">
            <img src="{{ j.image }}" class="box" alt="" />
          </div> -->
          <app-gallery [data]="i" [isMobile]="true"></app-gallery>
        </ng-container>
      </ng-container>
      <div class="col-12" *ngIf="generalMaterialPhotoNumber === 0">
        <p class="text-center">No Photo</p>
      </div>
    </div>
  </div>
</div>
<!-- VISA Customized Material Photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightCustomized"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "VISA Customized Material" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="row g-2">
      <ng-container *ngFor="let i of data?.images">
        <ng-container *ngIf="i._id === 'customizeMaterialPhoto'">
          <!-- <div class="col-3 col-lg-1" *ngFor="let j of i.images">
            <img src="{{ j.image }}" class="box" alt="" />
          </div> -->
          <app-gallery [data]="i" [isMobile]="true"></app-gallery>
        </ng-container>
      </ng-container>
      <div class="col-12" *ngIf="customizeMaterialPhotoNumber === 0">
        <p class="text-center">No Photo</p>
      </div>
    </div>
  </div>
</div>
<!-- Competitors Photo -->
<div
  class="offcanvas offcanvas-end w-100"
  tabindex="-1"
  id="offcanvasRightCompetitor"
  aria-labelledby="offcanvasRightLabel"
>
  <div class="offcanvas-header">
    <button class="btnback" data-bs-dismiss="offcanvas" aria-label="Close">
      <span class="material-symbols-outlined"> chevron_left </span>
    </button>
    <h2 class="offcanvas-title" id="offcanvasRightLabel">
      {{ "Competitors Photo" | translate }}
    </h2>
    <div></div>
  </div>
  <div class="offcanvas-body">
    <div class="row g-2">
      <ng-container *ngFor="let i of data?.images">
        <ng-container *ngIf="i._id === 'competitorPhoto'">
          <!-- <div class="col-3 col-lg-1" *ngFor="let j of i.images">
            <img src="{{ j.image }}" class="box" alt="" />
          </div> -->
          <app-gallery [data]="i" [isMobile]="true"></app-gallery>
        </ng-container>
      </ng-container>
      <div class="col-12" *ngIf="competitorPhoto === 0">
        <p class="text-center">No Photo</p>
      </div>
    </div>
  </div>
</div>

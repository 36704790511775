import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-discard-change',
  templateUrl: './discard-change.component.html',
  styleUrls: ['./discard-change.component.css'],
})
export class DiscardChangeComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DiscardChangeComponent>) {}

  ngOnInit(): void {}
}

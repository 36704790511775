import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { ServiceService } from 'src/app/service/service.service';

@Component({
  selector: 'app-deployment-merchant-info',
  templateUrl: './deployment-merchant-info.component.html',
  styleUrls: ['./deployment-merchant-info.component.css'],
})
export class DeploymentMerchantInfoComponent implements OnInit {
  id: any;
  data: any = {};
  edcPhotoNumber: number = 0;
  contactPhotoNumber: number = 0;
  generalMaterialPhotoNumber: number = 0;
  customizeMaterialPhotoNumber: number = 0;
  competitorPhoto: number = 0;
  constructor(
    private route: ActivatedRoute,
    private service: ServiceService,
    private router: Router,
    private authService: AuthService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.getDatadetail();
    }
  }

  async getDatadetail() {
    const data: any = await this.service.GetTokenPathParams(
      '/merchant/' + this.id
    );
    this.data = data;
    console.log('data', this.data);
    for (let i = 0; i < this.data.images.length; i++) {
      if (this.data.images[i]._id === 'edcNormalPhoto') {
        this.edcPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'contactPhoto') {
        this.contactPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'generalMaterialPhoto') {
        this.generalMaterialPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'customizeMaterialPhoto') {
        this.customizeMaterialPhotoNumber = this.data.images[i].images.length;
      } else if (this.data.images[i]._id === 'competitorPhoto') {
        this.competitorPhoto = this.data.images[i].images.length;
      }
    }
  }
  openSnackBar() {
    this._snackBar.open('Coming soon..', 'X', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 2000,
    });
  }
}

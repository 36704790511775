import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, UrlTree } from '@angular/router';
import { ServiceService } from 'src/app/service/service.service';
import { CanComponentDeactivate } from 'src/app/service/candeactivate.guard';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DiscardChangeComponent } from 'src/app/components/discard-change/discard-change.component';

@Component({
  selector: 'app-invite-admin',
  templateUrl: './invite-admin.component.html',
  styleUrls: ['./invite-admin.component.css'],
})
export class InviteAdminComponent implements OnInit, CanComponentDeactivate {
  data: any;
  country: any;
  isSuccess = false;
  isLoading = false;
  errMessage = '';
  constructor(
    private fb: FormBuilder,
    private service: ServiceService,
    private router: Router,
    public dialog: MatDialog,
    private el: ElementRef
  ) {}

  public findInvalidControls() {
    const invalid = [];
    const controls = this.data.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  ngOnInit(): void {
    this.data = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: [''],
      roles: ['', Validators.required],
      formId: ['', Validators.required],
    });
    this.getCountry();
  }
  async getCountry() {
    let data: any = await this.service.GetTokenPathParams(
      '/backoffice/form/filter?search=&skip=1&limit=10'
    );
    this.country = data.totalData;
  }

  Backtoadminmanagement() {
    this.router.navigate(['/admin-management']);
  }

  async Confirm() {
    this.errMessage = '';
    this.isLoading = false;
    if (this.data.invalid) {
      this.data.markAllAsTouched();
      const firstInvalidControl: HTMLElement =
        this.el.nativeElement.querySelector('.ng-invalid[formControlName]');

      firstInvalidControl.focus();
    } else {
      this.isLoading = true;
      let data: any = await this.service.PostTokenPathBody(
        '/auth/invite',
        this.data.value
      );
      if (data?.msg == 'Success') {
        this.isLoading = false;
        this.isSuccess = true;
        document.getElementById('btnsuccess')?.click();
      } else {
        if (data.error.message) {
          this.isLoading = false;
          this.errMessage = data.error.message;
        } else {
          this.isLoading = false;
          this.errMessage = 'Something went wrong, please try again later!';
        }
      }
    }
  }
  back() {
    setTimeout(() => {
      this.Backtoadminmanagement();
    }, 500);
  }

  openDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(DiscardChangeComponent, {
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.data.dirty && !this.isSuccess) {
      return this.openDialog();
    } else {
      return true;
    }
  }
}

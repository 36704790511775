import { Component, Input, OnInit } from '@angular/core';
import {
  Image,
  ModalGalleryConfig,
  ModalGalleryRef,
  ModalGalleryService,
} from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  @Input() data: any;
  @Input() isMobile: any;
  images?: Image[];
  constructor(private modalGalleryService: ModalGalleryService) {}

  ngOnInit(): void {
    this.initImageArray();
  }

  initImageArray() {
    this.images = this.data.images.map((item: any, index: number) => {
      return new Image(index, { img: item.image });
    });
  }

  openModal(id: number, imageIndex: number): void {
    if (this.images) {
      const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
        id: id,
        images: this.images,
        currentImage: this.images[imageIndex],
      } as ModalGalleryConfig) as ModalGalleryRef;
    }
  }
}

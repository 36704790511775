<div class="container">
  <div class="backMobile">
    <a class="back" routerLink="/deployment/database">
      <span
        class="material-symbols-outlined"
        style="font-size: 24px; color: #388087"
      >
        keyboard_backspace
      </span>
      <span style="color: #388087">{{ "Back" | translate }}</span></a
    >
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="text-title">
        {{ "Edit Profile" | translate }}
      </div>
    </div>
  </div>
  <div class="background p-3">
    <div class="row">
      <div class="col-md-12">{{ "Name" | translate }}*</div>
      <div class="col-md-12">
        <input
          type="text"
          class="form-control mt-2"
          placeholder="{{ 'Name' | translate }}"
          required
          [formControl]="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3">{{ "Email" | translate }}*</div>
      <div class="col-md-12">
        <input
          type="text"
          class="form-control mt-2"
          placeholder="{{ 'Example@mail.com' | translate }}"
          [(ngModel)]="data.email"
          [disabled]="data.email"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-3">{{ "Phone number" | translate }}*</div>
      <div class="col-md-12">
        <input
          type="text"
          maxlength="11"
          class="form-control mt-2"
          placeholder="{{ 'Phone number' | translate }}"
          required
          [formControl]="phoneNumber"
        />
      </div>
      <div
        *ngIf="phoneNumber.errors && phoneNumber.touched"
        class="text-danger fs-6"
      >
        {{ "Phone number must be 10 digits." | translate }}
      </div>
    </div>
  </div>
  <div class="row forDesktop">
    <div class="col-md-12 text-danger">
      <p *ngIf="errMessage">{{ errMessage | translate }}</p>
    </div>
    <div class="col-md-12 d-flex">
      <button class="btnsave" (click)="updateProfile()" *ngIf="!isLoading">
        {{ "Save Change" | translate }}
      </button>
      <button class="btnsave w-25" [disabled]="true" *ngIf="isLoading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
      <button class="btncancel ms-3" (click)="back()">
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>
  <div class="row forMobile">
    <div class="col-md-12 text-danger text-center">
      <p *ngIf="errMessage">{{ errMessage | translate }}</p>
    </div>
    <div class="col-md-12">
      <button
        class="btnsave w-100"
        (click)="updateProfile()"
        *ngIf="!isLoading"
      >
        {{ "Save Change" | translate }}
      </button>
      <button class="btnsave w-100" [disabled]="true" *ngIf="isLoading">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    </div>
    <div class="col-md-12">
      <button class="btncancel w-100 mt-2" (click)="back()">
        {{ "Cancel" | translate }}
      </button>
    </div>
  </div>
</div>
<button
  id="btnsuccess"
  style="display: none"
  type="button"
  class="btn btn-primary"
  data-bs-toggle="modal"
  data-bs-target="#success"
>
  Launch demo modal
</button>
<!-- Modal success -->
<div class="modal fade" data-bs-backdrop="static" id="success" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content p-3">
      <div class="row">
        <div class="col-md-12 text-center mb-3">
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>
        <div class="col-md-12 mb-3 text-center">
          {{ "Profile edit has been saved" | translate }}
        </div>
        <div class="col-md-12 mt-4">
          <button
            data-bs-dismiss="modal"
            class="btnsave w-100"
            (click)="back()"
          >
            {{ "Done" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row align-items-center justify-content-between">
    <div class="col-md-6">
      <div class="text-title">
        {{ "Database" | translate }}
      </div>
    </div>
    <div
      class="col-md-6 d-flex justify-content-end"
      *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'"
    >
      <select
        class="form-select w-50"
        (ngModelChange)="getFormId($event)"
        [(ngModel)]="countryId"
      >
        <option *ngFor="let i of totalCountry" [value]="i._id">
          {{ i.country }}
        </option>
      </select>
    </div>
  </div>
  <div class="row justify-content-end">
    <div class="col-md-auto mt-3">
      <!-- <button class="btncancel" data-bs-toggle="modal" data-bs-target="#Export">
        <span class="material-symbols-outlined me-3"> download </span>
        {{ "Export" | translate }}
      </button> -->
      <button
        class="btncancel"
        data-bs-toggle="modal"
        data-bs-target="#ExportLoading"
        (click)="exportAllmerchant()"
      >
        <span class="material-symbols-outlined me-3"> download </span>
        {{ "Export" | translate }}
      </button>
    </div>
    <div
      class="col-md-auto mt-3"
      *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'"
    >
      <button class="btncancel" (click)="addpage()">
        <span class="material-symbols-outlined me-3"> add </span>
        {{ "Add Merchant" | translate }}
      </button>
    </div>
    <div class="col-md-auto mt-3">
      <button class="btncancel" (click)="openSnackBar()">
        <span class="material-symbols-outlined me-3"> map </span>
        {{ "View on Map" | translate }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="!showmore">
    <div class="background p-3" @onOff>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Location" | translate }}</span>
            <span>
              <u (click)="Clear('locationName')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.locationName"
            placeholder="{{ 'Select Location' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalLocation"
              [value]="i?._id"
              >{{ i?.locationName }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Province" | translate }}</span>
            <span>
              <u (click)="Clear('province')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <form [formGroup]="Formdata">
            <ng-container *ngIf="country === 'Thailand'">
              <input
                type="text"
                formControlName="province"
                class="form-control mt-2"
                placeholder="{{ 'Province' | translate }}"
                [matAutocomplete]="auto"
                matInput
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptionProvince | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </ng-container>
            <input
              *ngIf="country !== 'Thailand'"
              type="text"
              placeholder="{{ 'Province' | translate }}"
              formControlName="province"
              class="form-control mt-2"
            />
          </form>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Category" | translate }}</span>
            <span>
              <u (click)="Clear('category')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.category"
            placeholder="{{ 'Select Category' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalCate"
              [value]="i?._id"
              >{{ i?.category }}</mat-option
            >
          </mat-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Date range" | translate }}</span>
            <span>
              <u (click)="Clear('dateFrom')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <div
            class="form-control mt-2 d-flex align-items-center"
            style="padding: 0 10px !important"
          >
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                [placeholder]="
                  this.lang === 'th' ? 'วันเริ่มต้น' : 'Start date'
                "
                [(ngModel)]="selectData.dateFrom"
              />
              <input
                matEndDate
                [placeholder]="this.lang === 'th' ? 'วันสิ้นสุด' : 'End date'"
                [(ngModel)]="selectData.dateEnd"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Card acceptance" | translate }}</span>
            <span>
              <u (click)="Clear('cardAcceptance')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.cardAcceptance"
            placeholder="{{ 'Select Card acceptance' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalCardAcc"
              [value]="i?._id"
              >{{ i?.cardAcceptance }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Contactless acceptance" | translate }}</span>
            <span>
              <u (click)="Clear('contractlessStatus')">{{
                "Clear" | translate
              }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.contractlessStatus"
            placeholder="{{ 'Select Contactless' | translate }}"
          >
            <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
            <mat-option [value]="false">{{ "No" | translate }}</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="row mt-5">
        <div *ngIf="!showmore" class="col-md-6 d-flex align-items-center">
          <span
            *ngIf="!showmore"
            (click)="show()"
            class="hover d-flex align-items-center"
            >{{ "Expand more filter" | translate }}
            <span class="material-symbols-outlined"> expand_more </span></span
          >
          <span
            *ngIf="showmore"
            (click)="show()"
            class="hover d-flex align-items-center"
            >{{ "Show less" | translate }}
            <span class="material-symbols-outlined"> expand_less </span></span
          >
        </div>

        <div class="col-md-6 mt-3 d-flex justify-content-end">
          <button
            class="btnsave me-3 d-flex align-items-center"
            (click)="getAllmerchant()"
          >
            <span class="material-symbols-outlined"> search </span>
            {{ "Search" | translate }}
          </button>
          <button class="btncancel" (click)="Clearall()">
            {{ "Clear filter" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showmore">
    <div class="background p-3" @onOff>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Date range" | translate }}</span>
            <span>
              <u (click)="Clear('dateFrom')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <div
            class="form-control mt-2 d-flex align-items-center"
            style="padding: 0 10px !important"
          >
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="{{ 'Start date' | translate }}"
                [(ngModel)]="selectData.dateFrom"
              />
              <input
                matEndDate
                placeholder="{{ 'End date' | translate }}"
                [(ngModel)]="selectData.dateEnd"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Category" | translate }}</span>
            <span>
              <u (click)="Clear('category')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.category"
            placeholder="{{ 'Select Category' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalCate"
              [value]="i?._id"
              >{{ i?.category }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Card acceptance" | translate }}</span>
            <span>
              <u (click)="Clear('cardAcceptance')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.cardAcceptance"
            placeholder="{{ 'Select Card acceptance' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalCardAcc"
              [value]="i?._id"
              >{{ i?.cardAcceptance }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Other Payment" | translate }}</span>
            <span>
              <u (click)="Clear('otherPayment')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.otherPayment"
            placeholder="{{ 'Select Other Payment' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalOtherPayment"
              [value]="i?._id"
              >{{ i?.otherPayment }}</mat-option
            >
          </mat-select>
        </div>

        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Contactless acceptance" | translate }}</span>
            <span>
              <u (click)="Clear('contractlessStatus')">{{
                "Clear" | translate
              }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.contractlessStatus"
            placeholder="{{ 'Select Contactless' | translate }}"
          >
            <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
            <mat-option [value]="false">{{ "No" | translate }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-5">
          <div class="text-title">
            <h1>
              {{ "Location" | translate }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Location" | translate }}</span>
            <span>
              <u (click)="Clear('locationName')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.locationName"
            placeholder="{{ 'Select Location' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalLocation"
              [value]="i?._id"
              >{{ i?.locationName }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Street" | translate }}</span>
            <span>
              <u (click)="Clear('street')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <input
            type="text"
            placeholder="{{ 'Street' | translate }}"
            [(ngModel)]="selectData.street"
            class="form-control mt-2"
          />
        </div>

        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "District" | translate }}</span>
            <span>
              <u (click)="Clear('district')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <form [formGroup]="Formdata">
            <ng-container *ngIf="country === 'Thailand'">
              <input
                type="text"
                formControlName="district"
                class="form-control mt-2"
                placeholder="{{ 'District' | translate }}"
                [matAutocomplete]="autoDistrict"
                matInput
              />
              <mat-autocomplete #autoDistrict="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptionDistrict | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </ng-container>
            <input
              *ngIf="country !== 'Thailand'"
              type="text"
              placeholder="{{ 'District' | translate }}"
              formControlName="district"
              class="form-control mt-2"
            />
          </form>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Province" | translate }}</span>
            <span>
              <u (click)="Clear('province')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <form [formGroup]="Formdata">
            <ng-container *ngIf="country === 'Thailand'">
              <input
                type="text"
                formControlName="province"
                class="form-control mt-2"
                placeholder="{{ 'Province' | translate }}"
                [matAutocomplete]="auto"
                matInput
              />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptionProvince | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </ng-container>
            <input
              *ngIf="country !== 'Thailand'"
              type="text"
              placeholder="{{ 'Province' | translate }}"
              formControlName="province"
              class="form-control mt-2"
            />
          </form>
        </div>

        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Zipcode" | translate }}</span>
            <span>
              <u (click)="Clear('zipcode')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <input
            type="text"
            placeholder="{{ 'Zipcode' | translate }}"
            [(ngModel)]="selectData.zipcode"
            class="form-control mt-2"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-5">
          <div class="text-title">
            <h1>
              {{ "Payment" | translate }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mt-3">
          <div>
            <input
              class="me-3"
              type="checkbox"
              [(ngModel)]="selectData.min_spen"
              (ngModelChange)="toggleMinSpen($event)"
            />
            <span>
              {{ "Minimum spending (THB)" | translate }}
            </span>
            <span *ngIf="selectData.min_spen" class="ms-5">
              {{ selectData.minSpendFrom }} - {{ selectData.minSpendTo }}</span
            >
          </div>
          <!-- <mat-slider
            #minSpen
            [min]="minFrom"
            max="10000"
            [disabled]="!selectData.min_spen"
            (change)="updateMinSpen($event)"
          >
          </mat-slider> -->
          <div class="d-flex justify-content-spacebetween">
            <input
              class="form-control mt-2"
              type="text"
              [(ngModel)]="selectData.minSpendFrom"
              placeholder="{{ 'Min' | translate }}"
              [disabled]="!selectData.min_spen"
            />
            <div class="mx-3 d-flex align-items-center">-</div>
            <input
              class="form-control mt-2"
              type="text"
              [(ngModel)]="selectData.minSpendTo"
              placeholder="{{ 'Max' | translate }}"
              [disabled]="!selectData.min_spen"
            />
          </div>
        </div>
        <div class="col-md-6 mt-3">
          <div>
            <input
              class="me-3"
              type="checkbox"
              [(ngModel)]="selectData.surcharge_min"
              (ngModelChange)="toggleSurcharge($event)"
            />
            <span>
              {{ "Surcharge (%)" | translate }}
            </span>
            <span *ngIf="selectData.surcharge_min" class="ms-5">
              {{ selectData.surchargeFrom }} -
              {{ selectData.surchargeTo_min }}</span
            >
          </div>
          <!-- <mat-slider
            #surCharged
            [min]="surFrom"
            max="10000"
            [disabled]="!selectData.surcharge_min"
            (change)="updateSurcharge($event)"
          >
          </mat-slider> -->
          <div class="d-flex justify-content-spacebetween">
            <input
              class="form-control mt-2"
              type="text"
              [(ngModel)]="selectData.surchargeFrom"
              placeholder="{{ 'Min' | translate }}"
              [disabled]="!selectData.surcharge_min"
            />
            <div class="mx-3 d-flex align-items-center">-</div>
            <input
              class="form-control mt-2"
              type="text"
              [(ngModel)]="selectData.surchargeTo"
              placeholder="{{ 'Max' | translate }}"
              [disabled]="!selectData.surcharge_min"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "EDC Bank" | translate }}</span>
            <span>
              <u (click)="Clear('edcBank')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <!-- example for class form-select -->
          <!-- <mat-select
          class="form-select mt-2"
          multiple
          [(ngModel)]="selectData.edcBank"
          placeholder="{{ 'Select EDC Bank' | translate }}"
          (selectionChange)="getAllmerchant()"
        > -->
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.edcBank"
            placeholder="{{ 'Select EDC Bank' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalEdcBank"
              [value]="i?._id"
              >{{ i?.edcBank }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "EDC Status" | translate }}</span>
            <span>
              <u (click)="Clear('edcStatus')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.edcStatus"
            placeholder="{{ 'Select EDC Status' | translate }}"
          >
            <mat-option [value]="true">{{ "Yes" | translate }}</mat-option>
            <mat-option [value]="false">{{ "No" | translate }}</mat-option>
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "EDC Place" | translate }}</span>
            <span>
              <u (click)="Clear('edcPlace')">{{ "Clear" | translate }}</u>
            </span>
          </div>

          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.edcPlace"
            placeholder="{{ 'Select EDC Place' | translate }}"
          >
            <mat-option value="Facing to customer">{{
              "Facing to customer" | translate
            }}</mat-option>
            <mat-option value="On counter but not facing to customer">{{
              "On counter but not facing to customer" | translate
            }}</mat-option>
            <mat-option value="Keep in counter">{{
              "Keep in counter" | translate
            }}</mat-option>
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Staff Performance" | translate }}</span>
            <span>
              <u (click)="Clear('staffPer')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.staffPer"
            placeholder="{{ 'Select Staff Performance' | translate }}"
          >
            <mat-option value="Welled perform">{{
              "Welled perform" | translate
            }}</mat-option>
            <mat-option value="Don't know how to process">
              {{ "Don't know how to process" | translate }}</mat-option
            >
            <mat-option value="Don't know about Contactless">{{
              "Don't know about Contactless" | translate
            }}</mat-option>
          </mat-select>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8 mt-5">
          <div class="text-title">
            <h1>
              {{ "VISA Material" | translate }}
            </h1>
          </div>
        </div>
        <div class="col-md-4 mt-5">
          <div class="text-title">
            <h1>
              {{ "Competitors Info" | translate }}
            </h1>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "General Material" | translate }}</span>
            <span>
              <u (click)="Clear('generalMaterial')">{{
                "Clear" | translate
              }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.generalMaterial"
            placeholder="{{ 'Select General Material' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalGeneral"
              [value]="i?._id"
              >{{ i?._id }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Customize Material" | translate }}</span>
            <span>
              <u (click)="Clear('customizeMaterial')">{{
                "Clear" | translate
              }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.customizeMaterial"
            placeholder="{{ 'Select Customize Material' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalCustomize"
              [value]="i?._id"
              >{{ i?._id }}</mat-option
            >
          </mat-select>
        </div>
        <div class="col-md-4 mt-3">
          <div class="select-item">
            <span>{{ "Competitors" | translate }}</span>
            <span>
              <u (click)="Clear('competitior')">{{ "Clear" | translate }}</u>
            </span>
          </div>
          <mat-select
            class="form-control mt-2"
            [(ngModel)]="selectData.competitior"
            placeholder="{{ 'Select Competitor' | translate }}"
          >
            <mat-option
              *ngFor="let i of dataFilters?.totalMat"
              [value]="i?.competitorBank"
              >{{ i?.competitorBank }}</mat-option
            >
          </mat-select>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-6 d-flex align-items-center">
          <span
            *ngIf="!showmore"
            (click)="show()"
            class="hover d-flex align-items-center"
            >{{ "Expand more filter" | translate }}
            <span class="material-symbols-outlined"> expand_more </span></span
          >
          <span
            *ngIf="showmore"
            (click)="show()"
            class="hover d-flex align-items-center"
            >{{ "Show less" | translate }}
            <span class="material-symbols-outlined"> expand_less </span></span
          >
        </div>

        <div class="col-md-6 mt-3 d-flex justify-content-end">
          <button
            class="btnsave me-3 d-flex align-items-center"
            (click)="getAllmerchant()"
          >
            <span class="material-symbols-outlined"> search </span>
            {{ "Search" | translate }}
          </button>
          <button class="btncancel" (click)="Clearall()">
            {{ "Clear filter" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-md-6 d-flex align-items-center">
      <div class="text-title me-3">
        {{ "Result" | translate }}
      </div>
      <span class="badge"> {{ data.totalCount | number }} </span>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <div class="input-group mb-3 w-50">
        <input
          type="text"
          class="form-control"
          style="border-right: none"
          placeholder="{{ 'Search' | translate }}"
          aria-label="Username"
          aria-describedby="basic-addon1"
          [(ngModel)]="selectData.merchantName"
          (keyup.enter)="getAllmerchant()"
        />
        <span
          *ngIf="selectData.merchantName"
          class="material-symbols-outlined input-group-text"
          style="border-left: none; cursor: pointer"
          (click)="clearOnSearch()"
          >clear</span
        >
        <span
          style="cursor: pointer"
          id="basic-addon1"
          class="material-symbols-outlined input-group-text"
          (click)="getAllmerchant()"
        >
          search
        </span>
      </div>
      <button
        class="btncancel ms-3"
        style="padding: 15px 15px !important"
        (click)="sortOrder()"
      >
        <span class="material-symbols-outlined"> swap_vert </span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'">
    <div class="row justify-content-end" *ngIf="deleteList?.length !== 0">
      <div class="col-md-1 mt-3 d-flex justify-content-end">
        <button
          class="delete"
          data-bs-toggle="modal"
          data-bs-target="#Delete"
          (click)="Deletelist()"
        >
          <span class="material-symbols-outlined"> delete </span>
          {{ "Delete" | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-md-12">
      <table>
        <thead>
          <tr>
            <th *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'">
              <!-- <matc-c type="checkbox" name="list_name" value="m1" /> -->
              <mat-checkbox
                (change)="Getalllists($event)"
                [(ngModel)]="getAllMer"
              ></mat-checkbox>
            </th>
            <th>{{ "Data ID" | translate }}</th>
            <th>{{ "Merchant name" | translate }}</th>
            <th>{{ "Date rec." | translate }}</th>
            <th>{{ "Category" | translate }}</th>
            <th>{{ "Location" | translate }}</th>
            <th>{{ "Country" | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let i of data['totalData']
                | paginate
                  : {
                      itemsPerPage: row,
                      currentPage: p,
                      totalItems: totalCount
                    };
              let index = index
            "
          >
            <tr>
              <td *ngIf="role === 'SUPERADMIN' || role === 'ADMIN'">
                <mat-checkbox
                  [(ngModel)]="i.isSelected"
                  (ngModelChange)="isUserSelected($event, index)"
                ></mat-checkbox>
              </td>
              <td>{{ i?.merchantId }}</td>
              <td class="merchant-name">
                <span class="detail" (click)="Detail(i?._id)">{{
                  i?.merchantName
                }}</span>
              </td>
              <td>{{ i?.createdAt | date : "dd/MM/yyyy" }}</td>
              <td>{{ i?.category }}</td>
              <td>{{ i?.locationName }}</td>
              <td>{{ i?.formId["country"] }}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="totalCount === 0">
            <tr>
              <td colspan="7" class="text-center">
                {{ "No data" | translate }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="col-md-12 d-flex align-items-center justify-content-between">
      <div>
        <pagination-controls
          (pageChange)="pageChanged($event)"
          [responsive]="true"
          previousLabel=""
          nextLabel=""
        ></pagination-controls>
      </div>
      <select
        [(ngModel)]="row"
        class="form-select w-auto"
        (change)="getAllmerchant()"
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
  </div>
  <!-- Modal Delete-->
  <div
    class="modal fade"
    id="Delete"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="container p-5 pt-2 pb-3">
          <div class="row">
            <div class="col-md-12 text-center">
              <span
                class="material-symbols-outlined"
                style="font-size: 100px; color: #388087"
              >
                error
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <span>
                {{ "Are you sure to permanent delete merchant" | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-2 text-center">
              <span style="font-size: 14px">
                {{ "Please confirm admin password to process" | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-4">
              <input
                type="password"
                class="form-control"
                placeholder="{{ 'Confirm Admin Password' | translate }}"
                [(ngModel)]="password"
              />
            </div>
            <p class="text-danger text-center mt-2" *ngIf="response">
              {{ response }}
            </p>
          </div>
          <div class="row">
            <div class="col-md-12 mt-5 d-flex">
              <button class="btnsavedelete w-100" (click)="confirmDelete()">
                {{ "Confirm Delete" | translate }}
              </button>
              <button
                class="btncancel w-100 ms-1"
                #btnclose
                data-bs-dismiss="modal"
              >
                {{ "Cancel" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal export-->
<!-- <div
  class="modal fade"
  id="Export"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <ng-container *ngIf="!isExportLoading">
          <div class="row">
            <div class="col-md-12 text-end">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <span
                class="material-symbols-outlined"
                style="font-size: 100px; color: #388087"
              >
                error
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <span>
                {{ "Do you want to export data with photo?" | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-5 d-flex">
              <button
                class="btnsave w-100"
                data-bs-toggle="modal"
                data-bs-target="#ExportLoading"
                (click)="exportAllmerchant(true)"
              >
                {{ "Yes" | translate }}
              </button>
              <button
                class="btncancel w-100 ms-1"
                data-bs-toggle="modal"
                data-bs-target="#ExportLoading"
                (click)="exportAllmerchant(false)"
              >
                {{ "No" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div> -->
<!-- Modal export loading-->
<div
  class="modal"
  id="ExportLoading"
  data-bs-keyboard="false"
  data-bs-backdrop="static"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="container p-5 pt-2 pb-3">
        <div
          class="d-flex justify-content-center"
          *ngIf="!isDone && errMessage.length === 0"
        >
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <p class="text-center my-2" *ngIf="!isDone && errMessage.length === 0">
          {{ "Exporting the data, please wait" | translate }}...
        </p>
        <div
          class="col-md-12 text-center"
          *ngIf="isDone && errMessage.length === 0"
        >
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            check_circle
          </span>
        </div>
        <div
          class="col-md-12 text-center"
          *ngIf="isDone && errMessage.length !== 0"
        >
          <span
            class="material-symbols-outlined"
            style="font-size: 100px; color: #388087"
          >
            error
          </span>
        </div>

        <p
          class="text-center my-2 text-danger"
          *ngIf="isDone && errMessage.length !== 0"
        >
          {{ errMessage | translate }}
        </p>
        <div
          class="progress"
          role="progressbar"
          aria-label="Success example"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          *ngIf="errMessage.length === 0"
        >
          <div class="progress-bar bg-success" [style]="style"></div>
        </div>

        <div
          class="col-md-12 mt-2 d-flex"
          *ngIf="isDone && errMessage.length === 0"
        >
          <button
            class="btncancel w-100 ms-1"
            #btnclose
            data-bs-dismiss="modal"
            (click)="progress = 0"
          >
            {{ "Done" | translate }}
          </button>
        </div>
        <div
          class="col-md-12 mt-2 d-flex"
          *ngIf="isDone && errMessage.length !== 0"
        >
          <button
            class="btncancel w-100 ms-1"
            #btnclose
            data-bs-dismiss="modal"
            (click)="progress = 0"
          >
            {{ "Try again" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading -->
<div class="overlay" *ngIf="isLoading">
  <div class="center">
    <div
      class="spinner-border text-success"
      style="width: 10em; height: 10em"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service/service.service';
@Component({
  selector: 'app-admin-info',
  templateUrl: './admin-info.component.html',
  styleUrls: ['./admin-info.component.css'],
})
export class AdminInfoComponent implements OnInit {
  id: any;
  password: any;
  data: any = {};
  isLoading = false;
  constructor(
    private service: ServiceService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
    });
  }

  ngOnInit(): void {
    this.getUseradmin();
  }

  async getUseradmin() {
    let detail: any = await this.service.GetTokenPathParams(
      '/user/admin/' + this.id
    );
    this.data = detail;
  }

  responds: any;
  async deleteUseradmin() {
    this.responds = '';
    this.isLoading = true;
    let body = {
      password: this.password,
    };
    let detail: any = await this.service.DeleteTokenPathBody(
      '/user/admin/' + this.id,
      body
    );

    if (detail?.message == 'Delete success') {
      this.responds = '';
      this.isLoading = false;

      document.getElementById('btnsuccess')?.click();
    } else {
      if (detail?.error.message) {
        this.responds = detail.error.message;
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.responds = 'Something went wrong, please try again later!';
      }
    }
  }

  back() {
    setTimeout(() => {
      this.router.navigate(['/admin-management']);
    }, 500);
  }
}

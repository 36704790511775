import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, map, retry, take } from 'rxjs';
import { AuthService } from './auth.service';
import { Roles } from '../shares/roles.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, OnDestroy {
  private isAllowed?: boolean;
  private subscription: any;
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiredRoles: Roles[] = route.data['requiredRoles'];
    const role = this.authService.getRole();

    this.subscription = this.authService.userRoles$
      .pipe(
        map((userRoles) => {
          // Check if the user has at least one of the required roles

          return requiredRoles.some((role) => userRoles.includes(role));
        }),
        retry(1)
      )
      .subscribe((isAllowed) => {
        this.isAllowed = isAllowed;
      });

    if (this.isAllowed) {
      return this.isAllowed;
    } else {
      if (role === 'DEPLOYMENT') {
        this.router.navigate(['/deployment/database']);
        return false;
      } else {
        this.router.navigate(['/database']);
        return false;
      }
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
